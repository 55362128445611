/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 100;
  src: local("Lato Hairline Italic"), local("Lato-HairlineItalic"), url(https://fonts.gstatic.com/s/lato/v14/S6u-w4BMUTPHjxsIPx-mPCLQ7A.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 100;
  src: local("Lato Hairline Italic"), local("Lato-HairlineItalic"), url(https://fonts.gstatic.com/s/lato/v14/S6u-w4BMUTPHjxsIPx-oPCI.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 300;
  src: local("Lato Light Italic"), local("Lato-LightItalic"), url(https://fonts.gstatic.com/s/lato/v14/S6u_w4BMUTPHjxsI9w2_FQft1dw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 300;
  src: local("Lato Light Italic"), local("Lato-LightItalic"), url(https://fonts.gstatic.com/s/lato/v14/S6u_w4BMUTPHjxsI9w2_Gwft.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: local("Lato Italic"), local("Lato-Italic"), url(https://fonts.gstatic.com/s/lato/v14/S6u8w4BMUTPHjxsAUi-qJCY.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: local("Lato Italic"), local("Lato-Italic"), url(https://fonts.gstatic.com/s/lato/v14/S6u8w4BMUTPHjxsAXC-q.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
  src: local("Lato Bold Italic"), local("Lato-BoldItalic"), url(https://fonts.gstatic.com/s/lato/v14/S6u_w4BMUTPHjxsI5wq_FQft1dw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
  src: local("Lato Bold Italic"), local("Lato-BoldItalic"), url(https://fonts.gstatic.com/s/lato/v14/S6u_w4BMUTPHjxsI5wq_Gwft.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 900;
  src: local("Lato Black Italic"), local("Lato-BlackItalic"), url(https://fonts.gstatic.com/s/lato/v14/S6u_w4BMUTPHjxsI3wi_FQft1dw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 900;
  src: local("Lato Black Italic"), local("Lato-BlackItalic"), url(https://fonts.gstatic.com/s/lato/v14/S6u_w4BMUTPHjxsI3wi_Gwft.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 100;
  src: local("Lato Hairline"), local("Lato-Hairline"), url(https://fonts.gstatic.com/s/lato/v14/S6u8w4BMUTPHh30AUi-qJCY.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 100;
  src: local("Lato Hairline"), local("Lato-Hairline"), url(https://fonts.gstatic.com/s/lato/v14/S6u8w4BMUTPHh30AXC-q.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: local("Lato Light"), local("Lato-Light"), url(https://fonts.gstatic.com/s/lato/v14/S6u9w4BMUTPHh7USSwaPGR_p.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: local("Lato Light"), local("Lato-Light"), url(https://fonts.gstatic.com/s/lato/v14/S6u9w4BMUTPHh7USSwiPGQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local("Lato Regular"), local("Lato-Regular"), url(https://fonts.gstatic.com/s/lato/v14/S6uyw4BMUTPHjxAwXjeu.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local("Lato Regular"), local("Lato-Regular"), url(https://fonts.gstatic.com/s/lato/v14/S6uyw4BMUTPHjx4wXg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: local("Lato Bold"), local("Lato-Bold"), url(https://fonts.gstatic.com/s/lato/v14/S6u9w4BMUTPHh6UVSwaPGR_p.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: local("Lato Bold"), local("Lato-Bold"), url(https://fonts.gstatic.com/s/lato/v14/S6u9w4BMUTPHh6UVSwiPGQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: local("Lato Black"), local("Lato-Black"), url(https://fonts.gstatic.com/s/lato/v14/S6u9w4BMUTPHh50XSwaPGR_p.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: local("Lato Black"), local("Lato-Black"), url(https://fonts.gstatic.com/s/lato/v14/S6u9w4BMUTPHh50XSwiPGQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/*============================================================================
  Debut | Built with Shopify Slate

  Some things to know about this file:
    - Sass is compiled on Shopify's server so you don't need to convert it to CSS yourself
    - The output CSS is compressed and comments are removed
    - You cannot use native CSS/Sass @imports in this file without a build script
==============================================================================*/
/*================ SASS HELPERS ================*/
/*============================================================================
  Convert pixels to ems
  eg. for a relational value of 12px write em(12) when the parent is 16px
  if the parent is another value say 24px write em(12, 24)
  Based on https://github.com/thoughtbot/bourbon/blob/master/app/assets/stylesheets/functions/_px-to-em.scss
==============================================================================*/
/*============================================================================
  Strips the unit from a number.
  @param {Number (With Unit)} $value
  @example scss - Usage
    $dimension: strip-units(10em);
  @example css - CSS Output
    $dimension: 10;
  @return {Number (Unitless)}
  based on https://github.com/thoughtbot/bourbon/blob/master/app/assets/stylesheets/functions/_strip-units.scss
==============================================================================*/
/*================ #Mixins ================*/
/*============================================================================
  Prefix mixin for generating vendor prefixes.
  Based on https://github.com/thoughtbot/bourbon/blob/master/app/assets/stylesheets/addons/_prefixer.scss

  Usage:
    // Input:
    .element {
      @include prefix(transform, scale(1), ms webkit spec);
    }

    // Output:
    .element {
      -ms-transform: scale(1);
      -webkit-transform: scale(1);
      transform: scale(1);
    }
==============================================================================*/
/*================ Media Query Mixin ================*/
/*================ Responsive Show/Hide Helper ================*/
/*================ Responsive Text Alignment Helper ================*/
/*============================================================================
  Flexbox prefix mixins from Bourbon
    https://github.com/thoughtbot/bourbon/blob/master/app/assets/stylesheets/css3/_flex-box.scss
==============================================================================*/
/*================ VARIABLES ================*/
/*============================================================================
  Grid Breakpoints and Class Names
    - Do not change the variable names
==============================================================================*/
/*============================================================================
  Generate breakpoint-specific column widths and push classes
    - Default column widths: $grid-breakpoint-has-widths: ($small, $medium-up);
    - Default is no push classes
==============================================================================*/
/*================ Color Variables ================*/
/*================ Sizing Variables ================*/
/*================ Z-Index ================*/
/*================ SVG ================*/
/*================ Drawers ================*/
/*================ Hero ================*/
/*================ Hero Slider ================*/
/*================ Typography ================*/
/*================ Gift Cards ================*/
/*================ Z-index ================*/
/*================ VENDOR ================*/
/*============================================================================
  Slick Slider 1.6.0

  - If upgrading Slick's styles, use the following variables/functions
    instead of the slick defaults (from slick-theme.scss)
  - This file includes default slick.scss styles (at Slick Slider SCSS)
    and slick-theme.scss (at Slick Slider Theme). Upgrade each area individually.
  - Remove `outline: none` from `.slick-dots li button`
==============================================================================*/
/*-------------------
COMMON STYLES
---------------------*/
body {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  line-height: 25px;
  color: #666666;
  width: 100%;
  overflow-x: hidden; }

h1 {
  font-size: 60px; }

h2 {
  font-size: 24px; }

h3 {
  font-size: 18px; }

h4 {
  font-size: 16px; }

h5 {
  font-size: 14px; }

h6 {
  font-size: 12px; }

img {
  max-width: 100%; }

a {
  color: #333333;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  text-decoration: none; }

a:hover, a:focus, a:active {
  text-decoration: none;
  outline: none; }

p {
  margin-bottom: 20px; }

ul, ol {
  margin: 0;
  padding: 0; }

ul ul, ol ol, ul ol, ol ul {
  padding-left: 0; }

input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
select {
  border: 1px solid #dddddd;
  padding: 0 15px;
  line-height: 38px;
  height: 38px;
  border-radius: 3px;
  box-shadow: none !important;
  -webkit-appearance: none !important;
  appearance: none !important; }

textarea {
  border: 1px solid #dddddd;
  padding: 0 15px;
  border-radius: 3px;
  box-shadow: none !important;
  -webkit-appearance: none !important;
  appearance: none !important; }

input[type="text"]:focus,
input[type="number"]:focus,
input[type="submit"]:focus,
input[type="search"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="tel"]:focus,
textarea:focus,
a:focus,
button:focus,
select:focus {
  outline: none; }

::-webkit-input-placeholder {
  color: inherit;
  opacity: 1;
  filter: alpha(opacity=100); }

:-moz-placeholder {
  /* Firefox 18- */
  color: inherit;
  opacity: 1;
  filter: alpha(opacity=100); }

::-moz-placeholder {
  /* Firefox 19+ */
  color: inherit;
  opacity: 1;
  filter: alpha(opacity=100); }

:-ms-input-placeholder {
  color: inherit;
  opacity: 1;
  filter: alpha(opacity=100); }

[class^="flaticon-"]::before, [class*=" flaticon-"]::before, [class^="flaticon-"]::after, [class*=" flaticon-"]::after {
  font-size: inherit;
  margin-left: 0; }

table {
  border-collapse: collapse;
  width: 100%; }

table, th, td {
  border: 1px solid #ececec; }

th, td {
  padding: 10px; }

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color: #333333;
  margin-bottom: 0;
  margin-top: 0;
  font-family: "Lato", sans-serif;
  font-weight: 600; }

ul, li {
  list-style: none; }

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

option {
  height: 30px;
  line-height: 30px;
  padding: 5px 0 5px 20px; }

a:hover {
  text-decoration: none;
  color: #ff8400; }

.home3 a.button:hover {
  background-color: #3374a6;
  color: white; }

a.button {
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 33px;
  padding: 0 20px;
  text-transform: uppercase; }

a.button:hover {
  background-color: #ff8400;
  color: white; }

a.orange {
  background-color: #ff8400;
  color: white; }

a.dark {
  background-color: #3e3e3e;
  color: white; }

a.blue {
  background-color: #3366cc;
  color: white; }

a.bg-666 {
  background-color: #666666;
  color: white; }

a.button-check-out {
  color: #333333;
  background-color: #dbdbdb;
  height: 34px;
  line-height: 33px; }

a.red {
  background-color: #c3023b;
  color: white; }

.sidebar-left .content {
  float: right; }

.sidebar-right .sidebar {
  float: right; }

.container {
  padding: 0; }

/* -------------------- */
/*  Padding Section     */
/* -------------------- */
.container {
  padding: 0; }

#section3 {
  padding-top: 10px; }

section#section4 {
  padding-top: 30px; }

#section6 {
  padding-top: 10px; }

#section7 {
  padding-top: 23px; }

#section8 {
  padding-top: 19px; }

#section92 {
  padding-top: 30px; }

#section10 {
  padding-top: 30px; }

#section11 {
  padding-top: 30px; }

#section12 {
  padding-top: 30px; }

#section13 {
  padding-top: 30px; }

#section14 {
  padding-bottom: 30px; }

#section15 {
  padding: 40px 0; }

#section17 {
  padding-top: 30px; }

#section20 {
  padding-top: 30px; }

#section22 {
  padding-top: 22px; }

#section24 {
  padding-top: 10px; }

#section25 {
  padding: 40px 0; }

#section27 {
  padding-top: 24px; }

#section28 {
  padding-top: 30px; }

#section29 {
  padding-top: 30px; }

#section32 {
  padding-top: 25px;
  padding-bottom: 40px; }

#section33 {
  padding-top: 19px;
  padding-bottom: 25px; }

#section34 {
  padding-top: 22px; }

#section35 {
  padding-top: 30px; }

#section38 {
  padding-top: 25px; }

#section41 {
  padding-top: 25px; }

#section42 {
  padding-top: 23px; }

#section43 {
  background: #f2f2f2 none repeat scroll 0 0;
  margin-top: 10px;
  padding: 30px 0; }

#section44 {
  padding-top: 34px; }

#section45 {
  padding-top: 38px; }

#section46 {
  padding-top: 20px; }

#section47 {
  padding-top: 40px; }

#section48 {
  padding-top: 30px; }

#banner-home5 {
  padding-top: 20px; }

@media (min-width: 992px) {
  .padding-right0 {
    padding-right: 0px; }

  .padding-left0 {
    padding-left: 0px; }

  .padding-right5 {
    padding-right: 5px; }

  .padding-left5 {
    padding-left: 5px; }

  .padding-right10 {
    padding-right: 10px; }

  .padding-left10 {
    padding-left: 10px; }

  .padding-top10 {
    padding-top: 10px; }

  .no-padding {
    padding: 0; } }
/* -------------------- */
/* -------------------- */
/*      Header          */
/* -------------------- */
.menu-primary {
  background-color: #666666; }

nav > ul > li {
  display: inline-block;
  line-height: 44px;
  padding: 0 14px; }

nav > ul > li > a {
  color: white;
  text-transform: uppercase; }

nav > ul > li a:hover {
  color: #ff8400; }

.has-childrent {
  position: relative; }

.has-childrent .sub-menu {
  background-color: white;
  border: 1px solid #dddddd;
  left: 0;
  line-height: 30px;
  padding: 10px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(50px);
  -ms-transform: translateY(50px);
  -o-transform: translateY(50px);
  transform: translateY(50px);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  position: absolute;
  top: 100%;
  min-width: 230px;
  z-index: 6; }

.has-childrent:hover .sub-menu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: all 0.6s ease-in-out 0s;
  -moz-transition: all 0.6s ease-in-out 0s;
  -ms-transition: all 0.6s ease-in-out 0s;
  -o-transition: all 0.6s ease-in-out 0s;
  transition: all 0.6s ease-in-out 0s;
  z-index: 5; }

select:focus::-ms-value {
  background: transparent !important;
  color: #333333 !important; }

.header-top {
  background: transparent url("https://cdn.shopify.com/s/files/1/0003/3367/6563/files/bg-header-top.jpg?6606005174222752827") no-repeat scroll center top;
  padding: 15px 0 10px;
  color: white; }

.header-top h5 {
  color: white;
  margin: 0;
  font-size: 18px;
  text-transform: uppercase; }

.header-top p {
  margin-bottom: 15px;
  font-size: 16px; }

.header-mid {
  background-color: #ececec;
  padding-top: 5px; }

.header-mid-left {
  display: inline-block;
  float: left;
  font-size: 13px;
  margin-top: 3px; }

.header-mid-left p {
  float: left; }

.header-mid-left .wellcome-to {
  color: #000000;
  margin: 0 15px 0 0; }

.header-mid-left .register-or-login {
  color: #888888;
  margin: 0; }

.header-mid-left .register-or-login a {
  color: #ff8400;
  font-size: 14px;
  font-weight: 700; }

.header-mid-right {
  display: inline-block;
  float: right; }

.header-mid-right div {
  float: left;
  display: inline-block; }

.header-mid-right .header-mid-right-content a {
  color: #000000;
  font-size: 13px;
  line-height: 30px; }

.header-mid-right .header-mid-right-content a:hover {
  color: #ff8400; }

.header-mid-right .header-mid-right-content a > i {
  font-size: 18px;
  margin: 0 5px 0 20px; }

.country-dropdown .country:hover {
  color: #ff8400; }

.country-dropdown .country:hover img {
  opacity: 0.8; }

.money-dropdown .money:hover {
  color: #ff8400; }

.country-select-menu {
  margin: 0 5px 0 20px; }

.country-select-menu, .money-select-menu {
  position: relative; }

.money-select-menu:hover .money-dropdown,
.country-select-menu:hover .country-dropdown {
  background-color: white;
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: all 0.6s ease-in-out 0s;
  -moz-transition: all 0.6s ease-in-out 0s;
  -ms-transition: all 0.6s ease-in-out 0s;
  -o-transition: all 0.6s ease-in-out 0s;
  transition: all 0.6s ease-in-out 0s;
  z-index: 5; }

.country.select > img {
  float: left;
  margin: 5px 10px 0 0; }

.money-select {
  border: 1px solid #ff8400;
  font-size: 13px;
  height: 31px;
  line-height: 30px;
  padding: 0 10px;
  margin-bottom: 3px;
  background-color: white;
  cursor: pointer; }

.money-select i {
  text-align: center;
  width: 20px; }

.money-select .money-dropdown {
  border: 1px solid #bbb;
  left: 0;
  right: 0;
  top: 100%;
  padding: 2px 10px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(50px);
  -ms-transform: translateY(50px);
  -o-transform: translateY(50px);
  transform: translateY(50px);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  position: absolute; }

.country-select {
  border: 1px solid #ff8400;
  font-size: 13px;
  height: 31px;
  color: #333333;
  line-height: 30px;
  padding: 0 10px;
  margin-bottom: 3px;
  background-color: white;
  cursor: pointer; }

.country-select i {
  text-align: center;
  width: 20px; }

.country-select .country-dropdown {
  border: 1px solid #bbb;
  left: 0;
  width: 140px;
  top: 100%;
  padding: 12px 10px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(50px);
  -ms-transform: translateY(50px);
  -o-transform: translateY(50px);
  transform: translateY(50px);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  position: absolute; }

.country-select .country-dropdown .country > img {
  margin: 5px 5px 10px 0; }

.header-search {
  display: inline-block;
  float: left;
  margin: 17px 0 0 65px; }

.header-bottom {
  padding: 20px 0 27px; }

h1.logo {
  display: inline-block;
  line-height: 25px;
  float: left; }

h1.logo a > i {
  background-color: #ff8400;
  border-radius: 70% 30%;
  color: white;
  display: inline-block;
  font-size: 50px;
  font-weight: bold;
  position: absolute;
  left: 0;
  top: 5px;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center; }

h1.logo .logo-name {
  color: #333333;
  display: inline-block;
  font-size: 26px;
  font-style: italic;
  text-transform: uppercase;
  margin: 16px 0 0;
  font-weight: 400; }

h1.logo .logo-name span {
  color: #ff8400;
  font-size: 25px;
  font-style: initial;
  font-weight: 900;
  margin-left: 6px; }

h1.logo .logo-desc {
  color: #888;
  display: inline-block;
  float: left;
  font-size: 14px;
  margin: 0; }

.form-search-header {
  border: 1px solid #dddddd;
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  -ms-border-radius: 0 3px 3px 0;
  -o-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
  display: inline-block;
  background: white; }

.form-search-header select, .form-search-header input, .form-search-header a {
  border: medium none;
  display: inline-block;
  float: left;
  height: 32px;
  line-height: 32px; }

.form-search-header select {
  background: transparent url("https://cdn.shopify.com/s/files/1/0003/3367/6563/files/select.png?10230981298140006378") no-repeat scroll 89% 50%;
  padding: 0 60px 0 20px;
  margin-top: 2px;
  border-right: 1px solid #dddddd;
  background: white; }

.form-search-header input {
  padding: 0 25px;
  width: 320px;
  margin-top: 2px; }

.form-search-header .button-search {
  background-color: #ff8400;
  border: medium none;
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  -ms-border-radius: 0 3px 3px 0;
  -o-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
  color: white;
  font-size: 20px;
  font-weight: bold;
  line-height: 38px;
  margin: -1px;
  text-align: center;
  width: 45px; }

.header-bottom-right {
  display: inline-block;
  float: right;
  margin-top: 28px; }

.header-bottom-right > div {
  color: #333333;
  float: left;
  font-size: 16px; }

.header-bottom-right i {
  font-size: 28px;
  font-weight: 600;
  margin-right: 5px; }

.cart-menu {
  position: relative;
  margin-left: 17px;
  margin-right: 15px; }

.cart-menu .cart-amount {
  background-color: #ff8400;
  border-radius: 50%;
  color: white;
  font-size: 16px;
  font-weight: 700;
  height: 22px;
  left: 15px;
  line-height: 22px;
  margin: 0;
  position: absolute;
  text-align: center;
  top: -10px;
  width: 22px; }

.cart-menu-relative {
  position: relative; }

.cart-menu-relative .cart-hover {
  border: 1px solid #dddddd;
  position: absolute;
  right: 0;
  top: 100%;
  width: 310px;
  z-index: 9;
  background-color: #f5f5f5;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(50px);
  -ms-transform: translateY(50px);
  -o-transform: translateY(50px);
  transform: translateY(50px);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  position: absolute; }

.cart-menu-relative .cart-hover ul, .cart-menu-relative .cart-hover li {
  width: 100%; }

.cart-menu-relative:hover .cart-hover {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: all 0.6s ease-in-out 0s;
  -moz-transition: all 0.6s ease-in-out 0s;
  -ms-transition: all 0.6s ease-in-out 0s;
  -o-transition: all 0.6s ease-in-out 0s;
  transition: all 0.6s ease-in-out 0s;
  z-index: 9; }

.cart-menu-relative .cart-hover-title {
  background-color: #dfdfdf;
  color: #333333;
  line-height: 43px;
  font-size: 14px;
  font-weight: 700;
  padding: 0 38px 0 14px;
  text-transform: uppercase; }

.cart-menu-relative .cart-hover .list-hover-cart {
  max-height: 332px;
  overflow-y: scroll; }

.cart-menu-relative .cart-hover-title span {
  float: right; }

.cart-menu-relative .list-hover-cart {
  display: inline-block;
  padding: 17px 14px; }

.cart-menu-relative .list-hover-cart > li {
  display: inline-block;
  margin-bottom: 10px;
  padding-right: 65px;
  position: relative; }

.cart-menu-relative .img-hover-cart {
  display: inline-block;
  float: left;
  margin-right: 10px;
  position: relative; }

.cart-menu-relative .delete-product-hover-cart {
  bottom: -8px;
  font-size: 1px;
  position: absolute;
  right: -3px; }

.cart-menu-relative .delete-product-hover-cart i {
  font-size: 15px; }

.cart-menu-relative .price-hover-cart {
  background-color: #666;
  border-radius: 3px;
  color: white;
  font-size: 14px;
  font-weight: 700;
  padding: 3px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 65px; }

.cart-menu-relative .text-hover-cart {
  font-size: 13px;
  line-height: 20px; }

.cart-menu-relative .text-hover-cart .quantity {
  margin: 5px 0; }

.cart-menu-relative .text-hover-cart p {
  font-size: 13px;
  margin: 0;
  line-height: 20px; }

.cart-menu-relative .subtotal-hover-cart {
  border-color: #dddddd;
  border-style: solid;
  border-width: 1px 0;
  color: #333333;
  font-size: 18px;
  font-weight: bold;
  margin: 0 14px;
  padding: 15px 0;
  text-transform: uppercase; }

.cart-menu-relative .subtotal-hover-cart > span {
  float: right; }

.cart-menu-relative .button-cart-hover {
  padding: 15px; }

.cart-menu-relative .button-cart-hover .go-to-cart {
  background-color: #dfdfdf; }

.cart-menu-relative .button-cart-hover .go-to-cart:hover {
  background-color: #ff8400;
  color: white; }

.cart-menu-relative .button-cart-hover .orange {
  float: right; }

.cart-menu-relative .button-cart-hover .orange:hover {
  background-color: #3e3e3e;
  color: white; }

.cart-menu-relative .button-cart-hover .button {
  font-size: 14px;
  font-weight: 600;
  padding: 0 25px; }

nav > ul > li.item-home > a {
  font-size: 28px;
  line-height: 20px;
  top: 6px;
  position: relative; }

nav > ul > li.item-home .show-submenu {
  display: none; }

.header-style1 .site-navigation, .header-style2 .site-navigation, .header-style3 .site-navigation, .header-style6 .site-navigation {
  float: left;
  padding-left: 15px; }

.header-style1 .has-mega-menu {
  width: 200px; }

.header-style1 .has-mega-menu .category-drop-list {
  border: 1px solid #dddddd;
  padding: 5px 0 0;
  z-index: 5;
  display: block; }

.header-style1 .has-mega-menu .category-drop-list .category-drop-list-inner {
  height: 453px;
  padding-bottom: 44px; }

.header-style1 .has-mega-menu .category-drop-list .category-drop-list-inner > .sub-menu {
  height: calc(100% - 15px); }

.header-style1 .more-categories {
  background: #ff8400 none repeat scroll 0 0;
  bottom: -1px;
  color: white;
  font-size: 16px;
  font-weight: 400;
  left: -1px;
  line-height: 37px;
  right: -1px;
  width: auto;
  text-align: left;
  padding-left: 19px; }

.header-style1 .more-categories:hover {
  color: white;
  background-color: #7e7e7e; }

.header-style1 .more-categories::after {
  display: none; }

.header-style1 .form-search-header select, .header-style1 .form-search-header input, .header-style1 .form-search-header a {
  border-radius: 0; }

.header-style1 .cart-menu i.fa.fa-caret-down {
  font-size: 12px;
  margin-left: 5px;
  margin-right: 0; }

.header-style1 .cart-menu i {
  font-size: 29px; }

.header-style1 .form-search-header select {
  background: transparent url("https://cdn.shopify.com/s/files/1/0003/3367/6563/files/select.png?10230981298140006378") no-repeat scroll 85% center; }

.header-style2 .has-mega-menu .category-drop-list {
  border: 1px solid #dddddd;
  padding: 5px 0 0;
  z-index: 5;
  display: none; }

.header-style2 .has-mega-menu .category-drop-list .category-drop-list-inner {
  height: 405px;
  padding-bottom: 44px; }

.header-style2 .has-mega-menu .category-drop-list .category-drop-list-inner > .sub-menu {
  height: calc(100%);
  padding: 0; }

.header-style2 .header-bottom {
  padding-bottom: 30px; }

.header-style2 .has-mega-menu {
  width: 200px; }

.header-style2 .more-categories {
  line-height: 37px;
  background: #7e7e7e;
  color: white;
  font-size: 16px;
  font-weight: 400;
  bottom: -1px;
  left: -1px;
  right: -1px;
  width: auto; }

.header-style2 .more-categories:hover {
  color: white;
  background-color: #ff8400; }

.header-style2 .form-search-header select, .header-style2 .form-search-header input, .header-style2 .form-search-header a {
  border-radius: 0; }

.header-style2 .cart-menu i {
  font-size: 30px; }

.header-style2 .cart-menu i.fa.fa-caret-down {
  font-size: 12px;
  margin-left: 5px;
  margin-right: 0; }

.header-style2 .form-search-header select {
  background: transparent url("https://cdn.shopify.com/s/files/1/0003/3367/6563/files/select.png?10230981298140006378") no-repeat scroll 85% center; }

.menu-primary.menu-fixed {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99999; }

.more-categories {
  position: absolute;
  line-height: 47px;
  background: #f2f2f2;
  width: 100%;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
  color: #333333;
  bottom: 0; }

.more-categories::after {
  content: "\f101";
  font-family: FontAwesome;
  font-size: 14px;
  line-height: 25px;
  position: relative;
  top: 1px;
  margin-left: 13px;
  font-weight: normal; }

/*Header Style 3*/
.header-style3 .header-mid-left .wellcome-to {
  color: #333333;
  margin-right: 3px; }

.header-style3 nav > ul > li > a {
  font-weight: 600; }

.header-style3 nav > ul > li > a i {
  margin-left: 5px; }

.header-style3 .header-top {
  background: transparent url("bg-header-top-h3.jpg") repeat scroll center center/cover;
  padding: 10px 0 30px; }

.header-style3 .header-top h5 {
  color: #333333;
  font-style: 14px; }

.header-style3 .header-top p {
  color: #666666;
  margin: 0; }

.header-style3 .header-top .button {
  line-height: 30px; }

.header-style3 .header-mid {
  background-color: #f6f6f6;
  color: #333333;
  padding: 3px 0; }

.header-style3 .header-mid .country-select {
  background-color: transparent;
  border: medium none;
  line-height: 33px;
  font-size: 14px;
  padding-right: 0; }

.header-style3 .header-mid .country-select:hover > .country {
  color: #3374a6; }

.header-style3 .header-mid .country-select i {
  margin-left: 3px; }

.header-style3 .header-mid-right .header-mid-right-content {
  position: relative;
  margin-left: 10px; }

.header-style3 .header-mid-right .header-mid-right-content::before {
  border-left: 1px solid #666666;
  content: "";
  height: 10px;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.header-style3 .header-mid-right .header-mid-right-content a > i {
  font-size: 18px;
  margin: 0 5px 0 10px; }

.header-style3 .header-mid-right .header-mid-right-content:first-child {
  margin-left: 0; }

.header-style3 .header-mid-right .header-mid-right-content:first-child::before {
  display: none; }

.header-style3 .form-search-header {
  position: relative; }

.header-style3 .form-search-header input {
  width: 290px; }

.header-style3 .form-search-header select {
  border-right: medium none;
  padding: 0 25px 0 0;
  color: #333333;
  max-width: 150px;
  background: transparent url("select2.png") no-repeat scroll 85% 20px; }

.header-style3 .form-search-header:before {
  position: absolute;
  content: '';
  top: 5px;
  bottom: 4px;
  left: 265px;
  width: 1px;
  background-color: #ddd;
  display: inline-block; }

.header-style3 .my-supermarket .supermarket {
  margin-right: 0px; }

.header-style3 .header-search {
  margin: 17px 0 0 50px; }

.header-style3 i.fa.fa-caret-down {
  font-size: 12px;
  margin-left: 5px;
  margin-right: 0; }

.header-style3 .header-bottom-right-content i, .header-style3 .header-bottom-right-content .text-bold {
  font-weight: 600; }

.header-style3 h1.logo a > i,
.header-style3 .form-search-header a {
  background-color: #3374a6; }

.header-style3 .header-bottom-right i {
  font-size: 30px; }

.header-style3 .form-search-header .button-search {
  background-color: #3374a6;
  border: medium none;
  border-radius: 0 3px 3px 0;
  color: white;
  font-size: 20px;
  font-weight: bold;
  line-height: 46px;
  margin: -1px;
  text-align: center;
  width: 50px; }

.header-style3 .form-search-header select, .header-style3 .form-search-header input, .header-style3 .form-search-header a {
  height: 42px;
  line-height: 44px; }

.header-style3 h1.logo .logo-name span,
.header-style3 .header-mid-left .register-or-login a,
.header-style3 .header-mid-right .header-mid-right-content a:hover {
  color: #3374a6; }

.header-style3 .cart-menu .cart-amount {
  background-color: #3374a6;
  border-radius: 7px;
  font-size: 14px;
  height: 16px;
  line-height: 13px;
  width: 37px;
  top: -10px; }

.header-style3 .cart-menu .cart-amount::before {
  background: transparent none repeat scroll 0 0;
  border-color: transparent transparent #3374a6;
  border-style: solid;
  border-width: 0 0 14px 10px;
  bottom: -4px;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  transform: rotate(-34deg);
  width: 1px; }

.header-style3 .has-mega-menu {
  width: 270px; }

.header-style3 .has-mega-menu .sub-menu li {
  cursor: pointer;
  display: inline-block;
  float: left;
  line-height: 37px;
  margin: 0;
  padding: 0 15px 0 25px; }

.header-style3 .has-mega-menu .sub-menu li .show-submenu {
  color: #333333;
  float: right;
  height: 35px;
  text-align: right;
  width: 35px; }

.header-style3 .has-mega-menu .sub-menu li .show-submenu i {
  font-size: 14px;
  margin: 0; }

.header-style3 .has-mega-menu .sub-menu li i {
  font-size: 25px;
  margin-right: 20px;
  position: relative;
  top: 3px; }

.header-style3 .has-mega-menu .sub-menu li:hover .show-submenu i {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg); }

.header-style3 .has-mega-menu .category-drop-list > .more-categories {
  background-color: #3374a6;
  font-weight: 600;
  line-height: 46px;
  text-align: center;
  width: 100%; }

.header-style3 .has-mega-menu .category-drop-list > .more-categories i {
  margin-left: 14px;
  position: relative;
  top: 1px; }

.header-style3 .has-mega-menu .category-drop-list li li {
  line-height: 22px; }

.header-style3 .cart-menu i {
  font-size: 32px; }

.header-style3 .cart-menu {
  margin-right: 0; }

.header-style3 .has-mega-menu .category-drop-list {
  padding: 10px 0 0; }

.header-style3 .has-mega-menu .category-drop-list .category-drop-list-inner {
  height: 485px;
  padding-bottom: 45px; }

.header-style3 .has-mega-menu .category-drop-list .category-drop-list-inner > .sub-menu {
  height: calc(100% - 35px);
  overflow: hidden;
  padding-bottom: 30px; }

.header-style3 .has-mega-menu .category-drop-list .more-categories {
  background-color: #3374a6;
  font-size: 16px;
  color: white; }

.my-supermarket {
  line-height: 30px;
  margin-right: 10px;
  position: relative; }

.my-supermarket .supermarket {
  display: inline-block;
  margin-right: 5px;
  margin-top: -9px;
  position: relative; }

.my-supermarket .supermarket p {
  height: 14px;
  line-height: 14px;
  margin-bottom: 6px; }

.my-supermarket .hover-supermarket {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(50px);
  -ms-transform: translateY(50px);
  -o-transform: translateY(50px);
  transform: translateY(50px);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  position: absolute;
  background-color: white;
  border: 1px solid #dddddd;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  padding: 17px;
  right: 0;
  width: 250px; }

.my-supermarket .hover-supermarket .singin-super {
  background-color: #3374a6;
  border-radius: 3px;
  color: white;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  height: 45px;
  line-height: 45px;
  text-align: center;
  width: 100%; }

.my-supermarket .market-list {
  border-bottom: 1px solid #dddddd;
  padding: 16px 0 10px; }

.my-supermarket .market-list a {
  display: inline-block;
  font-size: 14px;
  padding-top: 3px;
  width: 100%; }

.my-supermarket .submit-request {
  font-size: 14px; }

.my-supermarket p {
  color: #666666;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px; }

.my-supermarket:hover .hover-supermarket {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: all 0.6s ease-in-out 0s;
  -moz-transition: all 0.6s ease-in-out 0s;
  -ms-transition: all 0.6s ease-in-out 0s;
  -o-transition: all 0.6s ease-in-out 0s;
  transition: all 0.6s ease-in-out 0s;
  z-index: 5; }

/*Header Style 4*/
.header-style4 .header-bottom {
  padding: 27px 0;
  background: #443266;
  border-bottom: 4px solid #f8b91d;
  color: white; }

.header-style4 .menu-primary {
  background-color: #f2f2f2; }

.header-style4 .site-navigation {
  background: #e2e2e2 none repeat scroll 0 0;
  line-height: 50px;
  margin: 10px 0;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  text-align: center; }

.header-style4 .site-navigation .menu-item i {
  font-size: 28px;
  top: 0px;
  position: relative; }

.header-style4 .site-navigation .show-submenu {
  color: #333333;
  margin-left: 5px; }

.header-style4 .site-navigation .show-submenu i {
  font-size: 14px; }

.header-style4 nav > ul > li {
  padding: 0 20px; }

.header-style4 nav > ul > li > a {
  color: #333333;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px; }

.header-style4 .sub-menu {
  text-align: left; }

.header-style4 .my-supermarket .hover-supermarket {
  width: 225px;
  border-radius: 0;
  border: 1px solid #aaa; }

.header-style4 .my-supermarket .hover-supermarket .singin-super {
  background-color: #f8b91d;
  color: #32728b;
  height: 29px;
  line-height: 29px; }

.header-style4 .my-supermarket .hover-supermarket .register {
  color: #333333;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
  margin-top: 5px; }

.header-style4 .my-supermarket .hover-supermarket .register a:hover {
  color: #f8b91d; }

.header-style4 .my-supermarket .hover-supermarket .title-element {
  color: #f8b91d;
  font-size: 14px; }

.header-style4 .my-supermarket .hover-supermarket .market-list {
  border-bottom: medium none;
  padding: 3px 0 8px; }

.header-style4 .my-supermarket .hover-supermarket .market-list .market-list-item {
  line-height: 20px; }

.header-style4 .my-supermarket .hover-supermarket .market-list .market-list-item:hover a {
  color: #f8b91d; }

.header-style4 .header-bottom-right i {
  font-size: 32px;
  top: 2px;
  position: relative; }

.header-style4 .header-bottom-right i.fa-caret-down {
  font-size: 14px;
  margin-left: 7px; }

.header-style4 .cart-menu .link-top {
  color: white; }

.header-style4 .menu-primary .container {
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s; }

.header-style4 .menu-primary.menu-fixed .container {
  width: 100%; }

.header-style4 .menu-primary.menu-fixed .container .site-navigation {
  margin: 0; }

.header-style4 .header-bottom-center {
  padding-left: 0; }

.header-style4 .header-search {
  margin: 0; }

.header-style4 .form-search-header {
  border: none;
  border-radius: 3px;
  display: inline-block;
  background: white; }

.header-style4 .header-bottom-right {
  margin-top: 10px;
  float: right;
  width: auto; }

.header-style4 .form-search-header input {
  padding: 0 15px;
  width: 290px;
  color: #888;
  margin-top: 0;
  line-height: 43px;
  height: 44px; }

.header-style4 .form-search-header select {
  background: transparent url("select3.png") no-repeat scroll 89% 50%;
  border-left: 1px solid #dddddd;
  padding: 0 40px 0 15px;
  border-right: none;
  border-radius: 0;
  margin-top: 5px;
  line-height: 33px;
  color: #333333;
  height: 33px; }

.header-style4 .form-search-header .button-search {
  background-color: #f8b91d;
  border: medium none;
  border-radius: 0 3px 3px 0;
  color: white;
  font-size: 22px;
  font-weight: bold;
  line-height: 43px;
  margin: 0;
  text-align: center;
  width: 50px;
  line-height: 43px; }

.header-style4 .form-search-header .button-search:hover {
  background-color: #da3838;
  color: white; }

.header-style4 .money-select, .header-style4 .country-select {
  background-color: transparent;
  border: medium none;
  color: #333333;
  cursor: pointer;
  font-size: 14px;
  height: 60px;
  line-height: 60px;
  margin-bottom: 0;
  padding: 0; }

.header-style4 .money-select i, .header-style4 .country-select i {
  color: #666666;
  font-size: 12px;
  width: auto;
  margin-left: 10px; }

.header-style4 .country-select .country-dropdown, .header-style4 .money-select .money-dropdown {
  line-height: 30px; }

.header-style4 .header-mid-right .header-mid-right-content a > i {
  margin: 0 5px 0 0;
  position: relative;
  top: 2px;
  font-size: 19px; }

.header-style4 .header-mid-right-content {
  margin-left: 14px;
  padding-left: 14px;
  position: relative; }

.header-style4 .header-mid-right-content::before {
  content: "";
  height: 10px;
  border-left: 1px solid #666;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%); }

.header-style4 .header-mid-right-content:first-child {
  margin: 0;
  padding: 0; }

.header-style4 .header-mid-right-content:first-child::before {
  content: ""; }

.header-style4 .country.select > img {
  margin: -4px 10px 0 0;
  float: none; }

.header-style4 .header-mid {
  background-color: white;
  padding-top: 0;
  line-height: 60px; }

.header-style4 .header-mid .header-mid-left {
  margin-top: 0; }

.header-style4 .header-mid .header-mid-right {
  display: inline-block;
  float: right; }

.header-style4 .my-supermarket .supermarket {
  padding-bottom: 9px; }

.header-style4 .cart-menu {
  padding-bottom: 6px;
  margin-right: 0; }

.header-style4 .cart-menu .cart-amount {
  background: transparent url("cart-number2.png") no-repeat scroll 0 0;
  color: white;
  font-size: 14px;
  font-weight: 700;
  height: 30px;
  left: 17px;
  line-height: 17px;
  margin: 0;
  position: absolute;
  text-align: center;
  top: -12px;
  width: 31px; }

.header-style4 .cart-menu-relative:hover .link-top {
  color: #f8b91d; }

.header-style4 .category-drop-list-inner {
  height: 445px;
  padding-bottom: 44px;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s; }

/*Header Style 5*/
.header-style5 .header-bottom {
  background: white;
  border-bottom: none;
  color: white;
  padding: 18px 0 22px; }

.header-style5 .menu-primary {
  background-color: white; }

.header-style5 .site-navigation {
  background: #f2f2f2 none repeat scroll 0 0;
  line-height: 50px;
  margin: 0;
  border-bottom: 1px solid #0b2076;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  text-align: center; }

.header-style5 .site-navigation .menu-item i {
  font-size: 28px;
  top: 0px;
  position: relative; }

.header-style5 .site-navigation .show-submenu {
  color: #333333;
  margin-left: 5px; }

.header-style5 .site-navigation .show-submenu i {
  font-size: 14px; }

.header-style5 nav > ul > li {
  padding: 0 20px; }

.header-style5 nav > ul > li > a {
  color: #333333;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px; }

.header-style5 .sub-menu {
  text-align: left; }

.header-style5 .my-supermarket .supermarket .my-acc a {
  color: #333333; }

.header-style5 .my-supermarket .supermarket .my-acc a:hover {
  color: #3374a6; }

.header-style5 .my-supermarket .supermarket {
  display: inline-block;
  margin-right: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative; }

.header-style5 .my-supermarket .hover-supermarket {
  width: 225px;
  border-radius: 0;
  border: 1px solid #aaa; }

.header-style5 .my-supermarket .hover-supermarket .singin-super {
  background-color: #da3838;
  color: white;
  height: 29px;
  line-height: 29px; }

.header-style5 .my-supermarket .hover-supermarket .register {
  color: #333333;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
  margin-top: 5px; }

.header-style5 .my-supermarket .hover-supermarket .register a:hover {
  color: #da3838; }

.header-style5 .my-supermarket .hover-supermarket .title-element {
  color: #da3838;
  font-size: 14px; }

.header-style5 .my-supermarket .hover-supermarket .market-list {
  border-bottom: medium none;
  padding: 3px 0 8px; }

.header-style5 .my-supermarket .hover-supermarket .market-list .market-list-item {
  line-height: 20px; }

.header-style5 .my-supermarket .hover-supermarket .market-list .market-list-item:hover a {
  color: #da3838; }

.header-style5 .header-bottom-right i {
  font-size: 27px;
  top: 2px;
  position: relative; }

.header-style5 .header-bottom-right i.fa-caret-down {
  font-size: 14px;
  margin-left: 7px; }

.header-style5 .menu-primary .container {
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s; }

.header-style5 .menu-primary.menu-fixed .container {
  width: 100%; }

.header-style5 .menu-primary.menu-fixed .container .site-navigation {
  margin: 0;
  border: none; }

.header-style5 .header-search {
  margin: 10px 0 0; }

.header-style5 .form-search-header {
  background: white none repeat scroll 0 0;
  border: 2px solid #bbbbbb;
  border-radius: 3px;
  display: inline-block; }

.header-style5 .header-bottom-right {
  margin-top: 7px; }

.header-style5 .form-search-header input {
  padding: 0 15px;
  width: 240px;
  color: #888;
  margin-top: 0;
  line-height: 39px;
  height: 39px; }

.header-style5 .form-search-header select {
  background: transparent url("select3.png") no-repeat scroll 89% 50%;
  border-left: 1px solid #dddddd;
  padding: 0 40px 0 15px;
  border-right: none;
  border-radius: 0;
  margin-top: 5px;
  line-height: 29px;
  color: #333333;
  height: 29px; }

.header-style5 .form-search-header .button-search {
  background-color: #0b2076;
  border: medium none;
  border-radius: 0;
  color: white;
  font-size: 14px;
  font-weight: bold;
  line-height: 37px;
  margin: 1px;
  text-align: center;
  width: auto;
  padding: 0 20px; }

.header-style5 .form-search-header .button-search:hover {
  background-color: #da3838;
  color: white; }

.header-style5 .money-select, .header-style5 .country-select {
  background-color: transparent;
  border: medium none;
  color: white;
  cursor: pointer;
  font-size: 14px;
  height: 37px;
  line-height: 37px;
  margin-bottom: 0;
  padding: 0; }

.header-style5 .money-select i, .header-style5 .country-select i {
  color: white;
  font-size: 12px;
  width: auto;
  margin-left: 10px; }

.header-style5 .country-select .country-dropdown, .header-style5 .money-select .money-dropdown {
  line-height: 30px;
  color: #333333; }

.header-style5 .header-mid-right .header-mid-right-content a > i {
  margin: 0 5px 0 0;
  position: relative;
  top: 2px;
  font-size: 19px; }

.header-style5 .header-mid-right-content {
  margin-left: 14px;
  padding-left: 14px;
  position: relative; }

.header-style5 .header-mid-right-content::before {
  content: "";
  height: 10px;
  border-left: 1px solid #666;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%); }

.header-style5 .header-mid-right-content:first-child {
  margin: 0;
  padding: 0; }

.header-style5 .header-mid-right-content:first-child::before {
  content: ""; }

.header-style5 .country.select > img {
  margin: -4px 10px 0 0;
  float: none; }

.header-style5 .header-mid {
  background-color: #0b2076;
  padding-top: 0;
  line-height: 37px;
  color: white;
  font-size: 14px; }

.header-style5 .header-mid .header-mid-left {
  margin-top: 0; }

.header-style5 .header-mid .header-mid-left .register-or-login {
  color: white;
  margin: 0; }

.header-style5 .header-mid .header-mid-left .register-or-login a {
  color: white; }

.header-style5 .header-mid .header-mid-left .register-or-login a:hover {
  color: #ff8400; }

.header-style5 .header-mid .header-mid-right {
  display: inline-block;
  float: right; }

.header-style5 .header-mid .header-mid-right .header-mid-right-content a {
  color: white;
  font-size: 14px;
  line-height: 20px; }

.header-style5 a.orange {
  background-color: #0b2076; }

.header-style5 .cart-menu {
  background: #0b2076 none repeat scroll 0 0;
  float: right;
  margin-left: 15px;
  margin-right: 0;
  padding: 10px 15px;
  position: relative; }

.header-style5 .cart-menu .cart-amount {
  background: transparent url("cart-number.png") no-repeat scroll 0 0;
  border-radius: 50%;
  color: white;
  font-size: 14px;
  font-weight: 700;
  height: 30px;
  left: 32px;
  line-height: 19px;
  margin: 0;
  position: absolute;
  text-align: center;
  top: -5px;
  width: 31px; }

.header-style5 .cart-menu .link-top {
  background: transparent url("cart.png") no-repeat scroll 0 0;
  color: white;
  padding-left: 30px;
  height: 26px;
  display: inline-block; }

.header-style5 .header-bottom-right > div {
  float: right; }

.header-style5 .money-dropdown .money:hover {
  color: #0b2076; }

.header-style5 .menu-item.active > a, .header-style5 .menu-item.active > .show-submenu, .header-style5 .menu-item:hover > .show-submenu, .header-style5 nav > ul > li.menu-item:hover > a {
  color: #0b2076 !important; }

.header-style5 .country-dropdown .country:hover {
  color: #0b2076; }

.header-style5 nav > ul > li a:hover {
  color: #0b2076; }

.header-style6 .header-mid {
  background-color: #f2f2f2;
  padding-top: 0;
  line-height: 37px;
  color: #333333;
  font-size: 14px; }

.header-style6 .header-mid .header-mid-left {
  margin-top: 0; }

.header-style6 .header-mid .header-mid-left .register-or-login {
  color: #333333;
  margin: 0; }

.header-style6 .header-mid .header-mid-left .register-or-login a {
  color: #333333; }

.header-style6 .header-mid .header-mid-left .register-or-login a:hover {
  color: #ff8400; }

.header-style6 .header-mid .header-mid-right {
  display: inline-block;
  float: right; }

.header-style6 .header-mid .header-mid-right .header-mid-right-content a {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; }

.header-style6 .header-mid .header-mid-right .header-mid-right-content a:hover {
  color: #006393; }

.header-style6 .header-mid-right-content {
  margin-left: 14px;
  padding-left: 14px;
  position: relative; }

.header-style6 .header-mid-right-content a > i {
  font-size: 19px;
  margin: 0 5px 0 0;
  position: relative;
  top: 2px; }

.header-style6 .header-mid-right-content::before {
  border-left: 1px solid #666666;
  content: "";
  height: 10px;
  left: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%); }

.header-style6 .header-mid-right-content:first-child::before {
  display: none; }

.header-style6 .my-supermarket .hover-supermarket {
  width: 225px;
  border-radius: 0;
  border: 1px solid #aaa; }

.header-style6 .my-supermarket .hover-supermarket .singin-super {
  background-color: #006393;
  color: white;
  height: 29px;
  line-height: 29px; }

.header-style6 .my-supermarket .hover-supermarket .register {
  color: #333333;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
  margin-top: 5px; }

.header-style6 .my-supermarket .hover-supermarket .register a:hover {
  color: #006393; }

.header-style6 .my-supermarket .hover-supermarket .title-element {
  color: #006393;
  font-size: 14px; }

.header-style6 .my-supermarket .hover-supermarket .market-list {
  border-bottom: medium none;
  padding: 3px 0 8px; }

.header-style6 .my-supermarket .hover-supermarket .market-list .market-list-item {
  line-height: 20px; }

.header-style6 .my-supermarket .hover-supermarket .market-list .market-list-item:hover a {
  color: #006393; }

.header-style6 a.orange {
  background-color: #006393; }

.header-style6 .cart-menu .cart-amount {
  background: transparent url("cart-number1.png") no-repeat scroll 0 0;
  color: white;
  font-size: 14px;
  font-weight: 700;
  height: 30px;
  left: 17px;
  line-height: 17px;
  margin: 0;
  position: absolute;
  text-align: center;
  top: -12px;
  width: 31px; }

.header-style6 .money-select, .header-style6 .country-select {
  background-color: transparent;
  border: medium none;
  color: #333333;
  cursor: pointer;
  font-size: 14px;
  height: 37px;
  line-height: 37px;
  margin-bottom: 0;
  padding: 0; }

.header-style6 .money-select i, .header-style6 .country-select i {
  color: #333333;
  font-size: 12px;
  width: auto;
  margin-left: 10px; }

.header-style6 .country-select .country-dropdown, .header-style6 .money-select .money-dropdown {
  line-height: 30px;
  color: #333333; }

.header-style6 .country.select > img {
  float: none;
  margin: -4px 10px 0 0; }

.header-style6 .money.select:hover, .header-style6 .country.select:hover {
  color: #006393; }

.header-style6 .header-bottom {
  padding: 20px 0; }

.header-style6 .header-search {
  margin: 10px 0 0 50px; }

.header-style6 .form-search-header {
  background: white none repeat scroll 0 0;
  border: 1px solid #dddddd;
  border-radius: 3px;
  display: inline-block; }

.header-style6 .header-bottom-right {
  margin-top: 10px; }

.header-style6 .form-search-header input {
  padding: 0 15px;
  width: 250px;
  color: #888;
  margin-top: 0;
  line-height: 40px;
  height: 39px; }

.header-style6 .form-search-header select {
  background: transparent url("select3.png") no-repeat scroll 89% 50%;
  border-left: 1px solid #dddddd;
  border-radius: 0;
  border-right: medium none;
  color: #333333;
  height: 30px;
  line-height: 30px;
  margin-top: 5px;
  padding: 0 40px 0 15px; }

.header-style6 .form-search-header .button-search {
  background-color: #006393;
  border: medium none;
  border-radius: 0;
  color: white;
  font-size: 20px;
  font-weight: bold;
  line-height: 41px;
  margin: -1px;
  padding: 0 14px;
  text-align: center;
  width: 50px; }

.header-style6 .form-search-header .button-search:hover {
  background-color: #da3838;
  color: white; }

.header-style6 .menu-item.active > a, .header-style6 .menu-item.active > .show-submenu, .header-style6 .menu-item:hover > .show-submenu, .header-style6 nav > ul > li.menu-item:hover > a {
  color: #006393; }

.header-style6 nav > ul > li a:hover {
  color: #006393; }

.header-style6 .cart-menu i {
  font-size: 34px; }

.header-style6 .cart-menu-relative:hover .link-top {
  color: #006393; }

.header-style6 nav#categories-menu > ul > li > a:hover, .header-style6 nav#categories-menu > ul > li:hover > a {
  color: white; }

.header-style6 .my-supermarket .supermarket a:hover {
  color: #006393; }

.header-style6 i.fa.fa-caret-down {
  font-size: 12px;
  margin-left: 5px;
  margin-right: 0; }

.header-style6 .my-supermarket .supermarket {
  display: inline-block;
  margin-right: 5px;
  margin-top: -5px;
  position: relative; }

.header-style6 .my-supermarket .supermarket .register-or-login {
  color: #333333; }

.header-style6 .my-supermarket .supermarket .register-or-login a {
  color: #333333;
  margin: 0 2px; }

.header-style6 .my-supermarket .supermarket .register-or-login a:hover {
  color: #006393; }

.header-style6 .menu-primary {
  background-color: #006393; }

.header-style6 .has-mega-menu {
  background-color: #034261;
  width: 270px; }

.header-style6 .has-mega-menu .category-drop-list {
  padding: 0;
  border: 1px solid #dddddd;
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  z-index: 5; }

.header-style6 .has-mega-menu .category-drop-list .category-drop-list-inner {
  height: 482px;
  padding-bottom: 44px; }

.header-style6 .has-mega-menu .category-drop-list .category-drop-list-inner > .sub-menu {
  height: calc(100% - 45px); }

.header-style6 .has-mega-menu .category-drop-list.category-drop-list-show {
  height: auto; }

.header-style6 .has-mega-menu .category-drop-list > .sub-menu {
  padding-bottom: 30px;
  height: calc(100% - 40px); }

.header-style6 .has-mega-menu .category-drop-list .more-categories {
  background: #bbbbbb;
  font-size: 16px;
  width: auto;
  left: -1px;
  right: -1px;
  bottom: -1px; }

.header-style6 .has-mega-menu .category-drop-list .more-categories:hover {
  background-color: #006393;
  color: white; }

.header-style6 .has-mega-menu .category-drop-list li {
  display: inline-block;
  float: left;
  line-height: 35px;
  margin: 0;
  padding: 0 15px 0 25px;
  cursor: pointer; }

.header-style6 .has-mega-menu .category-drop-list li i {
  font-size: 25px;
  margin-right: 20px;
  top: 3px;
  position: relative; }

.header-style6 .has-mega-menu .category-drop-list li .show-submenu {
  color: #333333;
  float: right;
  width: 35px;
  height: 35px;
  text-align: right; }

.header-style6 .has-mega-menu .category-drop-list li .show-submenu i {
  font-size: 14px;
  margin: 0; }

.header-style6 .has-mega-menu .category-drop-list li:hover > a {
  color: #006393; }

.header-style6 .has-mega-menu .category-drop-list li:hover .show-submenu {
  color: #006393; }

.header-style6 .has-mega-menu .category-drop-list li:hover .show-submenu i {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg); }

.header-style6 .has-mega-menu .category-drop-list > .more-categories {
  background-color: #bbbbbb;
  color: #333333;
  font-size: 16px;
  line-height: 47px;
  text-align: center;
  margin: -1px;
  right: 0;
  width: auto; }

.header-style6 .has-mega-menu .category-drop-list > .more-categories:hover {
  background-color: #006393;
  color: white; }

.header-style6 .has-mega-menu .category-drop-list > .more-categories.show-before:hover::before {
  background-color: #006393;
  color: white; }

.header-style6 .has-mega-menu .category-drop-list > .more-categories.show-before {
  font-size: 0; }

.header-style6 .has-mega-menu .category-drop-list > .more-categories i {
  margin-left: 20px; }

.header-style6 nav > ul > li {
  display: inline-block;
  line-height: 50px;
  padding: 0 18px;
  font-size: 14px;
  font-weight: bold; }

.header-style6 nav.main-menu > ul > li:hover > a {
  color: #f8b91d; }

.header-style6 nav.main-menu > ul > li:hover > .show-submenu {
  color: #f8b91d; }

.menu-item.active > a, .menu-item.active > .show-submenu, .menu-item:hover > .show-submenu, nav > ul > li.menu-item:hover > a {
  color: #ff8400;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s; }

.home3 .menu-item.active > a, .home3 .menu-item.active > .show-submenu, .home3 .menu-item:hover > .show-submenu, .home3 nav > ul > li.menu-item:hover > a {
  color: #ff8400; }

.site-navigation .show-submenu {
  color: white;
  margin-left: 5px; }

.show-submenu {
  cursor: pointer;
  float: right; }

/*Filter Category*/
.categories-filter {
  position: relative;
  padding-bottom: 7px; }

.categories-filter .categories-filter-label {
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 1px;
  margin: 0 0 3px; }

.categories-filter .click-categories a {
  color: white;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px; }

.categories-filter .click-categories a:hover {
  color: #d69a04; }

.categories-filter .category-drop-list {
  background: white none repeat scroll 0 0;
  min-width: 200px;
  left: 0;
  position: absolute;
  top: 100%;
  z-index: 9;
  padding: 1px 0 !important;
  display: none; }

.categories-filter .category-drop-list .sub-menu {
  padding: 12px 0 0; }

.categories-filter .category-drop-list .sub-menu > li {
  line-height: 30px;
  padding: 0 13px 0 19px; }

.categories-filter .category-drop-list .sub-menu .sub-menu {
  position: absolute;
  opacity: 0;
  -webkit-transform: translateY(50);
  -ms-transform: translateY(50);
  -o-transform: translateY(50);
  transform: translateY(50);
  padding: 0;
  visibility: hidden; }

.categories-filter .category-drop-list .sub-menu .sub-menu .menu-item > a {
  color: #888; }

.categories-filter .category-drop-list .sub-menu .sub-menu .menu-item > a:hover {
  color: #d69a04; }

.categories-filter .category-drop-list .menu-item:hover > a, .categories-filter .category-drop-list .menu-item:hover .show-submenu {
  color: #d69a04; }

.categories-filter .category-drop-list .menu-item:hover > a i, .categories-filter .category-drop-list .menu-item:hover .show-submenu i {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg); }

.categories-filter .category-drop-list .menu-item:hover .sub-menu {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
  visibility: visible; }

.categories-filter .show-submenu {
  color: #333333;
  float: right; }

.categories-filter .show-submenu i {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  line-height: 15px; }

.categories-filter .more-categories {
  background: #e2e2e2 none repeat scroll 0 0;
  color: #333333;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  line-height: 44px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0; }

.categories-filter .more-categories:hover {
  color: white;
  background-color: #443266; }

.categories-filter .more-categories i {
  margin-left: 10px; }

.my-supermarket .supermarket .register-or-login {
  color: white; }

.my-supermarket .supermarket .register-or-login a {
  color: white;
  margin: 0 2px; }

.my-supermarket .supermarket .register-or-login a:hover {
  color: #f8b91d; }

.my-supermarket .supermarket .my-acc a {
  color: white; }

.my-supermarket .supermarket .my-acc a:hover {
  color: #f8b91d; }

/* -------------------- */
/*      end Header      */
/* -------------------- */
/* -------------------- */
/*     banner-slide     */
/* -------------------- */
.banner-home1 .supermartket-owl-carousel .active h3 {
  -webkit-animation: fadeInRight 0.3s ease-in-out 1s both;
  -o-animation: fadeInRight 0.3s ease-in-out 1s both;
  animation: fadeInRight 0.3s ease-in-out 1s both; }

.banner-home1 .supermartket-owl-carousel .active p {
  -webkit-animation: fadeInRight 0.5s ease-in-out 0.9s both;
  -o-animation: fadeInRight 0.5s ease-in-out 0.9s both;
  animation: fadeInRight 0.5s ease-in-out 0.9s both; }

.banner-home1 .supermartket-owl-carousel .active a {
  -webkit-animation: fadeInRight 0.3s ease-in-out 1.2s both;
  -o-animation: fadeInRight 0.3s ease-in-out 1.2s both;
  animation: fadeInRight 0.3s ease-in-out 1.2s both; }

.banner-home1-content {
  height: 460px; }

.banner-home1-content .sile-desc {
  font-size: 16px;
  line-height: 20px;
  margin: 8px 0 15px; }

.banner-home1-content h3 {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 25px;
  line-height: 30px; }

.banner-home1-content.slide1 .container {
  padding: 65px 0 0 235px; }

.banner-home1-content.slide2 .container {
  padding: 165px 0 0 250px; }

.banner-home1-content.slide2 .button {
  background-color: #d41c4f;
  color: white;
  line-height: 41px;
  margin-top: 20px; }

.banner-home1-content.slide3 .container {
  padding: 60px 220px 0 420px; }

.banner-home1-content.slide3 .button {
  background-color: #d41c4f;
  color: white;
  line-height: 42px; }

.banner-home .owl-dots {
  bottom: 35px;
  height: 2px;
  left: 50%;
  position: absolute;
  text-align: center;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%); }

.banner-home .owl-dots .owl-dot {
  background-color: white;
  display: inline-block;
  float: left;
  height: 2px;
  left: 0;
  margin: 0 3px;
  width: 20px; }

.banner-home .owl-dots .owl-dot:hover, .banner-home .owl-dots .owl-dot.active {
  background-color: #ff8400; }

.owl-nav > div {
  background: #f5f5f5 none repeat scroll 0 0;
  border: 1px solid #dddddd;
  border-radius: 3px;
  color: #333333;
  display: inline-block;
  font-size: 24px;
  height: 50px;
  line-height: 44px;
  text-align: center;
  width: 32px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%); }

.owl-nav > div.owl-prev {
  left: 5px; }

.owl-nav > div.owl-next {
  right: 5px; }

.owl-nav > div:hover {
  background-color: #666666;
  color: white;
  border-color: #666666; }

.banner-absolute {
  display: inline-block;
  position: absolute;
  top: 12px;
  width: 220px;
  z-index: 4; }

.banner-absolute li {
  margin-bottom: 12px;
  padding: 19px 10px 27px;
  width: 100%; }

.banner-absolute .banner-item1 {
  background: transparent repeat scroll 0 0; }

.banner-absolute .banner-item2 {
  background: transparent repeat scroll 0 0; }

.banner-absolute .banner-item3 {
  background: transparent repeat scroll 0 0; }

.banner-absolute .banner-item-desc {
  color: #888888;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 5px;
  margin-top: 1px; }

.banner-absolute .banner-item-title {
  color: #333333;
  font-size: 15px;
  font-weight: 600;
  margin: 0;
  text-transform: uppercase; }

.banner-absolute .banner-item-link {
  position: relative;
  display: inline-block; }

.banner-absolute .banner-item-link::before {
  border-bottom: 1px solid #ff8400;
  bottom: 3px;
  content: "";
  left: 0;
  position: absolute;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  width: 0; }

.banner-absolute .banner-item-link:hover::before {
  width: 100%; }

/*  Slide 2  */
.item-slide {
  position: relative; }

.item-slide-content {
  position: absolute;
  top: 105px;
  left: 130px; }

.item-slide-content.slide2 {
  left: 222px;
  top: 50px; }

.slide-title {
  font-size: 25px;
  margin-bottom: 5px;
  text-transform: uppercase; }

.sile-desc {
  font-size: 16px;
  color: #888;
  line-height: 20px; }

.button1 {
  background: #d5625c none repeat scroll 0 0;
  border-radius: 5px;
  color: white;
  display: inline-block;
  line-height: 43px;
  padding: 0 12px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600; }

.button1:hover {
  background-color: #3e3e3e;
  color: white; }

.button2 {
  background: #a1252d none repeat scroll 0 0;
  border-radius: 5px;
  color: white;
  display: inline-block;
  line-height: 43px;
  padding: 0 12px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600; }

.button2:hover {
  background-color: #3e3e3e;
  color: white; }

.button3 {
  background: #d5625c none repeat scroll 0 0;
  border-radius: 5px;
  color: white;
  display: inline-block;
  line-height: 43px;
  padding: 0 12px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600; }

.button3:hover {
  background-color: #3e3e3e;
  color: white; }

.item-slide-content1 .button1, .item-slide-content1 .button2, .item-slide-content1 .button3 {
  font-size: 16px; }

.active .item-slide-content1 .slide-title {
  animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-name: fadeInLeft;
  -webkit-animation-name: fadeInLeft; }

.active .item-slide-content1 .sile-desc {
  animation-duration: 1.7s;
  -webkit-animation-duration: 1.7s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-name: fadeInLeft;
  -webkit-animation-name: fadeInLeft; }

.active .item-slide-content1 .button2 {
  animation-duration: 2s;
  -webkit-animation-duration: 2s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-name: fadeInLeft;
  -webkit-animation-name: fadeInLeft; }

/*Slide 3*/
.item-slide-content3.item-slide-content {
  left: 85px;
  top: 125px; }

.item-slide-content3.item-slide-content.slide2 {
  left: auto;
  top: 90px;
  right: 90px; }

.item-slide-content3.item-slide-content.slide2 .slide-title {
  font-size: 24px;
  margin-bottom: 5px;
  text-transform: uppercase;
  line-height: 23px; }

.item-slide-content3.item-slide-content.slide2 .sile-subtitle {
  color: #666666;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 27px; }

.item-slide-content3.item-slide-content.slide3 {
  left: auto;
  top: 105px;
  right: 10px; }

.item-slide-content3 .sile-subtitle {
  font-size: 16px;
  color: #666666;
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 5px; }

.item-slide-content3 .slide-title {
  font-size: 24px;
  margin-bottom: 25px;
  text-transform: uppercase; }

.item-slide-content3 .button1 {
  line-height: 39px;
  padding: 0 25px;
  border-radius: 2px;
  font-size: 16px;
  font-weight: 600;
  background-color: #da3838; }

.item-slide-content3 .button1:hover {
  background-color: #f8b91d;
  color: white; }

.item-slide-content3 .button2 {
  background: #730603;
  line-height: 39px;
  padding: 0 25px;
  border-radius: 2px; }

.item-slide-content3 .button2:hover {
  background-color: #f8b91d;
  color: white; }

.item-slide-content3 .button3 {
  background: #f8b91d;
  font-size: 16px;
  padding: 0 25px;
  line-height: 39px;
  border-radius: 3px;
  font-weight: 600; }

.item-slide-content3 .button3:hover {
  background-color: #da3838;
  color: white; }

.item-slide-content3.slide3 .slide-title {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 24px;
  text-transform: uppercase;
  color: #333333; }

.item-slide-content3.slide3 .slide-title span {
  color: #666666;
  font-size: 20px; }

.active .item-slide-content1 .slide-title {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-name: fadeInRight;
  -webkit-animation-name: fadeInRight; }

.active .item-slide-content1 .sile-subtitle {
  animation-duration: 1.7s;
  -webkit-animation-duration: 1.7s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-name: fadeInRight;
  -webkit-animation-name: fadeInRight; }

.active .item-slide-content1 .button1 {
  animation-duration: 2s;
  -webkit-animation-duration: 2s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp; }

.active .item-slide-content1 .button2 {
  animation-duration: 2s;
  -webkit-animation-duration: 2s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp; }

.active .item-slide-content1 .button3 {
  animation-duration: 2s;
  -webkit-animation-duration: 2s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp; }

.active .item-slide-content3 .slide-title {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-name: fadeInRight;
  -webkit-animation-name: fadeInRight; }

.active .item-slide-content3 .sile-subtitle {
  animation-duration: 1.7s;
  -webkit-animation-duration: 1.7s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-name: fadeInRight;
  -webkit-animation-name: fadeInRight; }

.active .item-slide-content3 .button1 {
  animation-duration: 2s;
  -webkit-animation-duration: 2s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp; }

.active .item-slide-content3 .button2 {
  animation-duration: 2s;
  -webkit-animation-duration: 2s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp; }

.active .item-slide-content3 .button3 {
  animation-duration: 2s;
  -webkit-animation-duration: 2s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp; }

.banner-slide .owl-controls {
  display: none; }

.banner-slide:hover .owl-controls {
  display: block; }

.banner-slide .owl-dots {
  bottom: 20px;
  height: 2px;
  left: 50%;
  position: absolute;
  text-align: center;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%); }

.banner-slide .owl-dots .owl-dot {
  background-color: white;
  display: inline-block;
  float: left;
  height: 2px;
  left: 0;
  margin: 0 3px;
  width: 20px; }

.banner-slide .owl-dots .owl-dot:hover, .banner-slide .owl-dots .owl-dot.active {
  background-color: #d90735; }

.nav-style2 .owl-nav > div {
  background: #443266 none repeat scroll 0 0;
  border: medium none;
  border-radius: 3px;
  font-size: 27px;
  height: 72px;
  left: 8px;
  color: white;
  line-height: 65px;
  width: 45px; }

.nav-style2 .owl-nav > div.owl-next {
  right: 8px;
  left: auto; }

.nav-style2 .owl-nav > div:hover {
  background-color: #666666;
  color: #333333; }

.nav-style3 .owl-nav > div {
  background: white none repeat scroll 0 0;
  border: medium none;
  border-radius: 3px;
  color: #333333;
  font-size: 27px;
  height: 72px;
  left: 8px;
  line-height: 65px;
  width: 45px; }

.nav-style3 .owl-nav > div:hover {
  background-color: #0b2076;
  color: white; }

.nav-style3 .owl-nav > div.owl-next {
  right: 8px;
  left: auto; }

.item-slide-content4.item-slide-content {
  left: 210px;
  position: absolute;
  top: auto;
  bottom: 120px;
  text-align: center; }

.item-slide-content4.item-slide-content.slide2 {
  left: 50px;
  top: auto;
  bottom: 135px; }

.item-slide-content4.item-slide-content.slide3 {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 40px;
  bottom: auto; }

.item-slide-content4.item-slide-content .sile-subtitle {
  color: white;
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 7px; }

.item-slide-content4.item-slide-content .slide-title {
  color: white;
  font-size: 20px;
  margin-bottom: 14px;
  text-transform: uppercase; }

.item-slide-content4 .button1 {
  background: #1abd48 none repeat scroll 0 0;
  border-radius: 3px;
  color: white;
  display: inline-block;
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  line-height: 39px;
  padding: 0 25px;
  text-transform: uppercase; }

.item-slide-content4 .button2 {
  background: #1abd48 none repeat scroll 0 0;
  border-radius: 3px;
  color: white;
  display: inline-block;
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  line-height: 39px;
  padding: 0 25px;
  text-transform: uppercase; }

.item-slide-content4 .button3 {
  background: #da3838 none repeat scroll 0 0;
  border-radius: 3px;
  color: white;
  display: inline-block;
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  line-height: 39px;
  padding: 0 25px;
  text-transform: uppercase; }

.active .item-slide-content4 .slide-title {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-name: bounceIn;
  -webkit-animation-name: bounceIn; }

.active .item-slide-content4 .sile-subtitle {
  animation-duration: 1.7s;
  -webkit-animation-duration: 1.7s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-name: bounceInDown;
  -webkit-animation-name: bounceInDown; }

.active .item-slide-content4 .button1 {
  animation-duration: 2s;
  -webkit-animation-duration: 2s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp; }

.active .item-slide-content4 .button2 {
  animation-duration: 2s;
  -webkit-animation-duration: 2s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp; }

.active .item-slide-content4 .button3 {
  animation-duration: 2s;
  -webkit-animation-duration: 2s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp; }

div.owl-carousel .owl-item img {
  transform-style: inherit; }

.item-slide-content-wrap {
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%; }

.item-slide-content-wrap .container {
  position: relative; }

.item-slide-content6.item-slide-content {
  left: auto;
  padding-left: 310px;
  text-align: center;
  position: absolute;
  top: 165px; }

.item-slide-content6 .sile-subtitle {
  color: #666666;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 3px; }

.item-slide-content6 .slide-title {
  font-size: 26px;
  line-height: 34px;
  margin-bottom: 25px; }

.item-slide-content6 .button-red {
  background-color: #da3838;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 600;
  line-height: 39px;
  padding: 0 25px;
  display: inline-block;
  color: white;
  text-transform: uppercase; }

.item-slide-content6 .button-red:hover {
  background-color: #006393;
  color: white; }

.item-slide-content6.slide2 {
  padding-left: 0;
  padding-right: 50px;
  text-align: left;
  right: 0; }

.item-slide-content6.slide2 .sile-subtitle {
  font-style: italic;
  margin-bottom: 10px; }

.item-slide-content6.slide2 .slide-title {
  color: white;
  font-size: 36px;
  text-align: center;
  padding-left: 70px; }

.item-slide-content6.slide3.item-slide-content {
  padding-left: 365px;
  text-align: left; }

.item-slide-content6.slide3.item-slide-content .sile-subtitle {
  font-style: italic;
  margin-bottom: 10px; }

.item-slide-content6.slide3.item-slide-content .slide-title {
  color: #333333;
  font-size: 36px;
  text-align: center;
  padding-left: 70px; }

.nav-style4 .owl-nav > div {
  background: #006393 none repeat scroll 0 0;
  border: medium none;
  border-radius: 3px;
  color: white;
  font-size: 27px;
  height: 72px;
  left: 8px;
  line-height: 65px;
  width: 45px;
  display: none; }

.nav-style4 .owl-nav > div:hover {
  background-color: #666666;
  color: #333333; }

.nav-style4 .owl-nav > div.owl-next {
  right: 8px;
  left: auto; }

.slide-item1 {
  /*background-image: url("slide16.jpg");*/
  height: 485px; }

.slide-item2 {
  /*background-image: url("slide17.jpg");*/
  height: 485px; }

.slide-item3 {
  /*background-image: url("slide18.jpg");*/
  height: 485px; }

.active .item-slide-content6 .slide-title {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-name: bounceInRight;
  -webkit-animation-name: bounceInRight; }

.active .item-slide-content6 .sile-subtitle {
  animation-duration: 1.7s;
  -webkit-animation-duration: 1.7s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-name: slideInDown;
  -webkit-animation-name: slideInDown; }

.active .item-slide-content6 .button-red {
  animation-duration: 2s;
  -webkit-animation-duration: 2s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp; }

/* -------------------- */
/*   end banner-slide   */
/* -------------------- */
/* -------------------- */
/*      main            */
/* -------------------- */
/*Creat by Padan*/
html {
  overflow-x: hidden; }

/*Banner text*/
.banner-text {
  display: block;
  overflow: hidden;
  position: relative; }

.banner-text figure:before, .banner-text a:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(255, 132, 0, 0.1);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.6s ease-in-out 0s;
  -moz-transition: all 0.6s ease-in-out 0s;
  -ms-transition: all 0.6s ease-in-out 0s;
  -o-transition: all 0.6s ease-in-out 0s;
  transition: all 0.6s ease-in-out 0s; }

.banner-text:hover figure:before, .banner-text:hover a:before {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1); }

.banner-text img {
  width: 100%; }

.product-list-content figure, .item-product-deal figure, .better-sale-item figure, .catergory-item figure, .left-tab figure {
  overflow: hidden; }

.product-list-content figure img, .item-product-deal figure img, .better-sale-item figure img, .catergory-item figure img, .left-tab figure img {
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s; }

.product-list-content:hover figure img, .item-product-deal:hover figure img, .better-sale-item:hover figure img, .catergory-item:hover figure img, .left-tab:hover figure img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
  transform: scale(1.05);
  opacity: 0.9;
  opacity: 0.9; }

/*Product Slide*/
.products-slide-wrap {
  border: 1px solid #dddddd;
  background: white; }

.products-slide-wrap .product-slide-title {
  display: inline-block;
  padding: 18px 100px 20px 20px;
  width: 100%; }

.products-slide-wrap .product-slide-title .title-left {
  float: left;
  margin: 0;
  font-size: 16px; }

.products-slide-wrap .product-slide-title .button-link {
  float: right;
  line-height: 14px;
  position: relative; }

.products-slide-wrap .product-slide-title .button-link::before {
  border-bottom: 1px solid #ff8400;
  bottom: -1px;
  content: "";
  left: 0;
  position: absolute;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  width: 0; }

.products-slide-wrap .product-slide-title .button-link:hover::before {
  width: 100%; }

div.products-slide .owl-nav > div {
  height: 44px;
  left: auto;
  line-height: 40px;
  right: 45px;
  top: -34px; }

div.products-slide .owl-nav > div.owl-next {
  left: auto;
  right: 5px; }

.products-slide {
  padding: 0 10px 10px; }

.product-media a {
  display: inline-block;
  overflow: hidden; }

.products-slide .product-list-content {
  border: 1px solid transparent;
  padding-bottom: 10px;
  margin: 0px -1px 1px 0; }

.products-slide .product-list-content .feature-slide-cost {
  margin: 0 0 0 10px; }

.products-slide .product-list-content .feature-slide-name {
  margin-left: 10px;
  color: #000000; }

.products-slide .product-list-content:hover {
  border-color: #dfdfdf; }

/*Icon Box Style 2*/
.style2.icon-box {
  background-color: transparent;
  border: medium none;
  margin-top: 0;
  padding: 0; }

.style2.icon-box .icon-box-left {
  color: #000;
  float: left;
  font-size: 48px;
  line-height: 48px; }

.style2.icon-box:hover .icon-box-left {
  animation-duration: 0.8s;
  -webkit-animation-duration: 0.8s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-name: bounceIn;
  -webkit-animation-name: bounceIn;
  color: #ff8400; }

.style2.icon-box .icon-box-right {
  padding-left: 58px; }

.style2.icon-box p {
  font-size: 13px;
  line-height: 16px;
  margin: 5px 0 0; }

.style2.icon-box a {
  color: #2c4284;
  font-size: 16px; }

.style2.icon-box:hover a {
  color: #ff8400; }

.style2.icon-box h4 {
  font-weight: 600;
  text-transform: none; }

.style2.icon-box h4 span {
  text-transform: uppercase; }

/*Icon Box Style 3*/
.style3.icon-box {
  background-color: transparent;
  border: medium none;
  margin-top: 0;
  padding: 0; }

.style3.icon-box .icon-box-left {
  color: #333333;
  float: left;
  font-size: 48px;
  line-height: 48px; }

.style3.icon-box:hover .icon-box-left {
  animation-duration: 0.8s;
  -webkit-animation-duration: 0.8s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-name: bounceIn;
  -webkit-animation-name: bounceIn;
  color: #f8b91d; }

.style3.icon-box .icon-box-right {
  padding-left: 58px; }

.style3.icon-box p {
  font-size: 13px;
  line-height: 16px;
  margin: 5px 0 0; }

.style3.icon-box a {
  color: #443266;
  font-size: 16px; }

.style3.icon-box a:hover {
  color: #f8b91d; }

.style3.icon-box:hover a {
  color: #f8b91d; }

.style3.icon-box h4 {
  font-weight: 600;
  text-transform: none; }

.style3.icon-box h4 span {
  text-transform: uppercase; }

/*Icon Box Style 4*/
.style4.icon-box {
  background-color: transparent;
  border: medium none;
  margin-top: 0;
  padding: 0; }

.style4.icon-box .icon-box-left {
  color: #333333;
  float: left;
  font-size: 48px;
  line-height: 48px; }

.style4.icon-box:hover .icon-box-left {
  animation-duration: 0.8s;
  -webkit-animation-duration: 0.8s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-name: bounceIn;
  -webkit-animation-name: bounceIn;
  color: #ff8400; }

.style4.icon-box .icon-box-right {
  padding-left: 58px; }

.style4.icon-box p {
  font-size: 13px;
  line-height: 16px;
  margin: 5px 0 0; }

.style4.icon-box a {
  color: #0b2076; }

.style4.icon-box:hover a {
  color: #ff8400; }

.style4.icon-box h4 {
  font-weight: 600;
  text-transform: none; }

.style4.icon-box h4 span {
  text-transform: uppercase; }

/*Icon Box Style 5*/
.no-padding:last-child .style5.icon-box {
  border-width: 0; }

.style5.icon-box {
  background-color: transparent;
  border-width: 0 1px 0 0;
  margin-top: 0;
  padding: 10px 0 10px 25px; }

.style5.icon-box .icon-box-left {
  color: #333333;
  float: left;
  font-size: 48px;
  line-height: 48px; }

.style5.icon-box:hover .icon-box-left {
  animation-duration: 0.8s;
  -webkit-animation-duration: 0.8s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-name: bounceIn;
  -webkit-animation-name: bounceIn;
  color: #006393; }

.style5.icon-box .icon-box-right {
  padding-left: 58px; }

.style5.icon-box p {
  font-size: 13px;
  line-height: 16px;
  margin: 5px 0 0; }

.style5.icon-box a {
  color: #006393; }

.style5.icon-box:hover a {
  color: #006393; }

.style5.icon-box h4 {
  font-weight: 600;
  text-transform: none; }

.style5.icon-box h4 span {
  text-transform: uppercase; }

/*Tab Logo Brand*/
.tab-logo-brand .nav.nav-pills li {
  text-align: center;
  margin: 0;
  padding: 0;
  background: white; }

.tab-logo-brand .nav.nav-pills li a {
  z-index: 2;
  border-radius: 0; }

.tab-logo-brand .nav.nav-pills li a img {
  height: auto;
  max-width: none;
  width: 100%; }

.tab-logo-brand.has-2tabs li {
  width: 50%; }

.tab-logo-brand.has-3tabs li {
  width: 33.33%; }

.tab-logo-brand.has-4tabs li {
  width: 25%; }

.tab-logo-brand.has-5tabs li {
  width: 20%; }

.tab-logo-brand.has-6tabs li {
  width: 1.666667%; }

.tab-logo-brand .nav.nav-pills li.active, .tab-logo-brand .nav.nav-pills li:hover {
  background-color: #d3d3d3;
  position: relative; }

.tab-logo-brand .nav.nav-pills li::before {
  border-top: 10px solid #d3d3d3;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  content: "";
  display: inline-block;
  height: 10px;
  left: 50%;
  position: absolute;
  bottom: -10px;
  -webkit-transform: translateX(-50%) translateY(-20px);
  -ms-transform: translateX(-50%) translateY(-20px);
  -o-transform: translateX(-50%) translateY(-20px);
  transform: translateX(-50%) translateY(-20px);
  transition: all 0.3s ease 0s;
  width: 10px;
  z-index: -1; }

.tab-logo-brand .nav.nav-pills li.active::before, .tab-logo-brand .nav.nav-pills li:hover::before {
  -webkit-transform: translateX(-50%) translateY(0px);
  -ms-transform: translateX(-50%) translateY(0px);
  -o-transform: translateX(-50%) translateY(0px);
  transform: translateX(-50%) translateY(0px);
  z-index: 3; }

.tab-logo-brand .product-tabs-content {
  border-width: 1px 0 0; }

.tab-logo-brand .active-last .product-content:last-child .tab-h3-right {
  left: -100%;
  padding-left: 10px; }

.tab-logo-brand .product-content {
  display: inline-block;
  float: left;
  position: relative;
  width: 100%;
  border-right: 1px solid #dddddd; }

.tab-logo-brand .product-content:hover .tab-h3-right {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: all 0.6s ease-in-out 0s;
  -moz-transition: all 0.6s ease-in-out 0s;
  -ms-transition: all 0.6s ease-in-out 0s;
  -o-transition: all 0.6s ease-in-out 0s;
  transition: all 0.6s ease-in-out 0s;
  z-index: 5; }

.tab-logo-brand .product-content img {
  width: auto; }

.tab-logo-brand .product-content .product-title-content {
  color: #2c4285;
  font-weight: 600;
  text-transform: uppercase; }

.tab-logo-brand .product-content .tab-h3-right {
  background-color: white;
  left: 100%;
  top: 0;
  height: 100%;
  width: 100%;
  padding: 15px 0 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(50px);
  -ms-transform: translateY(50px);
  -o-transform: translateY(50px);
  transform: translateY(50px);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  position: absolute; }

.tab-logo-brand .product-content .tab-h3-right .additional_infor {
  margin-bottom: 10px; }

.tab-logo-brand .product-content .tab-h3-right a {
  margin-top: 10px;
  display: inline-block; }

.tab-logo-brand .product-content .tab-h3-right .tab-h3-cost {
  color: #da3838; }

.tab-logo-brand .product-content:hover .product-info {
  opacity: 0;
  visibility: visible; }

.tab-logo-brand .product-content .product-info {
  padding: 0 15px; }

.tab-logo-brand .product-content .product-info .product-content-desc {
  color: #333333;
  line-height: 22px;
  margin-bottom: 10px; }

.product-content > figure {
  text-align: center; }

.product-content > figure a {
  display: inline-block; }

/*Lasest News*/
.lastes-news-slide {
  overflow: hidden; }

.lastes-news-slide .title-left {
  margin: 10px 0 15px; }

.lastes-news-slide figure {
  margin-bottom: 5px; }

.lastes-news-slide figure img {
  height: auto; }

.lastes-news-slide .owl-nav > div.owl-prev {
  -webkit-transform: translateX(-100px) translateY(-200%);
  -ms-transform: translateX(-100px) translateY(-200%);
  -o-transform: translateX(-100px) translateY(-200%);
  transform: translateX(-100px) translateY(-200%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  opacity: 0; }

.lastes-news-slide .owl-nav > div.owl-next {
  -webkit-transform: translateX(100px) translateY(-200%);
  -ms-transform: translateX(100px) translateY(-200%);
  -o-transform: translateX(100px) translateY(-200%);
  transform: translateX(100px) translateY(-200%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  opacity: 0; }

.lastes-news-slide:hover .owl-nav > div.owl-prev, .lastes-news-slide:hover .owl-nav > div.owl-next {
  -webkit-transform: translateX(0) translateY(-200%);
  -ms-transform: translateX(0) translateY(-200%);
  -o-transform: translateX(0) translateY(-200%);
  transform: translateX(0) translateY(-200%);
  opacity: 1;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s; }

.lastes-news-slide.lastes-news-style2 {
  overflow: visible; }

.lastes-news-slide.lastes-news-style2 .owl-nav > div {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  border-radius: 2px !important;
  font-size: 20px;
  height: 34px;
  line-height: 32px;
  top: -45px;
  transform: none;
  -o-transform: none;
  -webkit-transform: none;
  visibility: visible;
  opacity: 1;
  width: 25px; }

.lastes-news-slide.lastes-news-style2 .owl-nav > div.owl-prev {
  left: auto;
  right: 36px; }

.lastes-news-slide.lastes-news-style2 .owl-nav > div.owl-next {
  right: 0; }

.lastes-news-slide.lastes-news-style2 .owl-nav > div:hover {
  background: #0b2076;
  color: white; }

.lastes-news-slide.lastes-news-style2:hover .owl-nav > div.owl-prev, .lastes-news-slide.lastes-news-style2:hover .owl-nav > div.owl-next {
  -webkit-transform: translateX(0) translateY(0);
  -ms-transform: translateX(0) translateY(0);
  -o-transform: translateX(0) translateY(0);
  transform: translateX(0) translateY(0);
  opacity: 1;
  visibility: visible; }

.lastes-news-slide.lastes-news-style2 .owl-nav::before {
  background: white none repeat scroll 0 0;
  content: "";
  height: 40px;
  position: absolute;
  right: 0;
  width: 85px;
  z-index: 0;
  top: -48px; }

.lastes-news-slide.lastes-news-style2 .latest-text {
  margin-bottom: 15px;
  position: relative; }

.latest-content figure a::after {
  background-color: rgba(0, 0, 0, 0.3);
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s; }

.latest-content figure a::before {
  color: white;
  content: "\f112";
  font-family: "Flaticon";
  font-size: 30px;
  font-weight: 100;
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%) scale(0);
  -ms-transform: translateX(-50%) translateY(-50%) scale(0);
  -o-transform: translateX(-50%) translateY(-50%) scale(0);
  transform: translateX(-50%) translateY(-50%) scale(0);
  -webkit-transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.5s ease-in-out 0s;
  -ms-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
  vertical-align: middle;
  z-index: 1; }

.latest-content:hover figure a::after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1); }

.latest-content:hover figure a::before {
  -webkit-transform: translateX(-50%) translateY(-50%) scale(1);
  -ms-transform: translateX(-50%) translateY(-50%) scale(1);
  -o-transform: translateX(-50%) translateY(-50%) scale(1);
  transform: translateX(-50%) translateY(-50%) scale(1);
  opacity: 1; }

.latest-content figure a {
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: 100%; }

.latest-text {
  position: relative;
  margin-bottom: 40px; }

.latest-text .post-date-item {
  background-color: #3374a6;
  border-radius: 3px;
  color: white;
  font-size: 18px;
  font-weight: 600;
  left: 0;
  padding: 12px 0 11px;
  position: absolute;
  text-align: center;
  text-transform: capitalize;
  top: 0;
  width: 48px; }

.latest-text .post-date-item .post-date {
  line-height: 14px;
  margin: 0; }

.latest-text .lastet-content {
  line-height: 20px; }

.latest-text .lastet-content .title-post {
  color: #333333;
  margin: 0 0;
  position: relative;
  top: -3px;
  text-transform: uppercase; }

.latest-text .lastet-content .title-post a {
  color: #333333; }

.latest-text .lastet-content .title-post a:hover {
  color: #3374a6; }

.latest-text .lastet-content .desc-post {
  color: #888;
  display: inline-block;
  line-height: 20px;
  margin: 0;
  width: 100%; }

.latest-text .lastet-content a {
  color: #666666; }

.latest-text .lastet-content a i {
  margin-left: 10px; }

.latest-text .lastet-content a:hover {
  color: #3374a6; }

.lastes-news-style1 .latest-text {
  padding-left: 65px; }

.lastes-news-style2 .post-date-item {
  background-color: #0b2076;
  border-radius: 2px;
  color: white;
  font-size: 18px;
  font-weight: 600;
  height: 50px;
  left: 5px;
  position: absolute;
  text-align: center;
  top: 5px;
  width: 34px; }

.lastes-news-style2 .post-date-item .post-date {
  line-height: 18px;
  margin: 0;
  padding-top: 5px; }

.lastes-news-style2 .post-date-item .post-month {
  line-height: 16px;
  font-size: 11px;
  background: white;
  width: 100%;
  color: #333333;
  display: inline-block;
  text-transform: capitalize; }

.lastes-news-style2 .lastet-content .title-post {
  margin: 0 0 13px;
  font-weight: 400; }

.lastes-news-style2 .post-meta {
  display: inline-block;
  margin-bottom: 7px; }

.lastes-news-style2 .post-meta .meta-item {
  float: left;
  border-left: 1px solid #dddddd;
  padding-left: 7px;
  margin-left: 7px;
  line-height: 10px;
  color: #aaaaaa; }

.lastes-news-style2 .post-meta .meta-item:first-child {
  margin: 0;
  padding: 0;
  border: none; }

.lastes-news-style2 .post-meta .meta-item a {
  color: #aaaaaa; }

.lastes-news-style2 .post-meta .meta-item:hover a {
  color: #0b2076; }

.lastes-news-style2 .lastet-content .desc-post {
  color: #666666;
  display: inline-block;
  line-height: 20px;
  margin: 0 0 10px;
  width: 100%; }

.lastes-news-style2 .lastest-media {
  position: relative; }

/*Title Block*/
.title-block {
  text-transform: uppercase;
  margin-bottom: 15px;
  position: relative;
  overflow: hidden; }

.title-block .title-left {
  margin-bottom: 0px;
  display: inline-block; }

.title-block.title-block-style2 .title-left {
  font-size: 16px;
  padding-right: 15px;
  position: relative; }

.title-block.title-block-style2 .title-left::before {
  border-bottom: 1px solid #dddddd;
  content: "";
  left: 100%;
  position: absolute;
  top: 50%;
  width: 9999px; }

.tabs-slide .nav.nav-pills {
  padding-right: 80px; }

.nav.nav-pills {
  border-color: #dddddd;
  background-color: white;
  border-radius: 0;
  border-style: solid;
  border-width: 1px 1px 0;
  display: inline-block;
  float: right; }

.nav.nav-pills li {
  display: inline-block;
  line-height: 38px;
  padding: 0 20px;
  margin: -1px -1px 0 -1px;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s; }

.nav.nav-pills li > a {
  background: transparent none repeat scroll 0 0;
  border-radius: 4px;
  padding: 0; }

.nav.nav-pills li > a:focus {
  background: transparent none repeat scroll 0 0; }

.nav.nav-pills li:hover a {
  background: transparent none repeat scroll 0 0; }

.nav.nav-pills li:focus a {
  background: transparent none repeat scroll 0 0; }

.nav.nav-pills li.active {
  background: #ff8400; }

.nav.nav-pills li.active a {
  color: white; }

.nav.nav-pills li:hover {
  background: #ff8400; }

.nav.nav-pills li:hover a {
  color: white; }

.product-tabs {
  display: inline-block;
  margin-top: 25px;
  position: relative;
  float: left;
  width: 100%; }

.product-tabs .tab-title {
  font-weight: 600;
  line-height: 21px;
  position: absolute;
  font-size: 16px;
  text-transform: uppercase;
  color: #333333;
  top: 0;
  left: 0;
  z-index: 1; }

.product-tabs .owl-nav > div {
  top: 45%; }

.tabs-slide.product-tabs .owl-nav > div {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  height: 34px;
  line-height: 33px;
  right: 0;
  top: -22px; }

.tabs-slide.product-tabs .owl-nav > div.owl-prev {
  left: auto;
  right: 35px; }

.tabs-slide.product-tabs .owl-nav > div:hover {
  color: #ff8400; }

.tab-content .product-media a {
  margin: 0 auto;
  display: table; }

.product-list-content .feature-slide-name {
  margin-left: 17px;
  color: #111; }

.product-list-content .feature-slide-cost {
  color: #bbb;
  font-size: 13px;
  margin: 5px 0 0 17px;
  line-height: 14px; }

.product-list-content .feature-slide-cost .price {
  font-size: 14px;
  color: #da3838; }

.product-tabs-content {
  display: inline-block;
  width: 100%;
  float: left;
  border: 1px solid #dddddd; }

.tab-content {
  background: white; }

.tab-content .owl-carousel .owl-item img, div.products-slide .owl-item img {
  display: inline-block;
  width: auto; }

.tab-content figure, div.products-slide figure {
  margin: 0;
  text-align: center; }

.left-tab {
  margin-top: 38px; }

.left-tab figure {
  position: relative;
  border: 1px solid #dddddd; }

.left-tab:hover p, .left-tab:hover h3 {
  animation-duration: 0.8s;
  -webkit-animation-duration: 0.8s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-name: fadeInRight;
  -webkit-animation-name: fadeInRight; }

.left-tab:hover h2, .left-tab:hover span {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-name: fadeInLeft;
  -webkit-animation-name: fadeInLeft; }

.left-tab:hover a {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp; }

.left-tab .left-tab-asblute {
  left: 20px;
  position: absolute;
  top: 80px; }

.left-tab .left-tab-asblute p {
  color: #555;
  font-size: 18px;
  margin: 0; }

.left-tab .left-tab-asblute h2 {
  color: #264a9b;
  margin: 5px 0 20px; }

.left-tab .left-tab-asblute h2 span {
  color: #333333; }

.left-tab .left-tab-asblute > h3 {
  margin-bottom: 3px;
  text-transform: uppercase; }

.left-tab .left-tab-asblute > span {
  color: #666666;
  display: inline-block;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 30px;
  width: 100%; }

.television .left-tab-asblute h5 {
  font-weight: 600;
  text-transform: uppercase; }

.television .left-tab .left-tab-asblute p {
  color: #666666;
  font-size: 16px;
  margin: 0 0 25px; }

.bx-wrapper {
  width: 79%;
  float: left; }

.slide-thumbs {
  overflow: hidden; }

.slide-thumbs .bx-viewport {
  height: auto !important; }

.slide-product-with-thumb .bx-wrapper {
  float: left;
  width: 100%; }

.bx-wrapper .bx-wrapper {
  width: 100%; }

.thumbs {
  float: right;
  width: 21%;
  background: white; }

.thumbs > a {
  border-bottom: 1px solid #dddddd !important;
  border-left: 1px solid #dddddd !important;
  display: inline-block;
  padding: 7px 0;
  text-align: center;
  width: 100%;
  position: relative; }

.slide-thumbs .thumbs > a:hover img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
  transform: scale(1.05); }

.slide-thumbs .thumbs > a img {
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s; }

.thumbs > a:last-child {
  border: medium none;
  border-bottom: none !important; }

.thumbs > a.active::before {
  background-color: #ff8400;
  content: "";
  height: 15px;
  position: absolute;
  right: 100%;
  top: 41%;
  transform: rotate(45deg) translateX(11px);
  -o-transform: rotate(45deg) translateX(11px);
  -ms-transform: rotate(45deg) translateX(11px);
  -moz-transform: rotate(45deg) translateX(11px);
  -webkit-transform: rotate(45deg) translateX(11px);
  width: 15px;
  z-index: 3; }

.slide-container div {
  text-align: center; }

/*slide-product-with-thumb*/
.slide-product-with-thumb {
  background: white none repeat scroll 0 0;
  border: 1px solid #dddddd;
  display: inline-block;
  width: 100%; }

.slide-product-with-thumb .bx-viewport {
  margin-top: -1px; }

.slide-product-with-thumb > .row {
  padding: 1px 0; }

.slide-product-with-thumb .thumbs {
  width: 100%;
  position: relative;
  top: -1px; }

.slide-product-with-thumb .slide-container > img:hover {
  -webkit-transform: scale(1.04);
  -ms-transform: scale(1.04);
  -o-transform: scale(1.04);
  transform: scale(1.04); }

.slide-product-with-thumb .slide-container > img {
  overflow: hidden;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s; }

.slide-product-with-thumb .slide-product-with-thumb .bx-wrapper {
  width: 100%;
  display: inline-block;
  overflow: hidden; }

.slide-product-with-thumb .image-media {
  float: right;
  position: relative; }

.slide-product-with-thumb .full-desc-content {
  display: inline-block;
  float: none !important; }

.slide-product-with-thumb .button-link-slide {
  font-size: 16px;
  font-weight: 600;
  line-height: 42px;
  text-transform: uppercase;
  clear: both;
  display: inline-block;
  padding: 0 25px;
  margin-top: 40px;
  border-radius: 3px;
  background: #f8b91d;
  color: white; }

.slide-product-with-thumb .button-link-slide:hover {
  background: #333333;
  color: white; }

.slide-product-with-thumb .ful-content > p {
  font-size: 14px;
  line-height: 30px;
  color: #333333;
  margin: 0; }

.slide-product-with-thumb .ful-content > p span {
  min-width: 85px;
  display: inline-block; }

.slide-product-with-thumb .thumbs > a {
  border: 1px solid #dddddd;
  display: inline-block;
  margin: -1px 0 0 -1px;
  padding: 0;
  position: relative;
  text-align: center;
  width: 100%; }

.slide-product-with-thumb .thumbs > a.active {
  border: 1px solid #f8b91d !important;
  z-index: 2; }

.slide-product-with-thumb .thumbs > a:hover {
  border: 1px solid #f8b91d !important;
  z-index: 2;
  color: #666666; }

.slide-product-with-thumb .thumbs > a.active::before {
  background-color: white;
  content: "";
  display: inline-block;
  font-family: FontAwesome;
  font-size: 24px;
  height: 13px;
  line-height: 12px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: rotate(-45deg) translateY(-50%) translateX(16px);
  -o-transform: rotate(-45deg) translateY(-50%) translateX(16px);
  -webkit-transform: rotate(-45deg) translateY(-50%) translateX(16px);
  width: 13px;
  z-index: 3;
  border-bottom: 1px solid #f8b91d;
  border-right: 1px solid #f8b91d;
  margin-top: 10px; }

.slide-product-with-thumb .thumbs > a:hover::before {
  background-color: white;
  content: "";
  display: inline-block;
  font-family: FontAwesome;
  font-size: 24px;
  height: 13px;
  line-height: 12px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: rotate(-45deg) translateY(-50%) translateX(16px);
  -o-transform: rotate(-45deg) translateY(-50%) translateX(16px);
  -webkit-transform: rotate(-45deg) translateY(-50%) translateX(16px);
  width: 13px;
  z-index: 3;
  border-bottom: 1px solid #f8b91d;
  border-right: 1px solid #f8b91d;
  margin-top: 10px; }

.slide-product-with-thumb .slide-container {
  overflow: hidden; }

.slide-product-with-thumb .thumb-media {
  float: left;
  margin-right: 15px; }

.slide-product-with-thumb .thumb-desc-content {
  text-align: left;
  padding-left: 20px; }

.slide-product-with-thumb .thumb-desc-content .title-product {
  text-transform: uppercase;
  font-weight: 400;
  position: relative;
  top: 10px;
  margin-bottom: 12px; }

.slide-product-with-thumb .thumb-desc-content .desc-product {
  line-height: 20px;
  color: #666666;
  padding: 0 20px 0 0; }

.slide-product-with-thumb .thumb-desc-content .price {
  color: #da3838;
  line-height: 20px; }

.slide-product-with-thumb .ful-content {
  float: left;
  margin-top: 50px;
  margin-left: 10px; }

.slide-product-with-thumb .image-media {
  float: right; }

.supermartket-owl-carousel .product-list-content {
  width: 100%; }

.product-media figure {
  text-align: center; }

.product-list {
  border: 1px solid #dddddd;
  display: inline-block;
  margin-bottom: -1px;
  width: 100%;
  background: white;
  float: left; }

.product-list .product-list-content {
  width: 20%; }

.product-list-content {
  display: inline-block;
  float: left;
  position: relative;
  overflow: hidden;
  padding: 20px 0 20px; }

.product-list-content .product-item-actions {
  width: 32px;
  top: 50%;
  -webkit-transform: translateY(-65%);
  -ms-transform: translateY(-65%);
  -o-transform: translateY(-65%);
  transform: translateY(-65%);
  position: absolute;
  right: 5px; }

.product-list-content li {
  height: 32px;
  width: 32px;
  line-height: 30px;
  font-size: 20px;
  display: inline-block;
  margin-bottom: 5px;
  background-color: #d9d9d9;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  text-align: center;
  opacity: 0; }

.product-list-content li:nth-child(1) {
  -webkit-transform: translateX(60px);
  -ms-transform: translateX(60px);
  -o-transform: translateX(60px);
  transform: translateX(60px);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s; }

.product-list-content li:nth-child(1) i {
  font-weight: 600; }

.product-list-content li:nth-child(2) {
  -webkit-transform: translateX(90px);
  -ms-transform: translateX(90px);
  -o-transform: translateX(90px);
  transform: translateX(90px);
  -webkit-transition: all 0.6s ease-in-out 0s;
  -moz-transition: all 0.6s ease-in-out 0s;
  -ms-transition: all 0.6s ease-in-out 0s;
  -o-transition: all 0.6s ease-in-out 0s;
  transition: all 0.6s ease-in-out 0s; }

.product-list-content li:nth-child(2) a i {
  font-weight: 100; }

.product-list-content li:nth-child(3) {
  -webkit-transform: translateX(120px);
  -ms-transform: translateX(120px);
  -o-transform: translateX(120px);
  transform: translateX(120px);
  -webkit-transition: all 1.1s ease-in-out 0s;
  -moz-transition: all 1.1s ease-in-out 0s;
  -ms-transition: all 1.1s ease-in-out 0s;
  -o-transition: all 1.1s ease-in-out 0s;
  transition: all 1.1s ease-in-out 0s; }

.product-list-content li:nth-child(3) i {
  font-weight: 600; }

.product-list-content:hover .product-item-actions li {
  opacity: 1; }

.product-list-content:hover .product-item-actions li:hover {
  background-color: #ff8400; }

.product-item-actions .active {
  background-color: #ff8400; }

.product-list-content:hover .product-item-actions li:hover a {
  color: white !important; }

.product-item-actions .active a {
  color: white !important; }

.product-list-content:hover .product-item-actions li:nth-child(1) {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s; }

.product-list-content:hover .product-item-actions li:nth-child(2) {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: all 0.6s ease-in-out 0s;
  -moz-transition: all 0.6s ease-in-out 0s;
  -ms-transition: all 0.6s ease-in-out 0s;
  -o-transition: all 0.6s ease-in-out 0s;
  transition: all 0.6s ease-in-out 0s; }

.product-list-content:hover .product-item-actions li:nth-child(3) {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: all 1.1s ease-in-out 0s;
  -moz-transition: all 1.1s ease-in-out 0s;
  -ms-transition: all 1.1s ease-in-out 0s;
  -o-transition: all 1.1s ease-in-out 0s;
  transition: all 1.1s ease-in-out 0s; }

.tab-content > .tab-pane {
  position: absolute;
  display: block !important;
  opacity: 0;
  visibility: hidden;
  z-index: 0;
  width: 100%; }

.tab-content > .tab-pane.active {
  position: relative;
  z-index: 1;
  opacity: 1;
  visibility: visible;
  width: 100%;
  -webkit-animation: fadeIn 0.3s ease-in-out 0.3s both;
  -o-animation: fadeIn 0.3s ease-in-out 0.3s both;
  animation: fadeIn 0.3s ease-in-out 0.3s both; }

.col-haft {
  display: inline-block;
  float: left;
  height: 160px;
  padding: 35px 0 0 20px;
  width: 49.6%;
  margin-top: 30px; }

.col-haft:nth-child(1) {
  float: left; }

.col-haft:nth-child(2) {
  float: right; }

.col-haft h4 {
  font-size: 17px;
  font-weight: bold;
  text-transform: uppercase; }

.col-haft p {
  line-height: 20px;
  margin: 8px 0 10px; }

.col1 {
  background: transparent url("banner-page1.jpg") repeat scroll 0 0/cover; }

.col2 {
  background: transparent url("banner-page2.jpg") repeat scroll 0 0/cover; }

.image-banner2 .col1 {
  background: transparent url("banner-page3.jpg") repeat scroll 0 0/cover; }

.image-banner2 .col2 {
  background: transparent url("banner-page4.jpg") repeat scroll 0 0/cover; }

.brand-sale h6 {
  font-weight: 600;
  margin-bottom: 15px;
  text-transform: uppercase; }

.brand-sale-box {
  border: 1px solid #dddddd;
  margin-bottom: 30px;
  padding: 20px 0; }

.brand-sale-box .brand-media {
  margin-left: 5px; }

.brand-sale-box:last-child {
  margin-bottom: 0; }

.brand-sale-box.row {
  margin-left: 0;
  margin-right: 0; }

.brand-sale-box .brand-sale-box-content {
  padding-top: 28px;
  padding-right: 10px; }

.brand-sale-box .brand-logo {
  display: inline-block;
  margin-bottom: 20px; }

.brand-sale-box .col-md-7 a, .brand-sale-box .col-md-7 a img {
  display: inline-block;
  width: 100%; }

.brand-sale-box .sale-name {
  color: #333333;
  font-size: 16px;
  margin: 0; }

.brand-sale-box .sale-count del {
  font-size: 16px;
  margin-right: 8px; }

.brand-sale-box .sale-count span {
  font-size: 18px;
  font-weight: 600;
  color: #da3838; }

.brand-sale-box .sale-time {
  background-color: #e6e6e6;
  line-height: 31px;
  width: 100%;
  display: inline-block;
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  -ms-border-radius: 0 3px 3px 0;
  -o-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
  margin-top: 60px; }

.brand-sale-box .sale-time > i {
  color: #333333;
  float: left;
  font-size: 23px;
  text-align: center;
  width: 38px; }

.brand-sale-box .sale-time .block-title {
  display: inline-block;
  height: 21px; }

.brand-sale-box .sale-time .block-title .box-count {
  display: inline-block;
  float: left;
  position: relative;
  line-height: 22px;
  padding-right: 20px;
  padding-top: 5px; }

.brand-sale-box .sale-time .block-title .box-count::before {
  position: absolute;
  content: '-';
  right: 7px;
  top: 5px; }

.brand-sale-box .sale-time .block-title .box-count:last-child {
  padding-right: 0; }

.brand-sale-box .sale-time .block-title .box-count:last-child::before {
  content: ''; }

.brand-sale-box .sale-time .block-title .box-count div {
  display: inline-block;
  float: left; }

.brand-sale-box .sale-time .block-title .box-count div.number {
  color: #da3838;
  margin-right: 2px; }

.brand-sale-box .sale-time .block-title .box-count div.text {
  margin: 0;
  text-transform: uppercase;
  color: #333333; }

.brand-sale-box .sale-time .orange {
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  -ms-border-radius: 0 3px 3px 0;
  -o-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
  display: inline-block;
  float: right;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase; }

.brand-sale-box .sale-time .orange:hover {
  background: #666666; }

.div-icon-box {
  display: inline-block;
  width: 100%; }

.icon-box {
  padding: 20px;
  border: 1px solid #dddddd;
  margin-top: 30px;
  background-color: white; }

.icon-box .col-md-4 i {
  background-color: #666;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  color: white;
  font-size: 44px;
  margin-top: 10px;
  display: inline-block;
  height: 90px;
  line-height: 88px;
  width: 90px;
  text-align: center;
  position: relative; }

.icon-box .flaticon-return-of-investment {
  padding-left: 4px; }

.icon-box .col-md-8 {
  padding-left: 5px; }

.icon-box h4 {
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase; }

.icon-box p {
  line-height: 22px;
  margin: 8px 0; }

.icon-box a {
  color: #3366cc; }

.icon-box a i {
  margin-left: 8px; }

.icon-box a:hover {
  color: #ff8400; }

.icon-box .col-md-4 i::after {
  border: 5px solid rgba(225, 132, 0, 0.8);
  border-radius: 50%;
  content: "";
  height: 115px;
  left: -13px;
  position: absolute;
  top: -13px;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  width: 115px;
  opacity: 0; }

.icon-box:hover .col-md-4 i {
  background-color: #ff8400; }

.icon-box:hover .col-md-4 i::after {
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 1; }

.icon-box .icon-box-content a {
  position: relative;
  display: inline-block; }

.icon-box .icon-box-content a::before {
  border-bottom: 1px solid #ff8400;
  bottom: 4px;
  content: "";
  left: 0;
  position: absolute;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  width: 0; }

.icon-box .icon-box-content a:hover::before {
  width: 100%; }

.logo-slide {
  margin: 27px 0; }

.logo-slide .owl-nav > div {
  display: none;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s; }

.logo-slide .owl-prev {
  -webkit-transform: translateX(-50px) translateY(-50%);
  -ms-transform: translateX(-50px) translateY(-50%);
  -o-transform: translateX(-50px) translateY(-50%);
  transform: translateX(-50px) translateY(-50%); }

.logo-slide .owl-next {
  -webkit-transform: translateX(50px) translateY(-50%);
  -ms-transform: translateX(50px) translateY(-50%);
  -o-transform: translateX(50px) translateY(-50%);
  transform: translateX(50px) translateY(-50%); }

.logo-slide:hover .owl-nav > div {
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  opacity: 1;
  -webkit-transform: translateX(0) translateY(-50%);
  -ms-transform: translateX(0) translateY(-50%);
  -o-transform: translateX(0) translateY(-50%);
  transform: translateX(0) translateY(-50%); }

.logo-slide-item {
  position: relative;
  display: inline-block;
  width: 100%;
  float: left;
  border: 1px solid #dddddd; }

.logo-slide-item a::before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 132, 0, 0.2);
  opacity: 0;
  z-index: 9;
  -webkit-transition: all 0.6s ease-in-out 0s;
  -moz-transition: all 0.6s ease-in-out 0s;
  -ms-transition: all 0.6s ease-in-out 0s;
  -o-transition: all 0.6s ease-in-out 0s;
  transition: all 0.6s ease-in-out 0s;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0); }

.logo-slide-item:hover a::before {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1); }

.brand-sale-box .col-md-7 a,
.col-haft {
  position: relative; }

.brand-sale-box .col-md-7 a::before,
.col-haft::before {
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  top: 0;
  right: 100%;
  background-color: rgba(225, 132, 0, 0.2);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s; }

.brand-sale-box .col-md-7 a:hover::before,
.col-haft:hover::before {
  right: 0;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s; }

a.button:hover {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  animation-name: button; }

@-webkit-keyframes button {
  15% {
    -webkit-transform: translateX(-5px);
    -ms-transform: translateX(-5px);
    -o-transform: translateX(-5px);
    transform: translateX(-5px); }

  50% {
    -webkit-transform: translateX(5px);
    -ms-transform: translateX(5px);
    -o-transform: translateX(5px);
    transform: translateX(5px); }

  75% {
    -webkit-transform: translateX(-2px);
    -ms-transform: translateX(-2px);
    -o-transform: translateX(-2px);
    transform: translateX(-2px); }

  100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@-o-keyframes button {
  15% {
    -webkit-transform: translateX(-5px);
    -ms-transform: translateX(-5px);
    -o-transform: translateX(-5px);
    transform: translateX(-5px); }

  50% {
    -webkit-transform: translateX(5px);
    -ms-transform: translateX(5px);
    -o-transform: translateX(5px);
    transform: translateX(5px); }

  75% {
    -webkit-transform: translateX(-2px);
    -ms-transform: translateX(-2px);
    -o-transform: translateX(-2px);
    transform: translateX(-2px); }

  100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@-moz-keyframes button {
  15% {
    -webkit-transform: translateX(-5px);
    -ms-transform: translateX(-5px);
    -o-transform: translateX(-5px);
    transform: translateX(-5px); }

  50% {
    -webkit-transform: translateX(5px);
    -ms-transform: translateX(5px);
    -o-transform: translateX(5px);
    transform: translateX(5px); }

  75% {
    -webkit-transform: translateX(-2px);
    -ms-transform: translateX(-2px);
    -o-transform: translateX(-2px);
    transform: translateX(-2px); }

  100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@keyframes button {
  15% {
    -webkit-transform: translateX(-5px);
    -ms-transform: translateX(-5px);
    -o-transform: translateX(-5px);
    transform: translateX(-5px); }

  50% {
    -webkit-transform: translateX(5px);
    -ms-transform: translateX(5px);
    -o-transform: translateX(5px);
    transform: translateX(5px); }

  75% {
    -webkit-transform: translateX(-2px);
    -ms-transform: translateX(-2px);
    -o-transform: translateX(-2px);
    transform: translateX(-2px); }

  100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

.home2 {
  background-color: #f2f2f2; }

.home2 .title-left {
  display: inline-block;
  float: left;
  margin: 0; }

.home2 .col-haft {
  padding: 0;
  margin-bottom: 30px;
  margin-top: 0; }

.home2 .banner-asblute li {
  background-position: right;
  background-size: cover; }

.banner-item-h2-1 {
  background: transparent  repeat scroll 0 0; }

.banner-item-h2-2 {
  background: transparent  repeat scroll 0 0; }

.banner-item-h2-3 {
  background: transparent  repeat scroll 0 0; }

.banner-item-h2-4 {
  background: transparent  repeat scroll 0 0; }

.banner-item-h2-5 {
  background: transparent repeat scroll 0 0; }

.banner-home2 .banner-home2-left {
  width: 940px;
  float: left;
  overflow: hidden; }

.banner-home2 .banner-home2-right {
  width: 220px;
  margin-left: 10px;
  float: left; }

.banner-home2 .banner-home2-right .banner-asblute {
  position: inherit; }

.banner-home2-right .list-banner .banner-item {
  padding: 23px 10px 28px; }

.list-banner .banner-item {
  box-shadow: 0 0 5px rgba(223, 223, 223, 0.63);
  margin-bottom: 12px;
  padding: 19px 10px 27px;
  width: 100%; }

.list-banner .banner-item .banner-item-title {
  font-size: 15px;
  margin-bottom: 5px;
  color: #000000;
  text-transform: uppercase; }

.list-banner .banner-item .banner-item-desc {
  color: #888888;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 9px;
  margin-top: 1px; }

.list-banner .banner-item .banner-item-link {
  display: inline-block;
  position: relative;
  color: #000000; }

.list-banner .banner-item .banner-item-link:hover {
  color: #ff8400; }

.list-banner .banner-item .banner-item-link::before {
  border-bottom: 1px solid #ff8400;
  bottom: 3px;
  content: "";
  left: 0;
  position: absolute;
  transition: all 0.3s ease 0s;
  width: 0; }

.list-banner .banner-item .banner-item-link:hover::before {
  width: 100%; }

.home2 .banner-asblute {
  display: inline-block;
  width: 100%; }

.banner-home2-title {
  display: inline-block;
  text-align: right;
  width: 100%;
  padding: 0 20px; }

.poduct-list-slide {
  margin-bottom: 30px; }

.poduct-list-slide .owl-nav > div.owl-prev {
  left: -15px; }

.poduct-list-slide .owl-nav > div.owl-next {
  right: -15px; }

.banner-home2-bottom {
  display: inline-block;
  width: 100%;
  padding: 16px 0px 5px;
  background-color: white;
  border: 1px solid #dddddd;
  border-radius: 3px;
  margin-top: 10px; }

.banner-home2-bottom .product-list {
  border: medium none; }

.banner-home2-bottom .banner-home2-title > a {
  color: #333333;
  display: inline-block;
  text-align: right;
  margin-left: 52px; }

.banner-home2-bottom .banner-home2-title > a i {
  margin-left: 8px; }

.banner-home2-bottom .banner-home2-title > a:hover {
  color: #ff8400; }

.banner-home2-bottom .block-title {
  color: #888888;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  height: 30px;
  text-transform: uppercase; }

.banner-home2-bottom .block-title i {
  display: inline-block;
  float: left;
  font-size: 25px; }

.banner-home2-bottom .block-title .deals-of-countdown {
  display: inline-block;
  height: 30px; }

.banner-home2-bottom .block-title .deals-of-countdown div {
  display: inline-block;
  margin: 0;
  position: relative; }

.banner-home2-bottom .block-title .deals-of-countdown div.box-count.box-days {
  display: none; }

.banner-home2-bottom .block-title .deals-of-countdown div.box-count {
  padding-left: 8px; }

.banner-home2-bottom .block-title .deals-of-countdown .box-count:before {
  position: absolute;
  content: ':';
  left: 2px;
  top: 0; }

.banner-home2-bottom .block-title .deals-of-countdown .box-count.box-hours::before {
  content: ''; }

.banner-home2-bottom .title-left {
  float: left;
  margin: 5px 0;
  font-size: 16px; }

.has-background-color {
  background: #f2f2f2; }

.home3 {
  background-color: #eaeaea; }

.home3 header {
  background-color: white; }

.home3 .menu-primary {
  background-color: #3374a6; }

.home3 .has-mega-menu {
  background-color: #234f71; }

.home3 footer {
  background-color: white; }

.home3 .footer-top {
  border-top: none; }

.home3 nav a:hover {
  color: #ff8400; }

.home3 .footer-mid .footer-mid-text p span {
  color: #3374a6; }

.active .banner-home3-content h3 {
  -webkit-animation: slideInDown 1s ease-in-out 0.3s both;
  -o-animation: slideInDown 1s ease-in-out 0.3s both;
  animation: slideInDown 1s ease-in-out 0.3s both; }

.active .banner-home3-content p {
  -webkit-animation: slideInDown 0.6s ease-in-out 0s both;
  -o-animation: slideInDown 0.6s ease-in-out 0s both;
  animation: slideInDown 0.6s ease-in-out 0s both; }

.active .banner-home3-content a {
  -webkit-animation: zoomIn 1s ease-in-out 0s both;
  -o-animation: zoomIn 1s ease-in-out 0s both;
  animation: zoomIn 1s ease-in-out 0s both; }

.banner-home3 .owl-controls {
  display: none; }

.banner-home3:hover .owl-controls {
  display: block; }

.banner-home3-content {
  position: relative; }

.banner-home3-content a {
  height: 43px;
  line-height: 42px;
  padding: 0 20px;
  font-size: 16px;
  font-weight: 600; }

.banner-home3-content .slide-h3-1-content {
  position: absolute;
  right: 150px;
  text-align: center;
  text-transform: uppercase;
  top: 90px; }

.banner-home3-content .slide-h3-1-content h3 {
  font-size: 40px; }

.banner-home3-content .slide-h3-1-content p {
  font-size: 30px;
  margin: 10px 0 20px; }

.banner-home3-content .slide-h3-2-content {
  left: 320px;
  position: absolute;
  top: 160px; }

.banner-home3-content .slide-h3-2-content h3 {
  text-transform: uppercase; }

.banner-home3-content .slide-h3-3-content {
  left: 330px;
  position: absolute;
  top: 80px; }

.banner-home3-content .slide-h3-3-content h3 {
  text-transform: uppercase; }

.div-icon-box-h3 {
  padding: 40px 0; }

.icon-box-h3 {
  padding: 0 0 0 70px;
  position: relative; }

.icon-box-h3 .icon-box-h3-left {
  color: #333333;
  font-size: 57px;
  left: 3px;
  position: absolute;
  top: 20px; }

.icon-box-h3 .icon-box-h3-right h6 {
  text-transform: uppercase;
  color: #3374a6;
  margin-bottom: 2px; }

.icon-box-h3 .icon-box-h3-right p {
  margin: 0;
  font-size: 13px;
  line-height: 17px; }

.tab-home3-logo {
  background-color: white;
  display: inline-block; }

.tab-home3-logo .tab-content, .tab-home3-logo .nav.nav-pills {
  float: left; }

.tab-home3-logo .nav li {
  display: inline-block;
  float: left;
  padding: 0;
  width: 20%; }

.tab-home3-logo .nav li.active {
  background-color: #d3d3d3;
  position: relative; }

.tab-home3-logo .nav li.active::before {
  background-color: #d3d3d3;
  bottom: -21px;
  content: "";
  height: 19px;
  left: 50%;
  position: absolute;
  transform: rotate(45deg) translateX(-18px);
  -o-transform: rotate(45deg) translateX(-18px);
  -ms-transform: rotate(45deg) translateX(-18px);
  -moz-transform: rotate(45deg) translateX(-18px);
  -webkit-transform: rotate(45deg) translateX(-18px);
  width: 19px;
  z-index: 2; }

.rate > span {
  width: 100%; }

.banner-page-home3 {
  margin: 20px 0; }

.product-tabs-style2 {
  display: inline-block;
  position: relative;
  width: 100%;
  margin-top: 12px; }

.product-tabs-style2 .tab-title {
  color: #333333;
  line-height: 50px;
  font-size: 16px;
  font-weight: 600;
  left: 0;
  position: absolute;
  text-transform: uppercase;
  top: 0; }

.product-tabs-style2 .nav.nav-pills {
  border: medium none;
  padding-right: 70px; }

.product-tabs-style2 .left-tab {
  background-color: white;
  border-color: #dddddd;
  border-style: solid;
  border-width: 1px;
  float: left;
  width: 20%; }

.product-tabs-style2 .left-tab ul {
  padding: 8px 20px 9px; }

.product-tabs-style2 .left-tab ul li {
  line-height: 36px; }

.product-tabs-style2 .left-tab figure {
  margin: 0; }

.product-tabs-style2 .right-tab {
  width: 80%;
  float: left;
  position: relative; }

.product-tabs-style2 .product-tabs-content {
  border-width: 0;
  display: inline-block;
  overflow: visible;
  background: transparent; }

.product-tabs-style2 .product-tabs-content .tab-pane-slide {
  border-bottom: 1px solid #dddddd;
  background-color: white; }

.product-tabs-style2 .product-tabs-content .product-list-content {
  border-width: 1px 1px 0 0;
  border-color: #dddddd;
  border-style: solid;
  padding-bottom: 15px;
  padding-top: 16px; }

.product-tabs-style2 .product-tabs-content .product-list-content figure {
  margin: 0; }

.product-tabs-style2 .product-tabs-content .rate {
  float: right; }

.product-tabs-style2 .nav {
  padding-bottom: 5px; }

.product-tabs-style2 .left-tab {
  margin-top: 49px; }

.product-tabs-style2 .owl-carousel .owl-item img {
  display: table;
  margin: 0 auto;
  width: auto; }

.product-tabs-style2 .owl-carousel .owl-item {
  display: inline-flex; }

.product-tabs-style2 .owl-item.active.active-first.active-last > div {
  display: inline-block; }

.product-tabs-style2 .owl-nav > div {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  height: 20px;
  line-height: 20px;
  top: -37px;
  transform: none;
  -o-transform: none;
  -ms-transform: none;
  -moz-transform: none;
  -webkit-transform: none;
  width: 20px; }

.product-tabs-style2 .owl-nav > div.owl-prev {
  left: auto;
  right: 28px; }

.product-tabs-style2 .owl-nav > div.owl-next {
  right: 0; }

.product-tabs-style2 .owl-nav > div:hover {
  color: #c3023b; }

.product-tabs-style2 .supermartket-owl-carousel .product-list-content {
  width: 25%; }

.product-tabs-style2 .nav.nav-pills {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  padding: 0 70px 0 0; }

.product-tabs-style2 .nav.nav-pills li {
  line-height: 50px; }

.product-tabs-style2 .nav.nav-pills li.active, .product-tabs-style2 .nav.nav-pills li:hover {
  background: transparent none repeat scroll 0 0; }

.product-tabs-style2 .nav.nav-pills li.active a, .product-tabs-style2 .nav.nav-pills li:hover a {
  color: #ff8400; }

.product-tabs-style2 .tab-content > .tab-pane.active .product-list-content {
  animation: 1s ease-in-out 0s normal both 1 running fadeIn;
  -webkit-animation: 1s ease-in-out 0s normal both 1 running fadeIn; }

.product-tabs-content.equal-container {
  overflow: hidden;
  position: relative; }

.rate {
  display: inline-block;
  height: 15px;
  position: relative;
  width: 75px;
  margin-right: 15px; }

.rate::before {
  content: "\f005\f005\f005\f005\f005";
  font-family: "FontAwesome";
  line-height: 17px;
  font-size: 13px;
  letter-spacing: 2px;
  position: absolute;
  color: #ddd;
  z-index: 1; }

.rate span {
  height: 15px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0; }

.rate span::before {
  color: #ffa500;
  content: "\f005\f005\f005\f005\f005";
  font-family: "FontAwesome";
  line-height: 17px;
  font-size: 13px;
  letter-spacing: 2px;
  position: absolute;
  z-index: 2;
  overflow: hidden; }

.product-tabs-style3 .owl-nav > div {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  height: 20px;
  line-height: 20px;
  top: -35px;
  transform: none;
  color: #666666;
  width: 20px; }

.product-tabs-style3 .owl-nav > div.owl-prev {
  left: auto;
  right: 28px; }

.product-tabs-style3 .owl-nav > div:hover {
  color: #f8b91d; }

.product-tabs-style3 .owl-carousel .owl-item img {
  display: table;
  margin: 0 auto;
  width: auto; }

.product-tabs-style3 .product-tabs-content:before {
  content: "";
  width: 100%;
  border-top: 4px solid #f8b91d;
  top: 0; }

.product-tabs-style3 .nav.nav-pills {
  margin-bottom: -1px;
  padding-right: 90px;
  background: #f2f2f2; }

.product-tabs-style3 .nav.nav-pills li:hover, .product-tabs-style3 .nav.nav-pills li.active {
  background: #f8b91d; }

.product-tabs-style3 .nav.nav-pills li:hover a, .product-tabs-style3 .nav.nav-pills li.active a {
  color: #333333; }

.product-tabs-style3 .nav.nav-pills li a {
  color: #666666; }

.product-tabs-style3 .tab-content {
  overflow: visible;
  display: inline-block; }

.product-tabs-style3 .tab-content > .tab-pane.active {
  animation: 1s ease-in-out 0s normal both 1 running fadeIn;
  -webkit-animation: 1s ease-in-out 0s normal both 1 running fadeIn; }

.product-tabs-style3 .owl-item.active-last > .product-list-content {
  border: none; }

.product-tabs-style3 .product-list-content {
  border-right: 1px solid #dddddd;
  border-top: 1px solid #dddddd;
  width: auto; }

.product-tabs-style3 .product-tabs-content {
  border-top: none; }

.product-tabs .product-media img {
  width: auto; }

/* product-tabs-style4*/
.product-tabs-style4 {
  overflow: hidden;
  padding-top: 6px;
  margin-top: 15px;
  float: left; }

.product-tabs-style4 .product-tabs-content {
  border-top: medium none #000080;
  display: inline-block;
  overflow: visible;
  margin-top: 14px;
  width: 100%;
  position: relative; }

.product-tabs-style4 .product-tabs-content::before {
  border-right: 1px solid #dddddd;
  content: "";
  height: 100px;
  position: absolute;
  right: -1px;
  top: -25px; }

.product-tabs-style4.product-tabs .tab-title {
  float: left;
  font-size: 28px;
  font-style: italic;
  line-height: 27px;
  margin-right: 7px;
  margin-top: 16px;
  padding-right: 13px;
  position: relative; }

.product-tabs-style4.product-tabs .tab-title::before {
  border-top: 1px solid #dddddd;
  bottom: -14px;
  content: "";
  position: absolute;
  width: 100%; }

.product-tabs-style4.product-tabs .tab-title::after {
  border-left: 1px solid #dddddd;
  content: "";
  height: 27px;
  position: absolute;
  right: -4px;
  top: 14px;
  transform: rotate(17deg); }

.product-tabs-style4 .product-list-content {
  bottom: 25px;
  padding-top: 45px; }

.product-tabs-style4 .nav.nav-pills {
  border-color: #333333;
  border-width: 0px;
  float: left;
  position: relative;
  margin: -1px -1px -1px 0px; }

.product-tabs-style4 .nav.nav-pills::before {
  border-bottom: 1px solid #dddddd;
  bottom: 0px;
  content: "";
  left: 100%;
  position: absolute;
  width: 9999px; }

.product-tabs-style4 .nav.nav-pills::after {
  border-bottom: 2px solid #333333;
  bottom: 0px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 9999; }

.product-tabs-style4 .nav.nav-pills li {
  background: #d1d6da none repeat scroll 0 0;
  border-radius: 2px 2px 0 0;
  display: inline-block;
  line-height: 33px;
  margin: 0px 0 0 1px;
  padding: 0 20px;
  text-transform: uppercase;
  -webkit-transition: all 0s ease-in-out 0s;
  -moz-transition: all 0s ease-in-out 0s;
  -ms-transition: all 0s ease-in-out 0s;
  -o-transition: all 0s ease-in-out 0s;
  transition: all 0s ease-in-out 0s; }

.product-tabs-style4 .nav.nav-pills li:first-child {
  margin-left: 9px;
  border-radius: 5px 2px 0 0; }

.product-tabs-style4 .nav.nav-pills li:first-child::before {
  border-bottom: 32px solid #d1d6da;
  border-left: 10px solid transparent;
  bottom: 2px;
  content: "";
  left: -9px;
  position: absolute;
  top: 0px; }

.product-tabs-style4 .nav.nav-pills li.active:first-child::before {
  border-bottom-color: #333; }

.product-tabs-style4 .nav.nav-pills li:first-child:hover::before {
  border-bottom-color: #333; }

.product-tabs-style4 .nav.nav-pills li.active {
  background: #333 none repeat scroll 0 0; }

.product-tabs-style4 .nav.nav-pills li:hover {
  background: #333 none repeat scroll 0 0; }

.product-tabs-style4 .nav.nav-pills li:hover a {
  color: white; }

.product-tabs-style4 .owl-nav > div {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  border-radius: 2px;
  font-size: 20px;
  height: 34px;
  line-height: 32px;
  top: -63px;
  transform: none;
  -o-transform: none;
  -webkit-transform: none;
  width: 25px; }

.product-tabs-style4 .owl-nav > div.owl-prev {
  left: auto;
  right: 36px; }

.product-tabs-style4 .owl-nav > div.owl-next {
  right: 0; }

.product-tabs-style4 .owl-nav > div:hover {
  background: #333333;
  color: white; }

.product-tabs-style4.product-tabs-color2 .tab-title {
  color: #025291; }

.product-tabs-style4.product-tabs-color2 .nav.nav-pills::after {
  border-color: #025291; }

.product-tabs-style4.product-tabs-color2 .nav.nav-pills li:first-child:hover::before {
  border-bottom-color: #025291; }

.product-tabs-style4.product-tabs-color2 .nav.nav-pills li.active:first-child::before {
  border-bottom-color: #025291; }

.product-tabs-style4.product-tabs-color2 .nav.nav-pills li.active {
  background-color: #025291; }

.product-tabs-style4.product-tabs-color2 .nav.nav-pills li:hover {
  background-color: #025291; }

.product-tabs-style4.product-tabs-color2 .owl-nav > div:hover {
  background: #025291; }

.product-tabs-style4.product-tabs-color3 .tab-title {
  color: #f8b91d; }

.product-tabs-style4.product-tabs-color3 .nav.nav-pills::after {
  border-color: #f8b91d; }

.product-tabs-style4.product-tabs-color3 .nav.nav-pills li:first-child:hover::before {
  border-bottom-color: #f8b91d; }

.product-tabs-style4.product-tabs-color3 .nav.nav-pills li.active:first-child::before {
  border-bottom-color: #f8b91d; }

.product-tabs-style4.product-tabs-color3 .nav.nav-pills li.active {
  background-color: #f8b91d; }

.product-tabs-style4.product-tabs-color3 .nav.nav-pills li:hover {
  background-color: #f8b91d; }

.product-tabs-style4.product-tabs-color3 .owl-nav > div:hover {
  background: #f8b91d; }

.product-tabs-style4.product-tabs-color4 .tab-title {
  color: #6da236; }

.product-tabs-style4.product-tabs-color4 .nav.nav-pills::after {
  border-color: #6da236; }

.product-tabs-style4.product-tabs-color4 .nav.nav-pills li:first-child:hover::before {
  border-bottom-color: #6da236; }

.product-tabs-style4.product-tabs-color4 .nav.nav-pills li.active:first-child::before {
  border-bottom-color: #6da236; }

.product-tabs-style4.product-tabs-color4 .nav.nav-pills li.active {
  background-color: #6da236; }

.product-tabs-style4.product-tabs-color4 .nav.nav-pills li:hover {
  background-color: #6da236; }

.product-tabs-style4.product-tabs-color4 .owl-nav > div:hover {
  background: #6da236; }

.product-tabs-style4 .active-last .product-list-content:before {
  right: -1px; }

.product-tabs-style4 .product-list-content:before {
  content: "";
  border-right: 1px solid #dddddd;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0; }

.category-media > figure {
  overflow: hidden; }

.category-item .category-media img {
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s; }

.category-item:hover .category-media img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
  transform: scale(1.05); }

/*product-tabs-style5*/
.product-tabs-style5 {
  border: 1px solid #dddddd;
  padding: 22px 10px 18px;
  overflow: hidden;
  margin: 0; }

.product-tabs-style5 .product-tabs-content {
  border: medium none;
  overflow: visible;
  position: relative;
  display: inline-block;
  width: 100%; }

.product-tabs-style5 .nav.nav-pills {
  border: medium none;
  padding-right: 75px; }

.product-tabs-style5 .nav.nav-pills li {
  line-height: 21px;
  margin: 0;
  padding: 0 12px;
  font-size: 13px; }

.product-tabs-style5 .nav.nav-pills li a {
  color: #666666; }

.product-tabs-style5 .nav.nav-pills li:hover {
  background-color: transparent; }

.product-tabs-style5 .nav.nav-pills li.active {
  background-color: transparent; }

.product-tabs-style5 .nav.nav-pills li:hover a {
  color: #006393; }

.product-tabs-style5.product-tabs .tab-title {
  position: relative;
  float: left;
  margin-left: 20px; }

.product-tabs-style5 .nav-style5 .owl-nav > div {
  right: 5px;
  top: -12px; }

.product-tabs-style5 .nav-style5 .owl-nav > div.owl-prev {
  left: auto;
  right: 41px; }

/*Hot Deal Slide */
.host-deals-slide {
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  padding: 12px 0;
  background: white;
  overflow: hidden; }

.host-deals-slide .product-media {
  float: left;
  margin-right: 55px; }

.host-deals-slide .product-media img {
  height: auto;
  width: auto; }

.host-deals-slide .product-info {
  padding-top: 25px;
  padding-right: 80px; }

.host-deals-slide .product-info .title-product {
  line-height: 20px;
  text-transform: uppercase;
  margin-bottom: 25px; }

.host-deals-slide .product-info .price {
  font-size: 26px;
  color: #da3838;
  font-weight: 600; }

.host-deals-slide .product-info .price p {
  line-height: 26px;
  margin: 0;
  font-size: 14px;
  color: #666666; }

.host-deals-slide .product-info .deal-in-on {
  color: #da3838;
  margin-top: 5px;
  display: inline-block; }

.host-deals-slide .product-info .deal-link {
  font-size: 16px;
  font-weight: 600;
  line-height: 42px;
  text-transform: uppercase;
  clear: both;
  display: inline-block;
  padding: 0 25px;
  margin-top: 25px;
  border-radius: 3px;
  margin-bottom: 35px;
  background: #f8b91d;
  color: white; }

.host-deals-slide .product-info .deal-link:hover {
  background: #333333;
  color: white; }

.host-deals-slide .product-info > p {
  margin: 0; }

.host-deals-slide .hot-deal-count {
  padding: 20px 0 0 105px; }

.host-deals-slide .hot-deal-count .title-deal {
  text-transform: uppercase;
  margin-bottom: 30px; }

.host-deals-slide .hot-deal-count .hot-deal-countdown {
  background: #e2e2e2 none repeat scroll 0 0;
  border-bottom: 9px solid #f8b91d;
  border-radius: 2px;
  padding: 20px 20px 17px;
  text-align: center;
  display: inline-block;
  overflow: hidden; }

.host-deals-slide .hot-deal-count .box-count {
  width: 50px;
  height: 50px;
  display: inline-block;
  background: #443266;
  border-radius: 2px;
  margin: 0 10px;
  text-align: center;
  color: white; }

.host-deals-slide .hot-deal-count .box-count .number {
  font-size: 30px;
  font-weight: 600;
  line-height: 17px;
  padding-top: 8px; }

.host-deals-slide .hot-deal-count .box-count .text {
  font-size: 14px;
  font-weight: 600; }

.host-deals-slide .owl-nav > div {
  background: #443266 none repeat scroll 0 0;
  border: medium none;
  border-radius: 0 5px 5px 0;
  color: #333333;
  display: inline-block;
  font-size: 24px;
  bottom: 15px;
  height: auto;
  line-height: 44px;
  position: absolute;
  text-align: center;
  top: 15px;
  transform: translateY(0px);
  width: 35px;
  overflow: hidden;
  left: 0; }

.host-deals-slide .owl-nav > div.owl-next {
  left: auto;
  right: 0;
  border-radius: 5px 0 0 5px; }

.host-deals-slide .owl-nav > div.owl-next:before {
  right: -100%;
  left: auto; }

.host-deals-slide .owl-nav > div.owl-next:hover::before {
  right: 0;
  left: auto; }

.host-deals-slide .owl-nav > div:before {
  content: "";
  background: #666;
  position: absolute;
  top: 0;
  left: -100%;
  bottom: 0;
  right: 0;
  width: 35px;
  border-radius: 0 5px 5px 0;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s; }

.host-deals-slide .owl-nav > div:hover::before {
  left: 0; }

.host-deals-slide .owl-nav > div span {
  width: 25px;
  color: #333333;
  background: white;
  height: 25px;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%); }

.host-deals-slide .item-deal .product-media:hover img {
  animation-duration: 0.8s;
  -webkit-animation-duration: 0.8s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-name: bounceIn;
  -webkit-animation-name: bounceIn;
  opacity: 0.8; }

.host-deals-slide.host-deals-slide-style-2 {
  border: 1px solid #dddddd;
  margin-top: 19px; }

.host-deals-slide.host-deals-slide-style-2 .hot-deal-count {
  padding: 2px 0 0 50px; }

.host-deals-slide.host-deals-slide-style-2 .hot-deal-count .box-count {
  margin: 0 7px; }

.host-deals-slide.host-deals-slide-style-2 .hot-deal-count .title-deal {
  margin-bottom: 13px;
  font-size: 16px; }

.host-deals-slide.host-deals-slide-style-2 .product-media {
  margin-right: 37px;
  margin-top: 15px;
  margin-left: 10px; }

.host-deals-slide.host-deals-slide-style-2 .hot-deal-countdown {
  border-color: #da3838;
  padding: 13px 20px 13px; }

.host-deals-slide.host-deals-slide-style-2 .hot-deal-countdown .number {
  font-size: 24px; }

.host-deals-slide.host-deals-slide-style-2 .hot-deal-countdown .text {
  font-size: 14px; }

.host-deals-slide.host-deals-slide-style-2 .product-info {
  padding-right: 20px;
  padding-top: 20px; }

.host-deals-slide.host-deals-slide-style-2 .product-info .deal-link {
  background: #da3838 none repeat scroll 0 0;
  clear: both;
  color: white;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  line-height: 35px;
  margin-bottom: 0;
  margin-left: 55px;
  margin-top: 35px;
  padding: 0 20px;
  border-radius: 2px;
  text-transform: uppercase; }

.host-deals-slide.host-deals-slide-style-2 .product-info .deal-link:hover {
  background-color: #0b2076;
  color: white; }

.host-deals-slide.host-deals-slide-style-2 .product-info .title-product {
  margin-bottom: 5px;
  text-transform: uppercase;
  font-size: 14px; }

.host-deals-slide.host-deals-slide-style-2 .product-info .price {
  color: #da3838;
  display: inline-block;
  float: left;
  font-size: 24px;
  text-align: left;
  font-weight: 600;
  text-align: left; }

.host-deals-slide.host-deals-slide-style-2 .product-info .price p {
  display: block; }

.host-deals-slide.host-deals-slide-style-3 {
  border: 1px solid #dddddd;
  margin-top: 0;
  padding: 20px; }

.host-deals-slide.host-deals-slide-style-3 .tab-title {
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 12px; }

.host-deals-slide.host-deals-slide-style-3 .hot-deal-count {
  padding: 0; }

.host-deals-slide.host-deals-slide-style-3 .hot-deal-count .box-count {
  margin: 0 2px;
  background-color: #666666;
  width: 40px;
  line-height: 20px;
  padding: 0;
  height: 38px;
  margin-bottom: 0; }

.host-deals-slide.host-deals-slide-style-3 .hot-deal-count .box-count .number {
  font-size: 20px;
  line-height: 20px;
  margin: 0;
  padding: 1px 0 0; }

.host-deals-slide.host-deals-slide-style-3 .hot-deal-count .box-count .text {
  font-size: 14px;
  line-height: 14px;
  margin: 0; }

.host-deals-slide.host-deals-slide-style-3 .hot-deal-count .title-deal {
  margin-bottom: 13px;
  font-size: 16px; }

.host-deals-slide.host-deals-slide-style-3 .product-media {
  margin-right: 37px;
  margin-top: 15px;
  margin-left: 10px; }

.host-deals-slide.host-deals-slide-style-3 .hot-deal-countdown {
  background: #f2f2f2 none repeat scroll 0 0;
  border-color: #ffca5a;
  border-radius: 4px;
  border-width: 8px;
  padding: 8px 0 0;
  width: 100%;
  margin-top: 12px; }

.host-deals-slide.host-deals-slide-style-3 .product-info {
  padding-right: 20px;
  padding-top: 20px; }

.host-deals-slide.host-deals-slide-style-3 .product-info .deal-link {
  background: #da3838 none repeat scroll 0 0;
  clear: both;
  color: white;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  line-height: 35px;
  margin-bottom: 0;
  margin-left: 55px;
  margin-top: 35px;
  padding: 0 20px;
  border-radius: 2px;
  text-transform: uppercase; }

.host-deals-slide.host-deals-slide-style-3 .product-info .deal-link:hover {
  background-color: #0b2076;
  color: white; }

.host-deals-slide.host-deals-slide-style-3 .product-info .title-product {
  margin-bottom: 5px;
  text-transform: uppercase;
  font-size: 14px; }

.host-deals-slide.host-deals-slide-style-3 .product-info .price {
  color: #da3838;
  display: inline-block;
  float: left;
  font-size: 24px;
  text-align: left;
  font-weight: 600;
  text-align: left; }

.host-deals-slide.host-deals-slide-style-3 .product-info .price p {
  display: block; }

.host-deals-slide .feature-slide-name {
  display: inline-block;
  margin: 7px 0 0 10px;
  line-height: 20px; }

.host-deals-slide .feature-slide-cost {
  color: #bbbbbb;
  font-size: 13px;
  line-height: 19px;
  margin-left: 10px; }

.host-deals-slide .feature-slide-cost .price {
  color: #da3838; }

.host-deals-slide .nav-style5 .owl-nav > div::before {
  display: none; }

.host-deals-slide .nav-style5 .owl-nav > div {
  border-radius: 3px;
  top: -20px; }

.host-deals-slide .nav-style5 .owl-nav > div span {
  background-color: transparent; }

.host-deals-slide .nav-style5 .owl-nav > div.owl-prev {
  left: auto;
  right: 35px; }

/* Newsletter */
.newsletter-form-wrap {
  background: #443266;
  text-align: center;
  padding: 55px 40px 76px; }

.newsletter-form-wrap .news-desc {
  line-height: 18px;
  color: #bbbbbb;
  padding: 28px 0 39px;
  margin: 0; }

.newsletter-form-wrap .newsletter-title {
  color: white;
  text-transform: uppercase; }

.newsletter-form-wrap .newsletter-content {
  position: relative; }

.newsletter-form-wrap .newsletter-content > input {
  width: 100%;
  line-height: 50px;
  height: 51px;
  padding: 0 55px 0 15px;
  border-radius: 0;
  color: #888888; }

.newsletter-form-wrap .newsletter-content .submit-button {
  background: #f8b91d none repeat scroll 0 0;
  border: medium none;
  bottom: 0;
  color: white;
  font-size: 30px;
  line-height: 50px;
  position: absolute;
  right: 0;
  top: 0;
  width: 57px;
  padding: 0; }

.newsletter-form-wrap .newsletter-content .submit-button:hover {
  background-color: #da3838;
  color: white; }

/*newsletter-form-style2*/
.newsletter-form-style2.newsletter-form-wrap {
  /*background: transparent url("bn-newsletter.jpg") no-repeat scroll 0 0;*/
  padding: 70px 20px 88px;
  text-align: left;
  border-radius: 3px; }

.newsletter-form-style2.newsletter-form-wrap .newsletter-title {
  font-size: 26px; }

.newsletter-form-style2.newsletter-form-wrap .news-desc {
  color: white;
  line-height: 24px;
  margin: 0;
  padding: 20px 0 35px; }

/*Category Slide*/
.categories-slide-wrap {
  border: 1px solid #dddddd;
  padding: 25px 0 47px; }

.categories-slide-wrap .tab-title {
  text-transform: uppercase;
  margin-bottom: 15px;
  padding: 0 20px; }

.categories-slide-wrap .categories-slide {
  padding: 0 20px;
  overflow: hidden; }

.categories-slide-wrap .categories-slide .category-info {
  padding: 10px 10px 20px;
  background: #f2f2f2;
  text-align: center; }

.categories-slide-wrap .categories-slide .cat-name > a {
  color: #333333;
  text-transform: uppercase;
  line-height: 20px; }

.categories-slide-wrap .categories-slide .cat-name > a:hover {
  color: #0b2076; }

.categories-slide-wrap .categories-slide .cat-sku {
  font-size: 14px;
  color: #0b2076; }

.categories-slide-wrap .categories-slide .cat-desc {
  line-height: 20px;
  color: #666666;
  font-size: 14px;
  margin-bottom: 15px; }

.categories-slide-wrap .categories-slide .cat-link {
  line-height: 32px;
  background: #666;
  color: white;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  text-transform: uppercase;
  padding: 0 15px;
  border-radius: 2px; }

.categories-slide-wrap .categories-slide .cat-link:hover {
  background-color: #0b2076;
  color: white; }

.categories-slide-wrap .categories-slide .owl-nav > div {
  left: -50px;
  -webkit-transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.5s ease-in-out 0s;
  -ms-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s; }

.categories-slide-wrap .categories-slide .owl-nav > div.owl-next {
  left: auto;
  right: -50px; }

.categories-slide-wrap .categories-slide:hover .owl-nav > div {
  left: 8px; }

.categories-slide-wrap .categories-slide:hover .owl-nav > div.owl-next {
  left: auto;
  right: 8px; }

/*Better Sales  */
.better-sales-slide {
  border: 1px solid #dddddd;
  padding: 16px 0 15px 20px;
  position: relative; }

.better-sales-slide .better-sale-item {
  display: inline-block;
  margin-bottom: 24px;
  width: 100%; }

.better-sales-slide .better-sale-media {
  float: left;
  margin: 0 10px 0 0; }

.better-sales-slide .better-sale-media img {
  width: auto; }

.better-sales-slide .title-product {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 25px; }

.better-sales-slide .price {
  color: #da3838; }

.better-sales-slide .tab-title {
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 15px; }

.nav-style5 .owl-nav > div {
  background: #e2e2e2 none repeat scroll 0 0;
  border: medium none;
  border-radius: 3px;
  color: #333333;
  display: inline-block;
  font-size: 20px;
  height: 36px;
  left: auto;
  line-height: 32px;
  position: absolute;
  right: 10px;
  text-align: center;
  top: -22px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 26px; }

.nav-style5 .owl-nav > div.owl-prev {
  right: 45px;
  left: auto; }

.nav-style5 .owl-nav > div:hover {
  color: white;
  background-color: #f8b91d; }

/*Category*/
.category-slider-wrap {
  display: inline-block;
  width: 100%; }

.category-slider-wrap .tab-title {
  line-height: 36px;
  text-transform: uppercase; }

.category-slider-wrap .categories-group {
  margin-top: 20px; }

.category-slider-wrap .nav-style5 .owl-nav > div {
  right: 0;
  top: -18px; }

.category-slider-wrap .nav-style5 .owl-nav > div.owl-prev {
  left: auto;
  right: 35px; }

.category-slider-wrap .catergory-item {
  display: inline-block;
  margin-bottom: 30px;
  width: 100%;
  background: #f2f2f2;
  padding: 25px 0 15px 0; }

.category-slider-wrap .catergory-item .catergory-item-media {
  float: left;
  margin-right: 10px; }

.category-slider-wrap .catergory-item .title-product {
  font-size: 14px;
  line-height: 20px;
  padding: 10px 20px 0;
  text-transform: uppercase; }

.category-slider-wrap .catergory-item .link-cat {
  background: #006393 none repeat scroll 0 0;
  border-radius: 3px;
  color: white;
  display: inline-block;
  font-size: 14px;
  line-height: 31px;
  margin-top: 20px;
  padding: 0 17px;
  text-transform: uppercase; }

.category-slider-wrap .catergory-item .link-cat:hover {
  background-color: #f8b91d;
  color: white; }

/*Call To action*/
.call-to-action {
  background: #da3838;
  padding: 10px;
  line-height: 24px;
  font-size: 20px;
  color: white;
  text-align: center;
  font-weight: 600; }

.call-to-action a {
  color: #0b2076;
  margin-left: 5px;
  font-size: 16px;
  text-decoration: underline; }

.call-to-action a:hover {
  color: #f8b91d; }

/*Banner Slide with cate*/
.banner-slide-with-cate .more-categories {
  font-size: 16px; }

.banner-slide-with-cate .more-categories:hover {
  background-color: #0b2076;
  color: white; }

.banner-slide-with-cate nav > ul > li.menu-item:hover > a {
  color: white; }

.banner-slide-with-cate .has-mega-menu {
  background-color: #034261;
  width: 270px;
  z-index: 5; }

.banner-slide-with-cate .has-mega-menu .category-drop-list {
  border: 1px solid #dddddd;
  opacity: 1;
  visibility: visible;
  display: block !important;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: all 0.6s ease-in-out 0s;
  -moz-transition: all 0.6s ease-in-out 0s;
  -ms-transition: all 0.6s ease-in-out 0s;
  -o-transition: all 0.6s ease-in-out 0s;
  transition: all 0.6s ease-in-out 0s;
  padding: 1px 0; }

.banner-slide-with-cate .has-mega-menu .category-drop-list.category-drop-list-show {
  height: auto; }

.banner-slide-with-cate .has-mega-menu .category-drop-list > .sub-menu {
  padding-bottom: 30px;
  height: 440px; }

.banner-slide-with-cate .has-mega-menu .category-drop-list .category-drop-list-inner {
  height: 538px;
  padding-bottom: 44px; }

.banner-slide-with-cate .has-mega-menu.category-show-more .category-drop-list-inner {
  height: auto; }

.banner-slide-with-cate .has-mega-menu .category-drop-list li {
  display: inline-block;
  float: left;
  line-height: 35px;
  margin: 0;
  padding: 2px 15px 2px 25px;
  cursor: pointer; }

.banner-slide-with-cate .has-mega-menu .category-drop-list li i {
  font-size: 25px;
  margin-right: 20px;
  top: 3px;
  position: relative; }

.banner-slide-with-cate .has-mega-menu .category-drop-list li .show-submenu {
  color: #333333;
  float: right;
  width: 35px;
  height: 35px;
  text-align: right; }

.banner-slide-with-cate .has-mega-menu .category-drop-list li .show-submenu i {
  font-size: 14px;
  margin: 0; }

.banner-slide-with-cate .has-mega-menu .category-drop-list li:hover > a {
  color: #006393; }

.banner-slide-with-cate .has-mega-menu .category-drop-list li:hover .show-submenu {
  color: #006393; }

.banner-slide-with-cate .has-mega-menu .category-drop-list li:hover .show-submenu i {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg); }

.banner-slide-with-cate .has-mega-menu .category-drop-list-inner > .sub-menu {
  height: calc(100% - 60px); }

.banner-slide-with-cate .has-mega-menu .category-drop-list > .more-categories.show-before::before {
  color: #333333;
  content: "Close";
  font-size: 16px;
  left: 0px;
  position: absolute;
  top: 0;
  right: 0; }

.banner-slide-with-cate .has-mega-menu .category-drop-list > .more-categories {
  background-color: #bbbbbb;
  color: #333333;
  font-size: 16px;
  line-height: 47px;
  text-align: center;
  margin: -1px;
  right: 0;
  width: auto; }

.banner-slide-with-cate .has-mega-menu .category-drop-list > .more-categories:hover {
  background-color: #006393;
  color: white; }

.banner-slide-with-cate .has-mega-menu .category-drop-list > .more-categories.show-before:hover::before {
  background-color: #006393;
  color: white; }

.banner-slide-with-cate .has-mega-menu .category-drop-list > .more-categories.show-before {
  font-size: 0; }

.banner-slide-with-cate .has-mega-menu .category-drop-list > .more-categories i {
  margin-left: 20px; }

.banner-slide-with-cate .has-mega-menu .has-mega:hover .mega-menu {
  -webkit-transform: translateY(-44px);
  -ms-transform: translateY(-44px);
  -o-transform: translateY(-44px);
  transform: translateY(-44px); }

.banner-slide-with-cate .has-mega-menu {
  width: 270px;
  background-color: #0b2076; }

@media screen and (min-width: 0 \0) {
  .header .header-search .form-search-header select {
    background: none !important;
    width: 190px;
    padding: 0 20px; }

  header.header-style4 .form-search-header input {
    width: 330px; } }
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .header-style2 .form-search-header select {
    height: 35px; }

  .header-style1 .form-search-header select {
    height: 34px; } }
@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-color-index: 0) {
  .header-style2 .form-search-header select {
    height: 35px; }

  .header-style2 .form-search-header input {
    margin-top: 4px; } }
/* -------------------- */
/*      end main        */
/* -------------------- */
/* -------------------- */
/*   PRODUCT STYLE      */
/* -------------------- */
/* -------------------- */
/*      categories      */
/* -------------------- */
.categories {
  background: #f5f5f5 none repeat scroll 0 0; }

.categories-checkbox > .categories {
  background: transparent none repeat scroll 0 0; }

.categories-grid1 {
  padding-top: 50px; }

.categories-grid1 .panel-categories {
  margin: 40px 0 32px; }

.categories-grid2 {
  padding-top: 40px; }

.categories-grid3 {
  padding-top: 30px; }

.categories-grid3 .main-top {
  background: white none repeat scroll 0 0; }

.categories-list1, .categories-list2 {
  padding-top: 40px; }

.has-sidebar-left {
  float: right; }

.breadcrumb-wrap {
  width: 100%;
  margin-bottom: 13px;
  display: inline-block; }

.breadcrumb-wrap li {
  background-color: white;
  border: 1px solid #dddddd;
  color: #888888;
  display: inline-block;
  float: left;
  line-height: 19px;
  margin: 0 4px 0 0;
  padding: 3px 13px 3px 25px;
  position: relative; }

.breadcrumb-wrap li::before {
  background-color: white;
  border-color: #dddddd;
  border-style: solid;
  border-width: 1px 1px 0 0;
  content: "";
  height: 20px;
  position: absolute;
  right: -10px;
  top: 3px;
  transform: rotate(45deg);
  width: 18px;
  z-index: 3; }

.breadcrumb-wrap li::after {
  background-color: white;
  border-color: #dddddd;
  border-style: solid;
  border-width: 1px 1px 0 0;
  content: "";
  height: 20px;
  position: absolute;
  left: -10px;
  top: 3px;
  transform: rotate(45deg);
  width: 18px;
  z-index: 2; }

.breadcrumb-wrap li:first-child {
  padding-left: 15px; }

.breadcrumb-wrap li:first-child::after {
  display: none;
  height: 0;
  width: 0;
  color: #888; }

.breadcrumb-wrap li:last-child {
  color: #333333; }

.breadcrumb-wrap li > a {
  color: #888888;
  text-transform: capitalize; }

.blog-categories-sidebar {
  display: inline-block;
  width: 100%; }

.sidebar-title {
  border-bottom: 1px solid #dddddd;
  color: #333333;
  font-weight: 600;
  margin-bottom: 10px;
  text-transform: uppercase;
  padding-bottom: 3px;
  width: 100%;
  display: inline-block; }

.sidebar-title-section {
  color: #333333;
  font-weight: 600;
  margin-bottom: 25px;
  margin-top: -3px;
  text-transform: uppercase; }

.shop-sidebar-content {
  background-color: white;
  border: 1px solid #dddddd;
  margin-top: 27px;
  padding: 23px 20px;
  overflow: hidden; }

.shop-sidebar-content .categories-checkbox li {
  cursor: pointer; }

.shop-sidebar-content .categories-checkbox li:hover label {
  color: #ff8400; }

.shop-sidebar-content > div {
  border-bottom: 1px dotted #aaa;
  margin-bottom: 20px;
  padding-bottom: 20px; }

.shop-sidebar-content > div:last-child {
  border: medium none;
  padding-bottom: 0;
  margin-bottom: 0; }

.shop-sidebar-content input {
  cursor: pointer;
  margin-right: 6px;
  transform: translateY(2px); }

.shop-sidebar-content label {
  color: #888;
  margin-bottom: 8px;
  font-weight: 400; }

.shop-sidebar-content .price-sidebar > span {
  border: 1px solid #dddddd;
  border-radius: 3px;
  display: inline-block;
  float: left;
  line-height: 30px;
  margin-right: 9px;
  text-align: center;
  width: 60px; }

.shop-sidebar-content .price-filter {
  margin: 0 3px 0 9px;
  padding-bottom: 35px; }

.sidebar-top .shop-sidebar-content div.price-sidebar {
  padding-right: 33px; }

.slider-range {
  background-color: #eee;
  border: medium none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  height: 5px;
  margin-top: 75px;
  position: relative; }

.slider-range .filter-top {
  left: 0;
  /*margin: 0 -10px;*/
  position: absolute;
  top: -55px;
  width: 100%; }

.slider-range .filter-top span.amount, .slider-range .filter-top span.amount2 {
  border: 1px solid #dddddd;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  float: left;
  height: 33px;
  line-height: 31px;
  margin-right: 10px;
  text-align: center;
  width: auto;
  padding: 0 5px; }

.slider-range .filter-top span.amount2 {
  margin: 0px;
  float: right; }

.slider-range .filter-top a {
  float: left;
  font-weight: 400;
  padding: 0 15px; }

.slider-range .ui-slider-range:last-child {
  background-color: #00a1d0; }

.slider-range .ui-state-default {
  background-color: #888888;
  border: medium none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  color: #888888;
  font-size: 14px;
  font-weight: 400;
  height: 12px;
  width: 12px; }

.slider-range .ui-state-default span {
  position: absolute;
  top: 17px; }

.slider-range .ui-state-default span span {
  position: initial; }

.slider-range .ui-state-default span.amount2 {
  -webkit-transform: translateX(-22px);
  -ms-transform: translateX(-22px);
  -o-transform: translateX(-22px);
  transform: translateX(-22px); }

.color-sidebar {
  display: inline-block;
  width: 100%; }

.color-sidebar .color-sidebar-content {
  float: left;
  width: 50%;
  line-height: 21px;
  cursor: pointer; }

.color-sidebar .box-color {
  background-color: yellow;
  border-radius: 3px;
  float: left;
  height: 23px;
  margin: 0 10px 9px 0;
  width: 23px; }

.color-sidebar .box-color.box-color-white {
  background: white none repeat scroll 0 0;
  border: 1px solid #dddddd;
  border-radius: 0;
  color: #000;
  line-height: 21px;
  margin-right: 8px;
  padding: 0 3px;
  width: auto; }

.color-sidebar .box-color-orange {
  background-color: #ff8400; }

.color-sidebar .box-color-brown {
  background-color: #6b5a5c; }

.color-sidebar .box-color-red {
  background-color: #da3838; }

.color-sidebar .box-color-black {
  background-color: #333; }

.color-sidebar .box-color-blue {
  background-color: #3063f2; }

.color-sidebar .box-color-cyan {
  background-color: #00FFFF; }

.compare-sidebar {
  margin: 25px 0; }

.compare-sidebar .compare-sidebar-content {
  background-color: white;
  border: 1px solid #dddddd;
  border-radius: 3px;
  color: #888;
  font-size: 13px;
  line-height: 30px;
  margin-top: 20px;
  padding: 15px 20px; }

.bestseller-item {
  display: inline-block;
  margin: 10px 0;
  min-height: 130px;
  padding-left: 122px;
  padding-top: 15px;
  position: relative;
  width: 100%; }

.bestseller-item > a {
  left: 0;
  position: absolute;
  top: 0; }

.bestseller-item .bestseller-text {
  color: #888;
  display: inline-block;
  width: 100%;
  margin-bottom: 6px; }

.bestseller-item .bestseller-text:hover {
  color: #ff8400; }

.bestseller-item span {
  color: #333333;
  font-size: 16px;
  font-family: "Open Sans", sans-serif; }

.tag-sidebar-item {
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 7px; }

.tag-sidebar-item a {
  background-color: white;
  border: 1px solid #dddddd;
  color: #888888;
  display: inline-block;
  line-height: 33px;
  padding: 0 15px; }

.tag-sidebar-item a:hover {
  background-color: #ff8400;
  color: white;
  border-color: #ff8400; }

.view-all-tags {
  background-color: white;
  border: 1px solid #dddddd;
  display: inline-block;
  line-height: 30px;
  color: #000000;
  padding: 0 12px; }

.view-all-tags i {
  margin-left: 25px; }

.view-all-tags:hover {
  color: white;
  border-color: #ff8400;
  background-color: #ff8400; }

.sidebar {
  display: inline-block; }

.section-icon-box .icon-box {
  margin: 0 0 30px; }

.panel-categories {
  border-bottom: 1px solid #dddddd;
  border-top: 1px solid #dddddd;
  padding: 8px 0;
  margin: 40px 0;
  width: 100%;
  display: inline-block; }

.products-categories + .panel-categories {
  margin: 0 0 30px; }

.categories-grid2 .products-categories + .panel-categories {
  margin: 0 0 30px; }

.view-categories > div {
  background-color: #b0b0b0;
  border-radius: 3px;
  color: #333333;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  height: 31px;
  line-height: 31px;
  text-align: center;
  width: 31px;
  margin-right: 7px; }

.view-categories > div:hover, .view-categories > div.color-active {
  background-color: #ff8400;
  color: white; }

.select-categories {
  text-align: center; }

.select-categories-content {
  display: inline-block; }

.select-categories-content select {
  background: white url("ar.jpg") no-repeat scroll 91% 50%;
  display: inline-block;
  height: 33px;
  line-height: 33px;
  padding: 0 30px 0 15px;
  border-radius: 2px; }

.select-categories-content select[name='position'] {
  margin-right: 25px; }

.select-categories-content span {
  color: #333333;
  margin-right: 12px; }

.page-nav {
  display: inline-block;
  margin: 0px;
  float: right; }

.page-nav > div {
  background-color: white;
  border: 1px solid #dddddd;
  color: #333333;
  display: inline-block;
  float: left;
  height: 33px;
  line-height: 31px;
  margin-left: 5px;
  text-align: center;
  width: 33px;
  cursor: pointer; }

.page-nav > div:hover, .page-nav > div.active {
  color: #ff8400; }

.categories-page .categories-list .img-product a {
  top: 43%; }

.categories-page .banner-categories-grid img {
  height: auto;
  width: 100%; }

.categories-page .products-categories {
  min-width: 100%;
  display: inline-block;
  margin-left: -15px;
  margin-right: -15px; }

.categories-page .product-box {
  float: left;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative; }

.categories-page .product-box:hover .img-product a {
  -webkit-transform: translateX(-50%) translateY(-50%) scale(1);
  -moz-transform: translateX(-50%) translateY(-50%) scale(1);
  -ms-transform: translateX(-50%) translateY(-50%) scale(1);
  -o-transform: translateX(-50%) translateY(-50%) scale(1);
  transform: translateX(-50%) translateY(-50%) scale(1);
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s; }

.categories-page .img-product {
  position: relative;
  width: 100%;
  text-align: center; }

.categories-page .img-product a {
  background-color: rgba(252, 165, 60, 0.78);
  color: white;
  font-size: 25px;
  left: 50%;
  position: absolute;
  padding: 2px 0 0 3px;
  top: 60%;
  -webkit-transform: translateX(-50%) translateY(-50%) scale(0);
  -moz-transform: translateX(-50%) translateY(-50%) scale(0);
  -ms-transform: translateX(-50%) translateY(-50%) scale(0);
  -o-transform: translateX(-50%) translateY(-50%) scale(0);
  transform: translateX(-50%) translateY(-50%) scale(0);
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s; }

.categories-page .product-box-content {
  background-color: white;
  border: 1px solid #dddddd;
  margin-bottom: 30px; }

.categories-page .product-box-content .product-box-text {
  color: #333333;
  padding: 0 10px; }

.categories-page .product-box-content .product-box-text p {
  line-height: 20px;
  font-size: 16px;
  margin: 0 0 4px; }

.categories-page .product-box-content .product-name {
  height: 45px;
  overflow: hidden;
  line-height: 20px;
  display: block;
  font-size: 16px; }

.categories-page .product-box-content .categories-page .product-box-content .product-cost {
  font-size: 16px;
  margin: 5px 0 8px !important; }

.categories-page .product-box-bottom {
  display: inline-block;
  margin: 5px 0 10px;
  padding: 0;
  text-align: center;
  width: 100%; }

.categories-page .product-box-bottom a {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #dddddd;
  display: inline-block;
  font-size: 11px;
  line-height: 30px;
  text-align: center;
  text-transform: uppercase;
  width: 18%; }

.categories-page .product-box-bottom a.add-to-cart i {
  margin-right: 3px; }

.categories-page .product-box-bottom a i {
  font-size: 14px;
  font-weight: 600; }

.categories-page .product-box-bottom a.wishlist {
  margin: 0 1%; }

.categories-page .product-box-bottom a.refresh-product i {
  font-weight: 100 !important; }

.categories-page .product-box-bottom a.add-to-cart {
  width: 57%; }

.categories-page .product-box-bottom a:hover {
  color: white;
  border-color: #ff8400;
  background-color: #ff8400; }

.categories-page .col-md-3, .categories-page .col-md-9 {
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s; }

.categories .sidebar-top {
  display: none; }

.categories.categories-grid3 .sidebar-top {
  display: block; }

.categories-grid .product-box {
  width: 25%; }

.categories-grid .desc-product, .categories-grid .write {
  display: none; }

.categories-grid3 .sidebar {
  display: inline-block;
  max-width: 0;
  overflow: hidden;
  padding: 0;
  width: 0;
  height: 0; }

.categories-grid3 .content-categorise {
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  width: 100%; }

.categories-grid3 .product-box {
  width: 20%; }

.categories-grid2 .breadcrumb-sidebar {
  margin-top: 20px; }

.categories-grid2 .panel-categories {
  margin: 45px 0 30px; }

.categories-list2 .breadcrumb-sidebar {
  margin-top: 40px; }

.main-top {
  background: #f5f5f5 none repeat scroll 0 0;
  margin-top: 30px;
  padding-bottom: 30px; }

.sidebar-top {
  margin: 20px 0 0; }

.sidebar-top::before {
  background-color: white;
  bottom: 0;
  content: "";
  left: 100%;
  position: absolute;
  top: 0;
  width: 1000px; }

.sidebar-top::after {
  background-color: white;
  bottom: 0;
  content: "";
  right: 100%;
  position: absolute;
  top: 0;
  width: 1000px; }

.sidebar-top .shop-sidebar-content {
  border: medium none;
  margin: 30px -15px 0;
  background: transparent none repeat scroll 0 0;
  padding: 0; }

.sidebar-top .shop-sidebar-content .col-md-3 > div::before {
  border-right: 1px solid #dddddd;
  content: "";
  height: 202px;
  position: absolute;
  right: 0;
  top: 0;
  width: 1px; }

.sidebar-top .shop-sidebar-content .categories-sidebar, .sidebar-top .shop-sidebar-content .price-sidebar, .sidebar-top .shop-sidebar-content .manufacture-sidebar, .sidebar-top .shop-sidebar-content .color-sidebar {
  padding: 0 15px; }

.sidebar-top .shop-sidebar-content .col-md-3:last-child > div::before {
  border: medium none; }

.sidebar-top .shop-sidebar .close-side-top {
  display: inline-block;
  float: right;
  height: 30px;
  position: relative;
  width: 20px;
  z-index: 3;
  cursor: pointer; }

.sidebar-top .shop-sidebar .close-side-top::before {
  background-color: #333;
  content: "";
  font-family: inherit;
  height: 2px;
  transform: rotate(45deg);
  width: 20px;
  top: 10px;
  position: absolute; }

.sidebar-top .shop-sidebar .close-side-top::after {
  background-color: #333;
  content: "";
  font-family: inherit;
  height: 2px;
  transform: rotate(-45deg);
  width: 20px;
  position: absolute;
  top: 10px;
  left: 0; }

.sidebar-top .shop-sidebar.hiden-drop .close-side-top::before {
  content: '\f103';
  font-family: Flaticon;
  font-size: 18px;
  left: 0;
  top: 2px;
  transform: rotate(0deg);
  background-color: transparent; }

.sidebar-top .shop-sidebar.hiden-drop .close-side-top::after {
  width: 0; }

.categories-list .product-box {
  width: 100%; }

.categories-list .img-product {
  float: left;
  width: 33.33%; }

.categories-list .img-product > img {
  height: auto;
  width: 100%;
  border: 1px solid #dddddd; }

.categories-list .product-box-text {
  float: left;
  width: 66.6%; }

.categories-list .product-box-content {
  background-color: transparent;
  border: medium none;
  display: inline-block;
  margin-bottom: 25px;
  width: 100%; }

.categories-list .product-box-content .product-box-text {
  color: #333333;
  padding: 40px 20px; }

.categories-list .write {
  color: #888;
  display: inline-block;
  font-size: 13px;
  margin: 3px 0;
  width: 100%; }

.categories-list .product-box-content .product-box-text .desc-product {
  color: #888;
  display: inline-block;
  font-size: 14px;
  margin: 3px 0 20px;
  width: 100%; }

.categories-list .product-box-bottom {
  width: 225px; }

.categories-list .product-box-bottom a {
  font-size: 14px;
  line-height: 35px;
  background-color: white; }

.categories-list .product-box-bottom a i {
  font-size: 18px; }

.tag-sidebar {
  margin-top: 17px; }

input.checked + label {
  color: #ff8400; }

@media screen and (min-width: 0 \0) {
  div.select-categories-content select {
    padding-right: 0; } }
/* -------------------- */
/*      end categories  */
/* -------------------- */
/* -------------------- */
/*      detail          */
/* -------------------- */
.product-detail .breadcrumb-sidebar {
  padding: 30px 0 10px; }

.product-detail .product-box {
  float: left;
  min-height: 1px;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  width: 100%; }

.product-detail .product-box .product-box-content {
  border: medium none;
  background-color: transparent; }

.product-detail .product-box .owl-nav > div {
  background-color: #ddd;
  border: medium none;
  color: #333333;
  font-size: 20px;
  height: 25px;
  line-height: 21px;
  width: 25px; }

.product-detail .product-box .owl-nav > div:hover {
  color: white;
  background-color: #ff8400; }

.product-detail .product-box .feature-slide-item {
  border: 1px solid #dddddd;
  text-align: center;
  background: white; }

.product-detail .product-box .feature-slide-item:hover, .product-detail .product-box .feature-slide-item.active {
  border: 1px solid #ff8400; }

.product-detail .product-box .feature-slide-item > figure {
  display: inline-block; }

.product-detail .product-box .feature-slide-item img {
  border-radius: 0px;
  width: auto; }

.product-detail .product-box .img-room {
  bottom: 10px;
  cursor: pointer;
  position: absolute;
  right: 10px; }

.product-detail .product-box .img-product {
  border: 1px solid #dddddd;
  margin-bottom: 35px; }

.product-detail .product-box .write {
  color: #888;
  display: inline-block;
  margin: 5px 0 17px; }

.product-detail .product-box .product-cost {
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 0;
  color: #333333; }

.product-detail .product-box .stock {
  color: #333333;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 25px;
  text-align: right;
  width: 100%; }

.product-detail .product-box .stock span {
  margin-left: 10px;
  color: #ff8400; }

.product-detail .product-box .desc-product-title {
  text-transform: uppercase;
  color: #333333; }

.product-detail .product-box .desc-product {
  color: #888;
  line-height: 24px; }

.product-detail .product-box .social {
  display: inline-block;
  margin-bottom: 17px;
  margin-top: 22px;
  width: 100%; }

.product-detail .product-box .social > div {
  display: inline-block;
  float: left;
  margin-right: 3px; }

.product-detail .product-box .social > div.tweet-social {
  margin-right: 50px; }

.product-detail .product-box .social .amount-folow {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 3px;
  display: inline-block;
  float: right;
  height: 20px;
  line-height: 20px;
  margin-left: 6px;
  margin-top: 4px;
  position: relative;
  text-align: center;
  width: 20px; }

.product-detail .product-box .social .amount-folow::before {
  background-color: white;
  border-color: #aaa;
  border-style: solid;
  border-width: 0 0 1px 1px;
  content: "";
  height: 6px;
  left: -4px;
  position: absolute;
  top: 34%;
  transform: rotate(45deg);
  width: 6px;
  z-index: 2; }

.product-detail .product-box select {
  -moz-appearance: button;
  background: transparent none repeat scroll 0 0;
  border-radius: 0;
  color: #888888;
  font-size: 14px;
  margin-bottom: 1px; }

.product-detail .product-box .quanlity-product {
  float: left;
  width: 140px;
  padding: 7px 20px;
  background-color: #e8e8e8; }

.product-detail .product-box .quanlity-product span {
  color: #fca53c;
  line-height: 32px; }

.product-detail .product-box .product-box-bottom {
  margin-top: 18px; }

.product-detail .product-box .button-detail {
  float: left;
  width: 240px; }

.product-detail .product-box .button-detail a {
  font-size: 14px;
  height: 47px;
  line-height: 45px; }

.product-detail .product-box .button-detail a i {
  font-size: 18px;
  font-weight: 400;
  /*margin-right: 5px;*/ }

.product-detail .product-box .button-detail a.add-to-cart {
  color: white;
  background-color: #ff8400;
  border: 1px solid #ff8400; }

.product-detail .buttons_added {
  float: right;
  height: 33px;
  position: relative;
  width: 50px; }

.product-detail .buttons_added a {
  background-color: #333;
  border: medium none;
  color: white;
  line-height: 12px;
  position: absolute;
  padding-top: 2px;
  right: -1px;
  width: 15px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0; }

.product-detail .buttons_added a i {
  font-size: 10px;
  font-weight: 100; }

.product-detail .buttons_added input {
  height: 33px;
  color: #fca53c;
  line-height: 33px; }

.product-detail .buttons_added .minus {
  text-align: center;
  bottom: 1px;
  top: 50%; }

.product-detail .buttons_added .plus {
  text-align: center;
  bottom: 50%;
  top: 1px; }

.product-detail .detail-products {
  border: 1px solid #dddddd;
  position: relative;
  background-color: white;
  display: inline-block;
  margin-bottom: 20px; }

.product-detail .detail-products::before {
  position: absolute;
  content: '';
  height: 100%;
  width: 1px;
  background-color: #ddd;
  left: 14.7%;
  top: 0; }

.product-detail .detail-products .product-tabs-content {
  float: right;
  width: 85.3%;
  border-width: 0;
  padding: 40px 20px 55px; }

.review-title {
  margin-bottom: 30px; }

.product-detail .detail-products .nav.nav-pills {
  float: left;
  width: 14.7%;
  border-width: 0; }

.product-detail .detail-products .nav.nav-pills li {
  line-height: 45px;
  padding: 0;
  margin: 0;
  width: 100%; }

.product-detail .detail-products .nav.nav-pills li a {
  font-size: 16px;
  font-weight: 600;
  border-radius: 0;
  padding: 0 20px;
  border-bottom: 1px solid #dddddd;
  width: 100%;
  display: inline-block; }

.product-detail .detail-products .nav.nav-pills li a:hover {
  color: white;
  background-color: #ff8400;
  border-bottom: 1px solid #ff8400; }

.product-detail .detail-products .nav.nav-pills li.active a {
  color: white;
  background-color: #ff8400;
  border-bottom: 1px solid #ff8400; }

.product-detail .detail-products .tagcloud a {
  border: 1px solid #aaa;
  border-radius: 3px;
  display: inline-block;
  float: left;
  font-size: 13px;
  height: 38px;
  line-height: 38px;
  margin: 0 5px 5px 0;
  padding: 0 20px;
  text-transform: uppercase; }

.product-detail .detail-products .tagcloud a:hover {
  border: 1px solid #ff8400;
  background-color: #ff8400;
  color: white; }

.product-detail .detail-products li:last-child .comment_container {
  border: medium none; }

.product-detail .detail-products .comment_container {
  min-height: 130px;
  padding-left: 120px;
  position: relative;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #dddddd; }

.product-detail .detail-products .comment_container img {
  left: 0;
  position: absolute;
  top: 0; }

.product-detail .detail-products .rating {
  color: #ff8400; }

.product-detail .detail-products .description {
  border: 1px solid #aaa;
  border-radius: 3px;
  padding: 10px 20px; }

.product-detail .detail-products label {
  color: #000;
  width: 100%; }

.product-detail .detail-products input, .product-detail .detail-products textarea {
  width: 100%; }

.detail-slide {
  margin-bottom: 25px; }

.detail-slide .owl-carousel .owl-item img {
  transform-style: inherit;
  -o-transform-style: inherit;
  -moz-transform-style: inherit;
  -webkit-transform-style: inherit; }

.detail-slide h5 {
  border-bottom: 1px solid #dddddd;
  margin: 20px 0;
  padding-bottom: 6px;
  text-transform: uppercase;
  font-weight: 600; }

.detail-slide .product-box {
  background-color: white; }

.detail-slide .product-box .img-product {
  border: medium none;
  margin-bottom: 0; }

.detail-slide .product-box .product-box-content {
  border: 1px solid #dddddd;
  margin-bottom: 0; }

.detail-slide .owl-nav > div {
  height: 32px;
  line-height: 27px;
  top: -59px;
  transform: none;
  -o-transform: none;
  -webkit-transform: none;
  -moz-transform: none;
  width: 32px; }

.detail-slide .owl-nav > div.owl-next {
  right: 0; }

.detail-slide .owl-nav > div.owl-prev {
  left: auto;
  right: 37px; }

.detail-slide .owl-nav > div:hover {
  color: white;
  background-color: #ff8400;
  border: 1px solid #ff8400; }

.detail-slide .single-product-content > .product-name, .detail-slide .single-product-content > .product-cost {
  font-size: 14px;
  color: #888; }

.detail-slide .single-product-content .product-name:hover {
  color: #ff8400; }

.relates .owl-carousel .owl-item img, .upsales .owl-carousel .owl-item img {
  height: auto; }

.product-title {
  font-size: 20px;
  line-height: 26px; }

.product-box-primary .img-product > img {
  -webkit-transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.5s ease-in-out 0s;
  -ms-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
  left: 20%;
  top: 20%;
  width: 100%; }

.product-box-primary .img-product img {
  height: auto; }

.select-detail > span {
  display: block;
  color: #333333; }

.title-detail-slide {
  border-bottom: 1px solid #dddddd;
  margin-bottom: 20px;
  padding-bottom: 8px;
  text-transform: uppercase; }

.detail-slide.upsales {
  margin: 0; }

#section-icon-box {
  padding-bottom: 30px; }

.single-product-content {
  padding-right: 100px;
  padding-top: 56px; }

/* -------------------- */
/*      end detail      */
/* -------------------- */
/* -------------------- */
/*      shopingcart     */
/* -------------------- */
.shoping-cart {
  background-color: #f5f5f5; }

.title-page {
  margin: 35px 0 20px;
  text-transform: uppercase; }

.checkout-box:last-child {
  margin: 0; }

.shoping-form {
  display: inline-block;
  width: 100%; }

.shoping-form .div-table-cart {
  display: inline-block;
  background-color: white;
  width: 100%;
  margin-top: 20px;
  border: 1px solid #dddddd; }

.shoping-form .button-cart {
  display: inline-block;
  width: 100%;
  margin-bottom: 3px;
  margin-top: 10px; }

.shoping-form .button-cart a {
  margin: 0 10px; }

.shoping-form .button-check-out {
  float: right;
  height: 38px;
  line-height: 38px; }

.shoping-form table {
  border: medium none; }

.shoping-form th, .shoping-form td {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: navy #dddddd #dddddd;
  border-image: none;
  border-style: none solid solid;
  border-width: medium 1px 1px;
  text-align: center; }

.shoping-form tr a {
  color: #666666; }

.shoping-form tr a.remove {
  font-size: 25px; }

.shoping-form tr input {
  width: 43px;
  height: 43px;
  line-height: 43px;
  text-align: center;
  color: #666666; }

.shoping-form tr:first-child th, .shoping-form tr:first-child td {
  border-left: medium none navy; }

.shoping-form tr:last-child th:last-child, .shoping-form tr:last-child td:last-child {
  border-right: medium none; }

.cart-box {
  background-color: white;
  border: 1px solid #dddddd;
  margin-top: 30px;
  padding: 20px;
  display: inline-block;
  width: 100%; }

.cart-box > p {
  color: #333333;
  margin-bottom: 0; }

.cart-box > p i {
  color: orange;
  font-size: 22px;
  margin-right: 8px; }

.cart-box > span {
  color: #888; }

.cart-box input, .cart-box select {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  height: 43px;
  line-height: 41px;
  width: 100%; }

.cart-box select {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button; }

.base-selector-box {
  display: inline-block;
  width: 100%;
  margin: 20px 0 10px; }

.base-selector-box figure {
  display: inline-block;
  float: left;
  width: 33.33%;
  margin: 0; }

.base-selector-box .base-selector-content {
  display: inline-block;
  float: left;
  width: 66.66%; }

.base-selector-box a.button-check-out {
  height: 30px;
  line-height: 30px;
  margin: 6px 0; }

.base-selector-box .product-cost {
  color: #da3838;
  margin: 0; }

.base-selector-box p {
  margin: 0; }

.base-selector-box p a {
  font-size: 13px; }

.base-selector-box p a:first-child {
  border-right: 1px solid #333;
  display: inline-block;
  line-height: 12px;
  margin-right: 10px;
  padding-right: 10px; }

.discount-cost-box input {
  margin: 10px 0 20px; }

.discount-cost-box a.button-check-out {
  float: right; }

.estimate-box label {
  color: #333333;
  margin-bottom: 5px;
  margin-top: 12px; }

.estimate-box label span {
  color: orange;
  display: inline-block;
  font-size: 25px;
  height: 5px;
  line-height: 5px;
  margin-right: 8px;
  transform: translateY(10px); }

.estimate-box a.button-check-out {
  float: right;
  margin-top: 20px; }

.cart_totals table, .cart_totals th, .cart_totals td {
  border: medium none; }

.cart_totals th {
  width: 150px; }

.cart_totals .cart-subtotal th, .cart_totals .cart-subtotal td {
  color: #333333;
  font-weight: 400;
  padding: 0; }

.cart_totals .grand th, .cart_totals .grand td {
  color: #333333;
  font-weight: 600;
  padding: 0; }

.cart_totals a.button-check-out {
  margin-top: 15px; }

/* -------------------- */
/*    end shopingcart   */
/* -------------------- */
/* -------------------- */
/*      checkout       */
/* -------------------- */
.checkout-page {
  background-color: #f5f5f5; }

.checkout-page .main-content {
  padding-top: 33px; }

.title-left {
  color: #333333;
  font-weight: 600;
  margin-bottom: 25px;
  text-transform: uppercase; }

.color-333 {
  color: #333333; }

.checkout-box {
  display: inline-block;
  margin-bottom: 10px;
  width: 100%; }

.checkout-box .check-out-click {
  background-color: #a3a3a3;
  color: white;
  font-size: 16px;
  line-height: 44px;
  padding: 0 18px;
  cursor: pointer; }

.checkout-box .check-out-click span {
  margin-right: 15px; }

.checkout-box .checkout-toggle {
  background-color: white;
  border: 1px solid #dddddd;
  margin-bottom: 10px;
  padding: 15px 20px; }

.checkout-box .method-content-left input {
  margin: 12px 8px 12px 0; }

.checkout-box p {
  margin: 0;
  color: #666666; }

.checkout-box p a {
  color: #666666; }

.checkout-box p a:hover {
  color: #ff8400; }

.checkout-box .method-content-left-bottom {
  border-bottom: 1px solid #dddddd;
  display: inline-block;
  margin-bottom: 30px;
  padding-bottom: 10px;
  width: 100%; }

.checkout-box a.button {
  float: right;
  margin-bottom: 30px; }

.checkout-box .col-md-5 .button-check-out {
  padding: 0 20px; }

.method-content-right {
  padding-right: 100px;
  display: inline-block;
  width: 100%; }

.method-content-right label {
  width: 100%;
  margin: 10px 0 6px 12px; }

.method-content-right input {
  width: 100%;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0; }

.check-title-right {
  border-bottom: 1px solid #dddddd;
  color: #333333;
  display: inline-block;
  margin-bottom: 20px;
  padding-bottom: 3px;
  text-transform: uppercase;
  width: 100%; }

.check-button {
  background-color: white;
  border: 1px solid #dddddd;
  display: inline-block;
  padding: 20px;
  width: 100%; }

.check-button .button-white {
  border: 1px solid #dddddd;
  border-radius: 0;
  color: #666666;
  font-size: 14px;
  height: 36px;
  line-height: 34px;
  margin-bottom: 10px;
  padding: 0 10px;
  text-transform: none;
  width: 100%; }

.check-button .button-white:last-child {
  margin-bottom: 0; }

/* -------------------- */
/*      end checkout    */
/* -------------------- */
/* My Account */
#customer_login {
  display: inline-block;
  width: 100%; }

.login-form-wrap, .register-form-wrap, .forget-pass-form-wrap {
  padding: 35px 60px 60px; }

#customer_login div.register-form-wrap {
  background-color: #fafafa;
  border-left: none; }

#customer_login .login-form-wrap, #customer_login .register-form-wrap, #customer_login .forget-pass-form-wrap {
  background: white none repeat scroll 0 0;
  border: 1px solid #ececec;
  float: left;
  width: 50%; }

#customer_login .login-form-wrap > h2, #customer_login .register-form-wrap > h2, #customer_login .forget-pass-form-wrap > h2 {
  font-size: 16px;
  margin-bottom: 25px; }

#customer_login .input-text {
  width: 100%; }

#customer_login label {
  font-weight: 400;
  margin-left: 10px;
  margin-bottom: 0; }

.button[type="submit"] {
  background: #ff8400 none repeat scroll 0 0;
  border: medium none;
  border-radius: 3px;
  color: white;
  font-size: 14px;
  line-height: 44px;
  padding: 0 30px; }

.button[type="submit"]:hover {
  background: #000 none repeat scroll 0 0; }

#customer_login input[type="checkbox"], input[type="radio"] {
  line-height: normal;
  margin: 5px 4px 0 0; }

/* -------------------- */
/*      about           */
/* -------------------- */
.about-top {
  margin-bottom: 10px; }

.about-top .breadcrumb-sidebar {
  margin: 20px 0 0px; }

.about-top .title-left {
  margin: 0 0 12px; }

.about-top2 .check-title-left {
  margin-bottom: 6px; }

.about-top2 p {
  font-size: 13px; }

.about-top3 .chose-us-item {
  border-bottom: 1px dotted #aaa;
  display: inline-block;
  margin-bottom: 7px;
  padding-bottom: 7px;
  width: 100%; }

.about-top3 .chose-us-item a {
  color: #666666;
  font-size: 13px; }

.about-top3 .chose-us-item a i {
  font-size: 15px;
  margin-right: 10px; }

.about-top3 .chose-us-item a:hover {
  color: #ff8400; }

.about-bottom {
  background-color: #f5f5f5;
  padding-top: 55px;
  padding-bottom: 30px; }

.about-bottom .about-bottom-slide {
  text-align: center;
  padding-bottom: 26px; }

.about-bottom .about-bottom-slide .check-title-left {
  margin-bottom: 5px; }

.has-dots {
  padding-top: 60px;
  position: relative; }

.has-dots .owl-dots {
  padding: 0 0 30px;
  position: absolute;
  top: 0;
  text-align: center;
  width: 100%; }

.has-dots .owl-dots > div {
  background-color: #ddd;
  border-radius: 50%;
  display: inline-block;
  height: 20px;
  margin: 0 3px;
  width: 20px; }

.has-dots .owl-dots > div.active {
  background-color: #ff8400; }

.about-box figure {
  margin-bottom: 5px; }

.about-box .about-name {
  color: #157ed2;
  line-height: 20px; }

.about-box .about-name:hover {
  color: #ff8400; }

.about-box .about-career {
  color: #444;
  line-height: 20px;
  margin: 0; }

.about-box .about-desc {
  margin: 13px 0 0; }

.about-box .icon-box {
  margin-top: 30px; }

/* -------------------- */
/*      end about       */
/* -------------------- */
/* -------------------- */
/*      contact         */
/* -------------------- */
.contact-page {
  background-color: #f5f5f5; }

.contact-top {
  margin: 40px 0 62px; }

.contact-top .title-left {
  margin-bottom: 34px; }

.contact-top figure {
  margin-bottom: 10px; }

.contact-top figure iframe {
  width: 100%; }

.contact-form .contact-form-top {
  display: inline-block;
  max-width: 400px;
  width: 100%; }

.contact-form .contact-form-top input {
  height: 46px; }

.contact-form input, .contact-form textarea {
  width: 100%;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  padding-left: 10px;
  line-height: 46px;
  margin-bottom: 10px;
  background-color: white; }

.contact-form a.button {
  height: 39px;
  line-height: 37px;
  margin-top: 10px; }

.contact-page .div-icon-box {
  background-color: white;
  padding: 35px 0; }

.div-icon-border {
  border-right: 1px solid #dddddd; }

.div-icon-box .col-md-3:last-child .div-icon-border {
  border: medium none; }

.icon-box-contact {
  display: inline-block;
  padding-left: 60px;
  position: relative;
  width: 100%;
  padding-right: 10px;
  margin-bottom: 35px; }

.icon-box-contact:last-child {
  margin-bottom: 0; }

.icon-box-contact .icon-contact {
  left: 0;
  position: absolute;
  top: 20px; }

.icon-box-contact .icon-contact i {
  width: 42px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  background-color: #e4e4e4;
  color: #333333;
  position: relative; }

.icon-box-contact .icon-contact i::after {
  position: absolute;
  width: 57px;
  height: 57px;
  line-height: 57px;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  border: 3px solid rgba(225, 132, 0, 0.8);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  content: '';
  top: -7px;
  left: -7px; }

.icon-box-contact:hover .icon-contact i {
  background-color: #ff8400;
  color: white;
  font-size: 17px; }

.icon-box-contact:hover .icon-contact i::after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s; }

.icon-box-contact .icon-contact-text p {
  color: #333333;
  margin: 0;
  line-height: 20px;
  text-transform: uppercase; }

.icon-box-contact .icon-contact-text p a, .icon-box-contact .icon-contact-text p span {
  color: #666666;
  text-transform: capitalize; }

.icon-box-contact .icon-contact-text p a:hover {
  color: #ff8400; }

.icon-box-contact .icon-contact-text p:first-child {
  font-weight: 600; }

.contact-page .icon-box {
  margin-top: 25px; }

/* -------------------- */
/*     end contact      */
/* -------------------- */
/* -------------------- */
/*      BLOG            */
/* -------------------- */
.blog-page {
  background-color: #f5f5f5; }

.blog-page .main-content {
  padding-top: 40px; }

.blog-page .page-nav {
  float: left; }

.blog-page .page-nav > div {
  height: 39px;
  line-height: 37px;
  width: 39px; }

.blog-page .page-nav > div:first-child {
  margin: 0; }

div .page-nav > div:hover, div .page-nav > div.active {
  background: #ff8400 none repeat scroll 0 0;
  border-color: #ff8400;
  color: white; }

.blog-content .title-page {
  margin: 7px 0 22px; }

.sibar-blog {
  display: inline-block;
  width: 100%; }

.categories-blog {
  margin-bottom: 12px;
  padding-top: 5px; }

.blog-categories-sidebar li {
  border-bottom: 1px solid #efeeee;
  margin-bottom: 8px;
  padding-bottom: 8px;
  width: 100%; }

.blog-categories-sidebar li:last-child {
  border: medium none; }

.blog-categories-sidebar li a {
  color: #666666; }

.blog-categories-sidebar li a:hover {
  color: #ff8400; }

.blog-categories-sidebar li.active a {
  color: #ff8400; }

.bestseller-sidebar-blog {
  background-color: white;
  border: 1px solid #dddddd; }

.bestseller-sidebar-blog .title-left {
  border-bottom: 1px solid #dddddd;
  height: 32px;
  line-height: 32px;
  margin: 0;
  padding: 0 10px; }

.bestseller-sidebar-blog .owl-nav > div {
  border: medium none;
  height: 28px;
  line-height: 28px;
  top: -31px;
  transform: none;
  -o-transform: none;
  -ms-transform: none;
  -moz-transform: none;
  -webkit-transform: none;
  width: 32px;
  background-color: transparent; }

.bestseller-sidebar-blog .owl-nav > div:hover {
  color: #ff8400; }

.bestseller-sidebar-blog .owl-nav > div.owl-prev {
  left: auto;
  right: 30px; }

.bestseller-sidebar-blog .owl-nav > div.owl-next {
  right: 0; }

.bestseller-sidebar-blog .bestseller-text {
  width: 100%; }

.bestseller-list .bestseller-item {
  display: inline-block;
  margin: 10px 0;
  min-height: 130px;
  padding-left: 120px;
  padding-top: 15px;
  position: relative;
  width: 100%; }

.blog-content .title-left {
  margin: 0 0 7px;
  text-transform: uppercase; }

.page-desc {
  margin-bottom: 22px; }

.blog-box {
  margin-bottom: 30px; }

.blog-box figure {
  margin: 0; }

.blog-content-left .blog-box-title {
  font-weight: 400;
  margin: 5px 0 9px; }

.blog-content-left .blog-box-date {
  color: #888;
  font-weight: bold;
  margin-bottom: 10px; }

.blog-content-left .blog-box-date i {
  font-size: 15px;
  margin-right: 8px; }

.blog-content-left .blog-box-desc {
  text-align: justify;
  margin-bottom: 10px; }

.blog-content-left .blog-post-meta a {
  display: inline-block;
  float: left;
  padding-right: 10px;
  margin-right: 10px;
  border-right: 1px solid #666;
  color: #666666;
  height: 14px;
  line-height: 14px; }

.blog-content-left .blog-post-meta a:hover {
  color: #ff8400; }

.blog-content-left .blog-post-meta a:last-child {
  border: medium none;
  padding: 0;
  margin: 0; }

.blog-content-left .blog-post-meta a:first-child {
  color: #ff8400; }

.blog-box .post-media {
  position: relative;
  overflow: hidden; }

.blog-box .post-media a::before {
  color: white;
  content: "\f112";
  font-size: 30px;
  font-weight: 100;
  left: 50%;
  opacity: 0;
  font-family: 'Flaticon';
  position: absolute;
  text-align: center;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%) scale(0);
  -ms-transform: translateX(-50%) translateY(-50%) scale(0);
  -o-transform: translateX(-50%) translateY(-50%) scale(0);
  transform: translateX(-50%) translateY(-50%) scale(0);
  -webkit-transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.5s ease-in-out 0s;
  -ms-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
  vertical-align: middle;
  z-index: 1; }

.blog-box .post-media a::before:hover {
  color: #ff8400; }

.blog-box .post-media a::after {
  background-color: rgba(0, 0, 0, 0.3);
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s; }

.blog-box .post-media:hover a::before {
  opacity: 1;
  -webkit-transform: translateX(-50%) translateY(-50%) scale(1);
  -ms-transform: translateX(-50%) translateY(-50%) scale(1);
  -o-transform: translateX(-50%) translateY(-50%) scale(1);
  transform: translateX(-50%) translateY(-50%) scale(1); }

.blog-box .post-media:hover a::after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1); }

/* -------------------- */
/*      end blog        */
/* -------------------- */
/* -------------------- */
/*      blogpost        */
/* -------------------- */
.blog-post-link {
  display: inline-block;
  margin: 15px 0;
  width: 100%; }

.blog-post-link .blog-post-link li {
  border-right: 1px solid #333;
  display: inline-block;
  float: left;
  height: 12px;
  line-height: 12px;
  margin-right: 12px;
  padding-right: 12px; }

.blog-post-link .blog-post-link li:last-child {
  margin-right: 0;
  padding-right: 0;
  border: medium none; }

.blog-post-meta > li {
  border-right: 2px solid #a8a8a8;
  color: #666666;
  display: inline-block;
  font-size: 13px;
  line-height: 13px;
  margin-right: 12px;
  padding: 0 15px 0 0; }

.blog-post-meta > li:last-child {
  border-right: none; }

.blog-post-meta > li a {
  color: #666666; }

.blog-post-meta {
  margin-bottom: 15px; }

.blog-post .blog-box {
  margin-bottom: 0; }

.blog-post .blog-box figure {
  margin-bottom: 23px; }

.blog-post .blog-content-left {
  border-bottom: 1px solid #dddddd; }

.blog-post .show-page {
  color: #666666;
  display: inline-block;
  margin-bottom: 0;
  margin-top: 10px;
  text-align: right;
  width: 100%; }

.blog-post .show-page a {
  color: #666666; }

.blog-post .blog-box-title {
  margin-bottom: 5px; }

.comment-blog-post {
  background-color: white;
  border: 1px solid #dddddd;
  padding: 14px 10px;
  margin-top: 30px; }

.comment-blog-post .name-date {
  margin-bottom: 15px; }

.comment-blog-post .name-date a {
  font-weight: 600; }

.comment-blog-post .name-date span {
  color: #333333;
  float: right; }

.comment-blog-post .comment-text {
  margin-bottom: 40px; }

.comment-blog-post > a {
  border-bottom: 1px solid #ff8400;
  color: #ff8400; }

.comment-blog-form label {
  color: #333333;
  font-weight: 600;
  margin: 0;
  width: 100%; }

.comment-blog-form input, .comment-blog-form textarea {
  width: 100%;
  margin-bottom: 17px;
  margin-top: 3px;
  border-radius: 0; }

.comment-blog-form textarea {
  height: auto; }

.comment-blog-form .comment-blog-form input {
  max-width: 470px; }

.comment-blog-form .note {
  font-size: 10px;
  line-height: 10px;
  margin: -8px 0 19px; }

.comment-blog-form .submit-comment {
  margin: 10px 0 0; }

.comment-blog-form .title-left {
  margin-bottom: 15px; }

.comment-blog-form .input-captcha {
  max-width: 300px; }

/* -------------------- */
/*    end blogpost      */
/* -------------------- */
/* -------------------- */
/*       Footer         */
/* -------------------- */
.backtotop {
  bottom: 30px;
  display: none;
  font-size: 10px;
  height: 40px;
  letter-spacing: 2px;
  position: fixed;
  right: 30px;
  text-align: center;
  text-transform: uppercase;
  width: 40px;
  background: #ff8400;
  color: white;
  line-height: 40px;
  border-radius: 50%;
  z-index: 100;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s; }

.backtotop:hover {
  color: white; }

.backtotop:after {
  border: 2px solid #ff8400;
  content: "";
  position: absolute;
  top: -4px;
  left: -4px;
  bottom: -4px;
  right: -4px;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s; }

.backtotop:before {
  content: "";
  border: 2px solid transparent;
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 50%; }

.backtotop.ts-block {
  display: block; }

.backtotop i {
  display: inline-block;
  font-size: 15px;
  margin-bottom: 10px;
  top: -10px;
  width: 100%; }

.backtotop:hover::after {
  bottom: -6px;
  left: -6px;
  right: -6px;
  top: -6px; }

.backtotop span {
  display: inline-block;
  font-size: 10px;
  line-height: 14px; }

.backtotop:hover .ts-line-animate {
  height: 40px;
  opacity: 1; }

@keyframes line_animate {
  0% {
    bottom: 0;
    height: 0;
    opacity: 1; }

  10% {
    bottom: 20px;
    opacity: 0.8; }

  50% {
    bottom: 50px;
    height: 50px;
    opacity: 0.7; }

  90% {
    bottom: 60px;
    opacity: 0.4; }

  100% {
    bottom: 70px;
    height: 80px;
    opacity: 0; } }

@-webkit-keyframes line_animate {
  0% {
    bottom: 0;
    height: 0;
    opacity: 1; }

  10% {
    bottom: 20px;
    opacity: 0.8; }

  50% {
    bottom: 50px;
    height: 50px;
    opacity: 0.7; }

  90% {
    bottom: 60px;
    opacity: 0.4; }

  100% {
    bottom: 70px;
    height: 80px;
    opacity: 0; } }

@keyframes pulser {
  0% {
    transform: scale(1.1); }

  50% {
    transform: scale(0.8); }

  100% {
    transform: scale(1); } }

@-webkit-keyframes pulser {
  0% {
    -webkit-transform: scale(1.1); }

  50% {
    -webkit-transform: scale(0.8); }

  100% {
    -webkit-transform: scale(1); } }

footer hr {
  border-color: #dddddd;
  margin: 0; }

.footer-top {
  border-bottom: 1px solid #dddddd;
  padding: 30px 0 40px; }

.footer-top .footer-top-logo {
  display: inline-block;
  margin-bottom: 20px; }

.footer-top .footer-top-title {
  display: inline-block;
  margin-bottom: 35px; }

.footer-top .footer-top-title .title-footer {
  margin-top: 35px;
  text-transform: uppercase; }

.footer-top .footer-infomation p {
  margin-bottom: 10px;
  padding-left: 70px;
  position: relative; }

.footer-top .footer-infomation p span {
  color: #333333;
  font-size: 16px;
  font-weight: 600;
  left: 0;
  position: absolute;
  top: 0; }

.footer-top .footer-infomation p a {
  color: #666666; }

.footer-top .footer-infomation p a:hover {
  color: #ff8400; }

.footer-top .footer-item {
  margin-bottom: 2px; }

.footer-top .footer-item a {
  color: #666666; }

.footer-top .footer-item a:hover {
  color: #ff8400; }

.footer-top .footer-tags li {
  display: inline-block;
  margin: 5px 2px 0px 0; }

.footer-top .footer-tags li a {
  border: 1px solid #dddddd;
  line-height: 30px;
  padding: 0 6px;
  color: #666666;
  display: inline-block; }

.footer-top .footer-tags li:hover a {
  border: 1px solid #ff8400;
  background-color: #ff8400;
  color: white; }

.footer-mid {
  padding: 28px 0; }

.footer-mid ul {
  display: inline-block;
  padding: 0 50px;
  width: 100%;
  margin: 10px 0; }

.footer-mid ul li {
  display: inline-block;
  float: left; }

.footer-mid ul li a {
  display: inline-block;
  position: relative; }

.footer-mid ul li a::before {
  background-color: rgba(255, 132, 0, 0);
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0; }

.footer-mid ul li a:hover {
  opacity: 0.7; }

.footer-mid .footer-mid-text {
  display: inline-block;
  margin-bottom: 15px;
  position: relative;
  padding-left: 29px; }

.footer-mid .footer-mid-text figure {
  position: absolute;
  left: 0;
  top: 0; }

.footer-mid .footer-mid-text p {
  color: #333333;
  display: inline-block;
  margin: 0;
  margin-top: 5px; }

.footer-mid .footer-mid-text p span {
  color: #ff8400; }

.footer-bottom {
  text-align: center; }

.footer-bottom .menu-footer-bottom li {
  display: inline-block;
  margin-bottom: 2px; }

.footer-bottom .menu-footer-bottom li a {
  border-left: 2px solid #dddddd;
  display: inline-block;
  line-height: 17px;
  padding: 0 7px; }

.footer-bottom .menu-footer-bottom li:first-child a {
  padding-left: 0;
  border: medium none; }

.footer-bottom .copyright {
  color: #333333;
  margin: 35px 0; }

/* -------------------- */
/*     end Footer       */
/* -------------------- */
/* -------------------- */
/*      responsive      */
/* -------------------- */
.site-navigation.mm-menu {
  display: block;
  max-width: 400px;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.5s ease-in-out 0s;
  -ms-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
  z-index: 99; }

.menu-item.mm-menu {
  display: block;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.5s ease-in-out 0s;
  -ms-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
  z-index: 99; }

.mm-menu.mm-offcanvas.mm-current {
  display: block;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0); }

.mm-menu.mm-pageshadow::after {
  display: none; }

html.mm-blocking #mm-blocker {
  display: block;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0); }

body #mm-blocker {
  background: rgba(0, 0, 0, 0.6) none repeat scroll 0 0;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.5s ease-in-out 0s;
  -ms-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s; }

.categories-element .menu-button, .categories-element #categories-menu {
  display: none; }

@media (min-width: 1200px) {
  .slide-fullwidth .owl-nav {
    width: 1170px;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%); }

  .slide-fullwidth .owl-nav > div.owl-prev {
    left: 280px; }

  .slide-fullwidth .owl-dots {
    margin-left: 115px; } }
@media (min-width: 992px) {
  .categories-grid .breadcrumb-sidebar, .categories-list .breadcrumb-sidebar {
    display: none; }

  /*tab-pane-slide cols*/
  div .tab-pane-slide.slide-5cols .product-list-content {
    width: 20%; }

  div .tab-pane-slide.slide-5cols .product-list-content:nth-child(5n) {
    border-right: none; }

  div .tab-pane-slide.slide-4cols .product-list-content {
    width: 25%; }

  div .tab-pane-slide.slide-4cols .product-list-content:nth-child(4n) {
    border-right: none; }

  div .tab-pane-slide.slide-3cols .product-list-content {
    width: 33.33%; }

  div .tab-pane-slide.slide-3cols .product-list-content:nth-child(3n) {
    border-right: none; }

  div .tab-pane-slide.slide-2cols .product-list-content {
    width: 50%; }

  div .tab-pane-slide.slide-2cols .product-list-content:nth-child(2n) {
    border-right: none; } }
@media (min-width: 1025px) {
  .banner-home3 .owl-nav > div.owl-prev {
    left: 275px; }

  .product-thumbnail {
    width: 300px; }

  .product-edit {
    width: 100px; }

  .menu-button {
    display: none; }

  .category-drop-list .category-drop-list-inner > .sub-menu {
    height: calc(100% - 30px);
    overflow: hidden;
    padding-bottom: 30px; }

  .category-drop-list.category-show-more .category-drop-list-inner > .sub-menu {
    height: auto; }

  .category-show-more .category-drop-list-inner {
    height: auto !important; }

  .has-mega-menu {
    line-height: 43px;
    float: left;
    padding: 0;
    position: relative;
    background-color: #3e3e3e;
    width: 220px; }

  .has-mega-menu a {
    color: white;
    text-transform: uppercase;
    font-weight: 600; }

  .has-mega-menu li {
    width: 100%; }

  .has-mega-menu > a {
    font-size: 16px;
    font-weight: 600;
    margin-left: 14px; }

  .has-mega-menu .category-drop-list {
    background: white none repeat scroll 0 0;
    display: block;
    left: 0;
    padding: 5px 0 45px;
    top: 100%;
    width: 100%;
    opacity: 1;
    position: absolute;
    z-index: 23; }

  .has-mega-menu .category-drop-list.sub-menu-open {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: all 0.6s ease-in-out 0s;
    -moz-transition: all 0.6s ease-in-out 0s;
    -ms-transition: all 0.6s ease-in-out 0s;
    -o-transition: all 0.6s ease-in-out 0s;
    transition: all 0.6s ease-in-out 0s;
    z-index: 5; }

  .has-mega-menu .category-drop-list.sub-menu-open.category-drop-list-show {
    height: auto;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s; }

  .has-mega-menu .category-drop-list > .sub-menu {
    display: inline-block;
    height: 390px;
    overflow: hidden;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s; }

  .has-mega-menu .category-drop-list > .sub-menu.show {
    height: auto; }

  .has-mega-menu .category-drop-list li {
    display: inline-block;
    float: left;
    line-height: 30px;
    margin: 0;
    padding: 0 15px;
    width: 100%; }

  .has-mega-menu .category-drop-list li li {
    line-height: 24px; }

  .has-mega-menu .category-drop-list li li a {
    color: #666666; }

  .has-mega-menu .category-drop-list li li a:hover {
    color: #ff8400; }

  .has-mega-menu .category-drop-list li a {
    color: #333333;
    text-transform: capitalize;
    font-weight: 400; }

  .has-mega-menu .category-drop-list li a:hover {
    color: #ff8400; }

  .has-mega-menu .category-drop-list li ul li {
    padding: 0; }

  .has-mega-menu .category-drop-list > .more-categories {
    background-color: #ff8400;
    color: white;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    line-height: 37px;
    padding: 0 12px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0; }

  .has-mega-menu .category-drop-list > .more-categories.show-before {
    color: transparent; }

  .has-mega-menu .category-drop-list > .more-categories.show-before::before {
    position: absolute;
    content: 'Close';
    font-size: 14px;
    color: white;
    top: 0;
    left: 10px; }

  .has-mega-menu .category-drop-list > .more-categories:hover {
    background-color: #3e3e3e; }

  .has-mega-menu .click-categories {
    color: white;
    float: right;
    font-size: 18px;
    padding: 0 20px;
    cursor: pointer; }

  .sub-menu.mega-menu {
    left: 100%;
    padding: 0;
    top: 0;
    border: 1px solid #dddddd;
    background-color: white; }

  .sub-menu.mega-menu .row {
    margin: 0; }

  .sub-menu.mega-menu .mega-content {
    border-bottom: 1px solid #dddddd;
    display: inline-block;
    margin: 0 10px 23px 15px;
    padding: 9px 0 35px;
    width: 95%; }

  .sub-menu.mega-menu .mega-content:last-child {
    border: medium none;
    margin-bottom: 0;
    padding-bottom: 0; }

  .sub-menu.mega-menu .mega-item-title {
    color: #333333;
    font-size: 14px;
    font-weight: 600;
    margin: 10px 0; }

  .sub-menu.mega-menu .menu li {
    margin: 0;
    line-height: 24px;
    padding: 0; }

  .has-mega-menu .has-mega {
    margin: 0;
    padding: 0 12px 6px; }

  .has-mega-menu .has-mega .mega-menu {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(50px);
    -ms-transform: translateY(50px);
    -o-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    position: absolute; }

  .has-mega-menu .has-mega:hover .mega-menu {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: all 0.6s ease-in-out 0s;
    -moz-transition: all 0.6s ease-in-out 0s;
    -ms-transition: all 0.6s ease-in-out 0s;
    -o-transition: all 0.6s ease-in-out 0s;
    transition: all 0.6s ease-in-out 0s;
    z-index: 5; } }
.img-mega {
  position: relative;
  margin-right: -15px; }

.img-mega figure {
  margin: 0; }

.img-mega .img-mega-asblute {
  left: 15px;
  position: absolute;
  top: 58px; }

.img-mega .img-mega-asblute p, .img-mega .img-mega-asblute h3 {
  color: white;
  margin: 0; }

.img-mega .img-mega-asblute .button {
  font-weight: 600;
  margin-top: 25px;
  color: white; }

@media (max-width: 1024px) {
  .banner-slide-with-cate .padding-left5 {
    width: 100%; }

  div .slide-item2, div .slide-item3, div .slide-item1 {
    height: 300px;
    background-position: center center; }

  .banner-home1-content.slide2 {
    background-position: right center; }

  div.banner-home2 .banner-home2-left {
    float: left;
    overflow: hidden;
    width: 100%; }

  div.host-deals-slide .hot-deal-count {
    padding: 20px 0 0 60px; }

  div.banner-home2 .banner-home2-right {
    float: left;
    margin-left: 0;
    width: 100%; }

  .blog-box .post-media a {
    display: inline-block;
    overflow: hidden;
    position: relative; }

  .banner .owl-nav {
    display: none; }

  .product-tabs-style4 .owl-nav {
    display: none; }

  .banner-text img {
    width: 100%;
    height: auto; }

  body footer hr {
    margin: 0 -20px; }

  .category-slider-wrap .catergory-item .link-cat {
    margin-left: 20px; }

  .better-sales-slide .better-sale-item:last-child {
    display: none; }

  header nav {
    display: none; }

  header.header-style4 .category-drop-list-inner {
    height: auto;
    padding-bottom: 60px; }

  div.item-slide-content3.item-slide-content, div.item-slide-content3.item-slide-content.slide3 {
    left: 60px;
    top: 20px; }

  body .header-style5 .menu-button.primary-navigation-button {
    display: table;
    margin: 0 auto;
    float: none; }

  body .banner-slide-with-cate .col-sm-3 {
    width: 100%; }

  body div.banner-slide-with-cate .menu-button {
    display: table;
    float: none;
    margin: 0 auto 20px; }

  body nav#categories-menu {
    display: none; }

  body .banner-slide-with-cate .menu-button {
    float: none;
    margin-bottom: 20px;
    display: inline-block; }

  body .more-categories {
    display: none; }

  body .mm-listview > li > a > i {
    margin-right: 10px;
    font-size: 20px; }

  body .menu-primary {
    background-color: transparent; }

  body .menu-button {
    font-weight: 600;
    text-transform: uppercase;
    background-color: #ff8400;
    color: white;
    padding: 10px 10px;
    float: left;
    font-size: 15px;
    width: 135px; }

  body .menu-button.primary-navigation-button {
    float: right; }

  body .menu-button.primary-navigation-button span {
    margin-right: 10px; }

  body .menu-button.categories-menu-button span {
    margin-left: 10px; }

  body #mm-categories-menu {
    z-index: 15;
    overflow: hidden; }

  body #mm-categories-menu .mm-panels > .mm-panel {
    width: 100% !important; }

  body #mm-categories-menu .click-categories {
    display: none; }

  body #mm-categories-menu .mega-item-title {
    color: #333333;
    font-weight: 600; }

  body .mm-menu .mm-listview {
    margin-bottom: 30px; }

  body .more-categories {
    line-height: 30px;
    padding: 5px 20px;
    color: white;
    background-color: #ff8400; }

  body a.mm-close::before {
    content: "\f057";
    font-family: "FontAwesome";
    transform: none;
    color: #333333;
    font-size: 20px;
    top: -17px;
    right: 25px; }

  body .mm-menu .mm-close::after, body .mm-menu .mm-close::before {
    border-color: transparent; }

  body #mm-blocker {
    background: rgba(0, 0, 0, 0.2) none repeat scroll 0 0; }

  body .mm-menu.mm-offcanvas.mm-right {
    z-index: 9; }

  body .mega-menu .col-md-3 {
    width: 100%; }

  body #mm-primary-navigation {
    overflow: hidden; }

  body .method-content-right {
    padding-right: 0; }

  body .product-list-content .product-item-actions li {
    opacity: 1;
    -webkit-transform: translateX(0) translateY(0);
    -ms-transform: translateX(0) translateY(0);
    -o-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0); } }
@media (max-width: 1205px) {
  body .footer-mid ul {
    padding: 0; }

  body .footer-mid ul li {
    margin: 5px;
    float: inherit; } }
@media (max-width: 563px) {
  body.home1 .header-top {
    padding: 0; }

  body.home1 .header-top .container {
    padding: 30px 0;
    background-color: rgba(0, 0, 0, 0.5); }

  body .categories-list div.product-box-content .img-product, body .categories-list div.product-box-content .product-box-text {
    width: 100%; } }
@media (min-width: 481px) and (max-width: 767px) {
  body .product-list .product-list-content {
    width: 50%; }

  body .about-bottom-box .col-md-3 {
    float: left;
    width: 50%; }

  body .about-box .about-desc {
    margin: 15px -2px 0; }

  body .categories-grid div.product-box {
    width: 50%; }

  body .select-categories > div {
    display: inline-block;
    margin: 10px 0 0; }

  body .select-categories > div:last-child {
    text-align: right; }

  body .header-search {
    margin: 17px 0 0; }

  body .header-search input {
    width: 230px; }

  body .banner-asblute li {
    width: 50%;
    float: left; }

  body div .product-tabs-h3 .supermartket-owl-carousel div.product-list-content {
    width: 50%; }

  body header div.header-mid-right {
    display: table;
    float: none;
    padding: 0;
    text-align: center;
    width: auto;
    margin: 10px auto; }

  body header.header .header-mid-right div.header-mid-right-content {
    display: inline-block;
    float: left;
    margin: 0 10px 0 0; }

  .list-banner .banner-item {
    background-color: white; }

  .list-banner .banner-item {
    background-position: right center;
    background-repeat: no-repeat;
    margin: 20px 5%;
    width: 90%;
    background-color: white; } }
@media (min-width: 563px) and (max-width: 991px) {
  .div-icon-box .col-md-3 {
    width: 50%;
    float: left; }

  body .categories-list div.product-box-content .img-product {
    width: 50%; }

  body .categories-list div.product-box-content .product-box-text {
    width: 50%;
    padding: 0 15px;
    margin-bottom: 20px; } }
@media (max-width: 1200px) {
  .header-style6 .form-search-header input {
    padding: 0 15px;
    width: auto;
    color: #888;
    margin-top: 0;
    line-height: 40px;
    height: 39px; } }
@media (max-width: 1024px) {
  body header .menu-primary {
    border: 1px solid #dddddd;
    padding: 10px;
    margin-bottom: 30px;
    background: #f5f5f5; } }
@media (max-width: 991px) {
  .header-style6 .header-search {
    margin: 0 auto; }

  body header .header-search {
    margin: 17px auto;
    display: table;
    float: none; }

  body header h1.logo {
    display: inline-block;
    line-height: 25px;
    text-align: center;
    width: 100%;
    margin-bottom: 20px; }

  body header .header-bottom-right {
    display: table;
    width: auto;
    float: none;
    margin: 20px auto 0; }

  body header .banner-absolutes .banner-absolute {
    border: 1px solid #f3f3f3;
    display: inline-block;
    float: left;
    padding: 20px 10px;
    position: relative;
    width: 100%;
    z-index: 5; }

  .banner-absolutes .banner-absolute {
    display: inline-block;
    position: relative;
    top: 0;
    width: 100%;
    padding: 8px 0; }

  .icon-box-content {
    margin-top: 20px; }

  .newsletter-form-wrap {
    margin-bottom: 30px; }

  .banner-text {
    margin-bottom: 30px; }

  .list-banner .banner-item {
    margin: 20px 5%;
    width: 90%;
    background-color: white;
    background-position: right center;
    background-repeat: no-repeat; }

  .style4.icon-box {
    width: auto; } }
@media (max-width: 767px) {
  .header-style1 .header-top {
    display: none; }

  div.menu-primary.menu-fixed {
    position: inherit; }

  .form-search-header select {
    background-position: 95% center !important; }

  div.slide-product-with-thumb .image-media {
    top: 2px; }

  .money-dropdown > .money {
    width: 100%;
    text-align: left; }

  .tabs-slide.product-tabs .owl-nav {
    display: none; }

  body .nav.nav-pills li {
    text-align: center; }

  body .mm-listview .mm-next::before {
    border-left-width: 0; }

  body header div.header-search {
    margin: 17px auto 30px; }

  body .icon-box {
    width: 90%; }

  div.banner-slide:hover .owl-controls {
    display: none; }

  .banner-home .owl-nav > div {
    display: none; }

  body header.header-style3 div .form-search-header input {
    height: 46px; }

  header.header-style3 .cart-menu .cart-amount {
    top: -20px; }

  .product-tabs .owl-nav > div {
    display: none; }

  body header.header-style3 div.cart-menu-relative .cart-hover {
    width: 90%;
    margin: 0 5%; }

  .product-tabs .owl-nav {
    display: none; }

  .banner-home4 .owl-nav > div {
    display: none; }

  header.header-style4 .header-bottom-center {
    padding-left: 15px; }

  body header.header-style4 div.cart-menu-relative .cart-hover {
    width: 90%;
    margin: 0 5%; }

  body header.header-style5 div.cart-menu-relative .cart-hover {
    width: 90%;
    margin: 0 5%; }

  body header.header-style6 div.cart-menu-relative .cart-hover {
    width: 90%;
    margin: 0 5%; }

  header.header-style4 .cart-menu .cart-amount {
    top: -22px; }

  .product-tabs-style3 .product-list-content {
    margin-bottom: -1px;
    border-bottom: 1px solid #dddddd; }

  .style2.icon-box {
    width: auto; }

  body header .header-mid-left {
    display: inline-block;
    float: left;
    font-size: 13px;
    margin-top: 3px;
    width: 100%;
    text-align: center; }

  body header .header-mid-left p {
    float: none;
    display: inline-block; }

  body header .header-mid-right {
    display: inline-block;
    float: right;
    text-align: center;
    width: 100%;
    padding: 0; }

  body header .header-mid-right .header-mid-right-content {
    display: inline-block;
    float: none; }

  body header .header-mid-right .country-select-menu {
    margin: 0; }

  body header h1.logo {
    display: inline-block;
    line-height: 25px;
    width: 100%;
    text-align: center; }

  body header .form-search-header {
    border: medium none;
    margin: 0 10px;
    position: relative;
    padding: 0;
    width: auto; }

  body header .form-search-header select {
    left: 0;
    padding: 0 0 0 20px;
    position: relative;
    top: 0;
    width: 100%;
    border: 1px solid #dddddd;
    background-position: 95% 50%;
    margin-bottom: 10px;
    min-width: 230px; }

  body header .form-search-header input {
    border: 1px solid #dddddd;
    padding: 0 25px;
    width: 86%;
    margin-top: 0;
    line-height: 38px;
    height: 38px; }

  body header .form-search-header .button-search {
    background-color: #ff8400;
    border: medium none;
    border-radius: 0 3px 3px 0;
    color: white;
    font-size: 20px;
    font-weight: bold;
    line-height: 38px;
    height: 38px;
    margin: 0;
    text-align: center;
    width: 14%; }

  body header .header-mid-left {
    margin-bottom: 5px; }

  body header .link-top {
    position: relative; }

  body header .header-bottom {
    position: relative; }

  body header .cart-menu-relative {
    position: static; }

  body header .cart-menu-relative .cart-hover {
    width: 100%; }

  body header.header-style2 .header-mid-right div.country-select-menu i {
    line-height: 12px;
    top: 29%;
    width: 20px; }

  body header .header-style3 .cart-menu .cart-amount {
    top: -16px; }

  body header.header-style3 .header-bottom-right > div {
    color: #333333;
    float: left;
    font-size: 16px;
    text-align: center;
    width: 50%; }

  body header.header-style3 .header-bottom-right > div.my-supermarket {
    width: 100%;
    margin-bottom: 20px; }

  body header.header-style3 .form-search-header {
    padding: 0; }

  body header.header-style3 .form-search-header::before {
    display: none; }

  body header.header-style3 .header-search {
    margin: 0 auto;
    max-width: 600px;
    min-width: 300px; }

  body header.header-style3 .form-search-header input {
    width: 90%;
    height: 44px;
    line-height: 44px; }

  body header.header-style3 .form-search-header .button-search {
    width: 10%;
    margin: 0;
    float: left;
    height: 46px;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0; }

  body header.header-style3 .form-search-header select {
    background-position: 96% center;
    margin-top: 10px;
    right: 0;
    padding: 0 0 0 20px;
    border-right: 1px solid #dddddd;
    width: 100%;
    max-width: inherit; }

  body header.header-style3 .header-bottom-right > div.my-supermarket {
    margin-bottom: 20px;
    margin-left: 0;
    margin-right: 0;
    position: static; }

  body header.header-style3 .header-bottom-right > div.my-supermarket .hover-supermarket {
    width: 100%; }

  body header.header-style3 .cart-menu-relative {
    position: static; }

  body header.header-style3 .cart-menu-relative .cart-hover {
    width: 100%; }

  body header.header-style3 .menu-primary {
    background-color: #f5f5f5; }

  body header.header-style4 .categories-filter {
    padding-bottom: 7px;
    position: static;
    text-align: left;
    display: table;
    margin: 0 auto; }

  body header.header-style4 .categories-filter .category-drop-list {
    left: 3%;
    width: 94%;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden; }

  body header.header-style4 .header-mid .header-mid-right {
    display: table;
    float: none; }

  body header.header-style4 .header-bottom-right {
    width: 100%; }

  body header.header-style4 .header-bottom-right > div {
    color: #333333;
    float: left;
    font-size: 16px;
    text-align: center;
    width: 50%; }

  body header.header-style4 .header-bottom-right > div.my-supermarket {
    width: 50%;
    margin-bottom: 20px; }

  body header.header-style4 .form-search-header {
    padding: 0;
    margin: 10px auto;
    display: table;
    background: 0; }

  body header.header-style4 .form-search-header::before {
    display: none; }

  body header.header-style4 .header-search {
    margin: 0 auto;
    max-width: 600px;
    min-width: 300px;
    width: 90%; }

  body header.header-style4 .form-search-header input {
    width: 84%;
    height: 44px;
    border-radius: 0;
    line-height: 44px; }

  body header.header-style4 .form-search-header .button-search {
    width: 16%;
    margin: 0;
    height: 44px;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0; }

  body header.header-style4 .form-search-header select {
    background-position: 96% center;
    border-right: 1px solid #dddddd;
    margin-top: 10px;
    padding: 0 0 0 20px;
    right: 0;
    width: 100%;
    background-color: white;
    line-height: 40px;
    height: 40px; }

  body header.header-style4 .header-bottom-right > div.my-supermarket {
    margin-bottom: 20px;
    margin-left: 0;
    margin-right: 0;
    position: static; }

  body header.header-style4 .header-bottom-right > div.my-supermarket .hover-supermarket {
    width: 100%; }

  body header.header-style4 .cart-menu-relative {
    position: static; }

  body header.header-style4 .cart-menu-relative .cart-hover {
    width: 100%; }

  body header.header-style4 div.categories-filter .category-drop-list .sub-menu > li {
    position: relative; }

  body header.header-style4 div.categories-filter .category-drop-list .sub-menu > li.sub-menu-open > a {
    color: #ff8400; }

  body header.header-style4 div.categories-filter .category-drop-list .sub-menu > li.sub-menu-open > .show-submenu {
    color: #ff8400; }

  body header.header-style4 div.categories-filter .category-drop-list .sub-menu .sub-menu {
    opacity: 1;
    padding: 0 20px;
    position: relative;
    visibility: visible;
    width: 100% !important;
    display: none; }

  body header.header-style4 div.categories-filter .category-drop-list .sub-menu .sub-menu .mega-item-title {
    color: #333333;
    font-size: 14px;
    margin: 20px 0; }

  body header.header-style4 div.categories-filter .category-drop-list .sub-menu .sub-menu .img-mega {
    margin-top: 20px;
    margin-bottom: 20px; }

  body header.header-style4 div.categories-filter .show-submenu {
    color: #333333;
    float: right;
    position: absolute;
    right: 15px;
    text-align: right;
    top: 0;
    width: 50px; }

  body header.header-style4 div.categories-filter .category-drop-list-inner {
    height: auto; }

  body header.header-style4 div.categories-filter .more-categories {
    position: relative;
    width: 100%;
    display: none;
    margin-top: 20px; }

  body header .banner-home4 .owl-nav {
    display: none; }

  body header .slide-product-with-thumb .image-media {
    top: 2px; }

  body header .header-mid-right-content:nth-child(2n+1)::before {
    display: none; }

  body header.header-style5 .header-mid .header-mid-right {
    display: table;
    float: none; }

  body header.header-style5 .header-bottom-left {
    display: inline-block;
    width: 100%; }

  body header.header-style5 .header-mid-right div.country-select-menu i {
    line-height: 17px;
    right: 0; }

  body header.header-style5 .header-bottom-right {
    width: 100%; }

  body header.header-style5 .header-bottom-right > div {
    color: #333333;
    float: left;
    font-size: 16px;
    text-align: center;
    width: 50%; }

  body header.header-style5 .header-bottom-right > div.my-supermarket {
    width: 50%;
    margin-bottom: 20px; }

  body header.header-style5 .form-search-header {
    padding: 0;
    margin: 10px auto;
    display: table;
    background: transparent;
    border: none; }

  body header.header-style5 .form-search-header::before {
    display: none; }

  body header.header-style5 .header-search {
    margin: 0 auto;
    max-width: 600px;
    min-width: 300px;
    width: 90%; }

  body header.header-style5 .form-search-header input {
    width: 75%;
    height: 43px;
    border-radius: 0;
    line-height: 43px; }

  body header.header-style5 .form-search-header .button-search {
    width: 25%;
    margin: 0;
    line-height: 43px;
    position: absolute;
    height: 43px;
    top: 0;
    right: 0; }

  body header.header-style5 .form-search-header select {
    background-position: 96% center;
    border-right: 1px solid #dddddd;
    margin-top: 10px;
    padding: 0 0 0 20px;
    right: 0;
    width: 100%;
    background-color: white;
    line-height: 40px;
    height: 40px; }

  body header.header-style5 div.header-mid-right div.header-mid-right-content {
    display: inline-block;
    float: left;
    margin: -1px;
    padding: 0;
    text-align: center;
    width: 50%; }

  body header.header-style5 div.header-mid-right div.header-mid-right-content > div {
    width: 120px;
    margin: 0 auto;
    display: table;
    float: none; }

  body header.header-style5 .header-bottom-right > div.my-supermarket {
    margin-bottom: 20px;
    margin-left: 0;
    margin-right: 0;
    position: static; }

  body header.header-style5 .header-bottom-right > div.my-supermarket .hover-supermarket {
    width: 94%;
    right: 3%; }

  body header.header-style5 .cart-menu-relative {
    position: static; }

  body header.header-style5 .cart-menu-relative .cart-hover {
    width: 94%;
    right: 3%; }

  body header.header-style6 .header-mid-right .header-mid-right-content {
    margin: 0 10px; }

  body header.header-style6 .menu-primary {
    background-color: #f5f5f5; }

  body header.header-style6 .header-mid .header-mid-right {
    display: table;
    float: none; }

  body header.header-style6 .header-mid-right div.country-select-menu i {
    line-height: 17px;
    right: 0; }

  body header.header-style6 .header-search {
    margin: 0 auto; }

  body header.header-style6 .form-search-header {
    border: none; }

  body header.header-style6 .form-search-header input {
    width: 84%;
    height: 41px; }

  body header.header-style6 .form-search-header .button-search {
    width: 16%;
    margin: 0;
    height: 41px;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0; }

  body header.header-style6 .form-search-header select {
    background-position: 94% 50%;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    height: 40px;
    line-height: 40px; }

  body header.header-style6 .header-bottom {
    padding: 20px 0 0;
    position: relative; }

  body header.header-style6 .header-bottom-right > div.my-supermarket {
    margin-bottom: 20px;
    margin-left: 0;
    margin-right: 0;
    position: static; }

  body header.header-style6 .header-bottom-right > div.my-supermarket .hover-supermarket {
    width: 100%; }

  body header.header-style6 .cart-menu-relative {
    position: static; }

  body header.header-style6 .cart-menu-relative .cart-hover {
    width: 100%; }

  body header.header-style6 .cart-menu .cart-amount {
    top: -22px; }

  body header .money.select, body header .country.select {
    float: none; }

  body header .country-dropdown .country {
    width: 100%;
    text-align: left; }

  body header .cart-menu .cart-amount {
    left: 18px;
    top: -20px; }

  .banner-home2-left .owl-nav {
    display: none; }

  div div.item-slide-content6.item-slide-content {
    top: 50px;
    left: 20px;
    right: auto;
    padding: 0; }

  div.item-slide-content6.item-slide-content {
    padding-right: 20px; }

  div.item-slide-content6.slide2 .slide-title {
    font-size: 27px;
    padding: 0; }

  div.item-slide-content6.slide3.item-slide-content .slide-title {
    font-size: 27px;
    padding-left: 0; }

  .main-content {
    padding: 0 10px; }

  footer {
    padding: 0 10px; }

  .product-tabs-style3 div.product-list-content {
    margin-bottom: 0;
    width: 100%;
    border-bottom: none; }

  body .icon-box.style1 {
    text-align: center; }

  body .style1 .icon-box-content {
    margin-top: 20px; }

  body .icon-box {
    display: table;
    margin: 0 auto 30px; }

  body .style5.icon-box {
    border-width: 0; }

  body img {
    height: auto; }

  body .banner-text {
    margin-bottom: 20px; }

  body .product-media {
    text-align: center; }

  body .header-mid-right .header-mid-right-content a > i {
    margin: 0 5px 0 10px; }

  body .nav.nav-pills {
    width: 100%;
    margin-top: -1px; }

  body .nav.nav-pills li {
    padding: 0 15px; }

  body .product-tabs {
    padding-top: 50px; }

  body .banner-asblute {
    display: none; }

  body .banner-home1-content {
    background-position: 57% 20%;
    position: relative; }

  body .banner-home1-content .container {
    padding: 115px 0 0 45px !important; }

  body .banner-home1-content:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.3); }

  body .banner-home1 .owl-nav > div.owl-prev {
    left: 10px !important; }

  body .banner-home1 .owl-nav > div.owl-next {
    right: 10px !important; }

  body .left-tab {
    text-align: center;
    margin-bottom: 20px; }

  body .left-tab figure {
    display: inline-block; }

  body .thumbs > a {
    padding: 0;
    width: 85%;
    border: none !important; }

  body .col-haft {
    width: 100%; }

  body .brand-sale-box .sale-time {
    margin-top: 15px; }

  body .brand-sale-box .sale-time > i {
    font-size: 20px;
    width: 20px; }

  body .deals-of-countdown {
    font-size: 13px; }

  body .brand-sale-box .sale-time .block-title .box-count {
    padding-right: 8px; }

  body .brand-sale-box .sale-time .block-title .box-count:last-child {
    padding-right: 0; }

  body .brand-sale-box .sale-time .block-title .box-count::before {
    right: 2px; }

  body .brand-sale-box .sale-time .orange {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px; }

  body .logo-slide .owl-item {
    text-align: center; }

  body .about-box {
    margin-bottom: 30px;
    text-align: center; }

  body .about-top1 a, body .about-top1 a img {
    width: 100%; }

  body .blog-box figure {
    margin-bottom: 15px; }

  body .blog-page .sidebar {
    display: inline-block;
    width: 100%; }

  body.blog-page .sidebar-title {
    margin: 30px 0 10px; }

  body .contact-form .contact-form-top {
    padding-right: 0; }

  body .contact-form a.button {
    margin-bottom: 25px; }

  body .div-icon-border {
    border-right: medium none;
    border-bottom: 1px solid #dddddd;
    padding-bottom: 15px; }

  body .div-icon-box .title-left {
    margin-bottom: 15px;
    margin-top: 15px; }

  body .categories-list > .container {
    padding-top: 55px; }

  body.categories-page .panel-categories {
    position: relative; }

  body.categories-page .panel-categories .col-md-3 {
    position: inherit; }

  body.categories-page .panel-categories .page-nav {
    position: absolute;
    right: -9px;
    top: 8px;
    width: 160px; }

  body .categories-list1 .sidebar, body .categories-grid1 .sidebar {
    margin-top: 0; }

  body .categories-list1 .content, body .categories-grid1 .content {
    margin-top: 55px; }

  body .categories-list1 .breadcrumb-sidebar p, body .categories-grid1 .breadcrumb-sidebar p {
    padding: 2px 14px 3.5px; }

  body .single-product-content {
    padding: 20px; }

  body .detail-slide.upsales {
    margin-bottom: 30px; }

  body .product-detail .product-box .social > div {
    float: left;
    margin-right: 20px;
    width: auto; }

  body.product-detail .featue-slide {
    margin: 0 auto; }

  body.product-detail .detail-products .nav.nav-pills {
    padding: 0;
    width: 100%; }

  body.product-detail .detail-products .nav.nav-pills li {
    margin-bottom: 0; }

  body.product-detail .detail-products .product-tabs-content {
    width: 100%; }

  body.product-detail .detail-products::before {
    background-color: transparent; }

  body .shop_table.cart th {
    display: none; }

  body .shop_table.cart td {
    display: block;
    padding-left: 100px;
    position: relative; }

  body .shop_table.cart td::before {
    border-right: 1px solid #dddddd;
    bottom: 0;
    content: attr(data-title);
    left: 0;
    line-height: 40px;
    padding-left: 5px;
    position: absolute;
    top: 0;
    width: 100px; }

  body .shoping-form .button-cart a {
    float: left;
    margin: 0 10px 10px 0;
    padding: 0 14px;
    width: auto; }

  body .shoping-form .button-check-out {
    float: left; }

  body.home3 .banner-home3 .owl-item img {
    height: auto; }

  body.home3 .icon-box-h3 {
    margin-bottom: 20px; }

  body.home3 .nav.nav-pills {
    padding: 0;
    width: 100%; }

  body.home3 .nav.nav-pills li {
    margin: 0; }

  body.home3 .nav.nav-pills li a {
    display: inline-block;
    padding: 20px 0; }

  body.home3 .nav.nav-pills li a img {
    height: auto; }

  body.home3 .tab-home3-content {
    width: 100%;
    text-align: center; }

  body.home3 .tab-home3-content:hover {
    width: 50%;
    margin-left: 10px; }

  body.home3 .product-tabs-h3 .left-tab {
    width: 100%; }

  body.home3 .product-tabs-h3 .right-tab {
    width: 100%; }

  body.home3 .product-tabs-h3 .right-tab li {
    width: 100%;
    padding: 5px 0; }

  body.home3 .product-tabs-h3 .right-tab li a {
    padding: 0; }

  body div.banner-home3-content .slide-h3-3-content {
    left: 20px;
    position: absolute;
    top: 20px; }

  body .item-slide-content {
    left: 20px;
    position: absolute;
    top: 20px; }

  body .item-slide-content .slide-title {
    font-size: 18px;
    margin-bottom: 5px;
    text-transform: uppercase;
    margin-bottom: 20px; }

  body .item-slide-content .sile-desc {
    color: #888888;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 10px; }

  body .item-slide-content.slide2 {
    left: 20px;
    top: 20px; }

  body .header-style3 .header-top {
    padding: 10px 10px 30px; }

  div.slide-bx.slide-product-with-thumb .thumbs > a {
    margin: 0px 0 -1px 0; }

  div.slide-bx.slide-product-with-thumb .thumbs > a::before {
    content: none; }

  div.banner-home3-content .slide-h3-content {
    left: 20px;
    position: absolute;
    top: 20px; }

  div.item-slide-content3.item-slide-content {
    top: 20px !important;
    left: 20px !important;
    width: 96%; }

  div.item-slide-content3.item-slide-content .slide-title {
    font-size: 17px;
    margin-bottom: 5px; }

  div.item-slide-content3.item-slide-content .sile-subtitle {
    font-size: 14px;
    margin-bottom: 10px;
    display: none; }

  #banner-home4 .banner-text {
    margin-top: 30px; }

  .host-deals-slide .item-deal .hot-deal-count {
    padding: 30px 0;
    text-align: center; }

  .host-deals-slide .item-deal .product-media {
    float: left;
    margin-right: 0;
    width: 100%; }

  .host-deals-slide .item-deal .product-media figure {
    display: inline-block; }

  .host-deals-slide .item-deal .product-info {
    text-align: center;
    padding: 0 40px; }

  div.product-tabs-style2 .left-tab {
    width: 100%;
    margin-top: 20px; }

  div.product-tabs-style2 .right-tab {
    width: 100%; }

  div.product-tabs-style2 .supermartket-owl-carousel .product-list-content {
    width: 50%; }

  div.product-tabs-style2 .nav.nav-pills li {
    font-size: 13px;
    line-height: 14px; }

  div.product-tabs-style2 .nav.nav-pills li a {
    padding: 10px 0; }

  div.product-tabs-style3 .nav.nav-pills {
    padding-right: 0; }

  div.product-tabs-style4 .nav.nav-pills li {
    padding: 0 5px;
    border-radius: 0; }

  div.product-tabs-style4 .nav.nav-pills li:first-child {
    border-radius: 0;
    margin-left: 0; }

  div.product-tabs-style4 .product-tabs-content {
    margin-top: 0; }

  div.product-tabs-style4.product-tabs .tab-title {
    margin: 20px 0; }

  div.product-tabs-style4.product-tabs .tab-title::before {
    display: none; }

  div.product-tabs-style4.product-tabs .tab-title::after {
    display: none; }

  div.product-tabs-style4 .owl-nav > div {
    top: -35px; }

  div.product-tabs-style5.product-tabs {
    padding-top: 20px; }

  div.product-tabs-style5.product-tabs .tab-title {
    float: left;
    margin-left: 0px;
    position: relative;
    margin-bottom: 30px; }

  div.product-tabs-style5 .nav.nav-pills {
    padding-right: 0; }

  div.product-tabs-style5 .nav.nav-pills li {
    line-height: 40px;
    padding: 0 20px 0 0; }

  .categories-filter .category-drop-list {
    width: 100%; }

  .newsletter-form-style2.newsletter-form-wrap {
    background-size: cover; }

  .host-deals-slide .item-product-deal img {
    max-width: 100%;
    width: auto;
    margin: 0 auto; }

  div.tabs-slide .nav.nav-pills {
    padding-right: 0px; }

  .tabs-slide.product-tabs .owl-nav > div {
    display: none; }

  .item-slide-content4.item-slide-content {
    bottom: auto;
    left: 20px !important;
    position: absolute;
    text-align: center;
    top: 20px !important;
    transform: none !important; }

  body .banner-slide-with-cate a.menu-button {
    display: none;
    float: none;
    margin-bottom: 20px; }

  div.categories-element .menu-button {
    display: block; }

  header.header-style5 .cart-menu .cart-amount {
    top: -20px; }

  .banner-slide-with-cate .owl-nav > div {
    display: none; }

  div.product-tabs-style4 .product-list-content {
    bottom: 0; }

  .product-tabs-style4 .product-tabs-content::before {
    display: none; }

  div.item-slide-content6.item-slide-content {
    left: auto;
    padding-left: 20px;
    position: absolute;
    text-align: center;
    top: 20px; }

  div.item-slide-content6.slide3.item-slide-content {
    padding-left: 20px;
    text-align: left; }

  .item-slide-content-wrap .container {
    width: auto; }

  body .style5.icon-box {
    border-width: 0;
    width: auto; }

  .product-tabs-style5 .product-tabs-content {
    border-top: 1px solid #dddddd; }

  div.product-tabs-style5.product-tabs {
    padding-top: 20px;
    margin-top: 30px; }

  body .page-nav {
    margin-top: 10px; }

  div.categories-list1 {
    padding-top: 0; }

  body .categories-list1 div.content, body .categories-grid1 div.content, body .categories-list2 div.content {
    margin: 0;
    padding: 0 30px; }

  body .categories-list1 div.sidebar, body .categories-grid1 div.sidebar, body .categories-list2 div.sidebar {
    margin-top: 0;
    padding: 0 30px; }

  #customer_login div.login-form-wrap, #customer_login div.register-form-wrap {
    background: white none repeat scroll 0 0;
    border: 1px solid #ececec;
    float: left;
    margin-bottom: 30px;
    padding: 30px;
    width: 100%; } }
@media (min-width: 768px) and (max-width: 1366px) {
  div.product-tabs-style3 .product-list-content {
    width: 100%; } }
@media (min-width: 768px) and (max-width: 1024px) {
  div.product-tabs-style3 .product-list-content {
    width: 100%; }

  div.form-search-header .button-search {
    line-height: 38px; }

  header.header-style6 .header-bottom-right {
    margin-top: 30px; }

  .slide-product-with-thumb .thumb-media {
    max-width: 95px; }

  .slide-product-with-thumb .image-media {
    float: right;
    display: none; }

  div.blog-page .page-nav {
    float: left;
    margin-bottom: 30px;
    width: auto; }

  ul.bestseller-list .bestseller-item {
    padding-left: 0; }

  li.bestseller-item > a {
    float: left;
    position: relative; }

  .bestseller-content {
    padding: 0 20px; }

  div.item-slide-content6.item-slide-content {
    top: 50px !important;
    left: 20px !important;
    right: auto !important;
    padding: 0; }

  div.product-tabs-style2 .left-tab {
    width: 28%; }

  div.product-tabs-style2 .right-tab {
    width: 72%; }

  div.product-tabs-style2 .supermartket-owl-carousel .product-list-content {
    width: 50%; }

  .country-select .country-dropdown {
    left: auto;
    right: 0; }

  div.icon-box h4 {
    font-size: 16px; }

  div.banner-home1-content.slide3 .container {
    padding: 100px 20px 0 50px; }

  div.list-banner .banner-item {
    margin: 0 0 12px; } }
@media (min-width: 768px) and (max-width: 991px) {
  .style3.icon-box {
    width: auto; }

  div.div-icon-box > div > .col-md-4 {
    width: 100%; }

  div.banner-home3-content div.slide-h3-content {
    left: 50px;
    position: absolute;
    top: 60px; }

  .banner-home .owl-nav {
    display: none; }

  div div.left-tab {
    display: none; }

  .host-deals-slide .product-info {
    clear: both; }

  .brand-sale-box .sale-time .block-title .box-count {
    width: auto !important;
    margin: 0 !important; }

  .brand-sale-box .sale-time .block-title .box-count .number {
    font-size: 16px !important;
    margin-right: 3px !important; }

  .banner-home2-bottom .block-title .deals-of-countdown div.box-count {
    width: auto !important;
    margin: 0 !important; }

  .banner-home2-bottom .block-title .deals-of-countdown div.box-count .number {
    font-size: 16px !important;
    margin-right: 3px !important; }

  div.product-tabs-style2 div.right-tab {
    width: 100%; }

  .view-categories {
    float: left; }

  body div.select-categories {
    margin: 0 10px 0 0;
    text-align: right; }

  div.style5.icon-box {
    border-width: 0;
    width: auto; }

  .better-sales-slide .better-sale-media {
    float: none;
    margin: 0 10px 0 0; }

  .better-sales-slide .owl-nav > div {
    display: none; }

  header.header-style4 .header-search {
    margin: 20px auto; }

  header.header-style5 .header-search {
    margin: 10px auto; }

  .header-style5 .header-bottom-center {
    text-align: center;
    width: 100%; }

  .item-product-deal .box-count {
    width: 30px !important; }

  .item-product-deal .number {
    font-size: 15px !important; }

  .item-product-deal .number {
    font-size: 13px !important; }

  div.product-tabs-style5.product-tabs .tab-title {
    float: left;
    margin-left: 12px;
    position: relative;
    margin-bottom: 20px; }

  .box-count {
    margin: 3px !important; }

  .box-count {
    width: 40px !important; }

  .number {
    font-size: 22px !important; }

  div.about-top1 > a {
    display: table;
    margin: 0 auto; }

  .div-icon-box > div > .col-md-4 {
    width: 100%; }

  header.header-style4 .header-bottom-right {
    float: none;
    margin-top: 30px;
    width: auto; }

  div.categories-filter {
    display: table;
    margin: 10px auto;
    padding-bottom: 7px;
    position: relative; }

  .product-tabs-style3 .product-list-content {
    border-bottom: 1px solid #dddddd;
    margin-bottom: -1px; }

  div.icon-box {
    display: table;
    margin: 30px auto;
    width: 90%;
    padding: 20px 30px; }

  header h1.logo {
    display: inline-block;
    line-height: 25px;
    width: 100%;
    text-align: center; }

  header.header-style3 .header-search {
    margin: 15px auto 30px; }

  .div-icon-box > div > .col-md-4 {
    width: 50%; }

  .div-icon-box > div > .col-md-4 .icon-box {
    min-height: 190px; }

  .about-top .row > div {
    width: 100%; }

  .banner-home1-content.slide3 .container {
    padding: 60px 220px 0 268px; }

  .thumbs > a img {
    height: 80%;
    width: 80%; }

  .product-tabs .feature-slide-name,
  .product-tabs .feature-slide-cost {
    margin-left: 5px; }

  .nav.nav-pills li {
    padding: 0 10px;
    font-size: 13px; }

  .brand-sale-box.row > div {
    width: 100%; }

  .brand-sale-box.row > div .sale-time {
    margin-top: 15px; }

  body .bestseller-item {
    padding-left: 0;
    position: inherit;
    text-align: center; }

  body .bestseller-item > a {
    display: inline-block;
    left: auto;
    position: inherit;
    top: auto; }

  body .blog-box .row > div {
    width: 100%; }

  body .blog-box .row > div figure {
    margin-bottom: 10px; }

  body.checkout-page .main-content > .row > div {
    width: 100%; }

  body div.categories-grid div.product-box {
    width: 50%; }

  body .page-nav {
    width: 160px; }

  body .select-categories > div {
    display: inline-block;
    margin: 0 0 10px 0; }

  body .select-categories > div span {
    float: left;
    width: 60px; }

  body .select-categories > div select {
    width: 130px; }

  body .slider-range {
    margin-top: 135px; }

  body .slider-range .filter-top {
    top: -130px; }

  body .slider-range .filter-top span {
    margin-bottom: 5px; }

  body .slider-range .filter-top a {
    float: left; }

  body .shop-sidebar-content input {
    margin-right: 3px; }

  body .breadcrumb-sidebar p {
    padding: 2px 15px 3.5px; }

  body .categories-list .product-box-content .product-box-text {
    margin-top: -8px;
    padding: 0 0 0 15px; }

  body .categories-list .product-box-content .product-box-text .desc-product {
    margin: 0; }

  body .write {
    margin: 0; }

  body.product-detail .detail-products .nav.nav-pills li a {
    font-size: 14px;
    padding: 0 8px; }

  body .footer-top .has-logo {
    width: 100%; }

  body .footer-top .tags-footer {
    width: 50%; }

  body.home3 .product-tabs-h3 .right-tab {
    width: 70%; }

  body.home3 .product-tabs-h3 .left-tab {
    width: 30%; }

  body.home3 .product-tabs-h3 .supermartket-owl-carousel .product-list-content {
    width: 50%; }

  body.home3 .product-tabs-h3 .nav.nav-pills {
    padding-right: 45px; }

  div.banner-home3-content .slide-h3-content {
    left: 20px;
    position: absolute;
    top: 20px; }

  .product-list-content figure img, .item-product-deal figure img, .better-sale-item figure img, .catergory-item figure img, .left-tab figure img {
    height: auto; }

  div.product-list .product-list-content {
    width: 33.33%; } }
@media (max-width: 991px) {
  .sidebar .breadcrumb-sidebar {
    display: none; }

  body .categories-list .product-box-content .product-box-text p.desc-product {
    margin: 0 0 20px; }

  .categories-grid .breadcrumb-wrap, .categories-list .breadcrumb-wrap {
    margin: 20px 0 0; }

  .categories.categories-grid3 .sidebar-top {
    padding: 0 20px; }

  .banner-absolutes .banner-absolute {
    right: 0 !important; }

  body .banner-home2 .banner-home2-left {
    width: 100%; }

  body .banner-home2 .banner-home2-right {
    width: 100%;
    margin: 0; }

  body .banner-asblute li {
    width: 33.33%;
    float: left; }

  body .page-nav {
    float: left; }

  body .select-categories {
    margin: 10px 0 0;
    text-align: left; }

  body .sidebar {
    display: inline-block;
    width: 100%;
    margin-bottom: 20px; }

  body footer {
    padding: 0 20px; }

  div.left-tab {
    display: table;
    margin: 40px auto; }

  body .sidebar {
    margin-top: 30px; }

  body li.bestseller-item > a {
    display: inline-block;
    float: none;
    left: auto;
    position: inherit;
    top: auto; }

  div.section-icon-box .icon-box {
    margin: 0 auto 30px; } }
@media (min-width: 992px) and (max-width: 1205px) {
  .product-list-content figure img, .item-product-deal figure img, .better-sale-item figure img, .catergory-item figure img, .left-tab figure img {
    height: auto; }

  header.header-style4 .form-search-header input {
    width: 200px; }

  .header-style4 .header-bottom-center {
    padding-left: 0;
    width: auto; }

  header.header-style5 .form-search-header input {
    width: 200px; }

  header.header-style5 .header-bottom-right .text-bold {
    font-size: 0; }

  body .icon-box .col-md-4 i {
    height: 80px;
    line-height: 80px;
    width: 80px;
    margin-left: -10px; }

  body .icon-box .col-md-4 i::after {
    width: 105px;
    height: 105px; }

  body .thumbs > a {
    padding: 0; }

  body .thumbs > a img {
    width: 85%; }

  body .about-box .about-desc {
    font-size: 13px;
    margin: 6px -2px 0; }

  body .icon-box-contact {
    margin-left: -15px; }

  body .categories-grid .product-box {
    width: 33.33% !important; }

  body .select-categories {
    margin: 0 0 0 -89px; }

  body .page-nav {
    display: inline-block;
    float: right;
    margin: 0 0 0 -5px; }

  body .slider-range {
    margin-top: 100px; }

  body .slider-range .filter-top {
    top: -90px; }

  body .slider-range .filter-top a {
    float: left;
    margin-top: 5px; }

  body .categories-list .product-box-content .product-box-text {
    padding: 15px; }

  body .shop-sidebar-content {
    padding: 30px 12px; }

  body .footer-top .row > div {
    padding-left: 30px; }

  body .footer-top .row > div .has-logo {
    padding-left: 0; }

  div.brand-sale-box .sale-time {
    background-color: transparent; }

  div.brand-sale-box .sale-time .block-title {
    height: 23px; }

  div.brand-sale-box .sale-time > i {
    text-align: left;
    width: 30px; }

  .brand-sale-box .sale-time .orange {
    float: left;
    margin-top: 10px; }

  div.icon-box p {
    line-height: 22px;
    margin: 8px 0;
    height: 18px;
    overflow: hidden; }

  header.header-style5 div.form-search-header input {
    width: 135px; } }
@media (min-width: 481px) and (max-width: 991px) {
  /*tab-pane-slide cols*/
  div.tab-pane-slide.slide-5cols div.product-list-content {
    width: 33.33%; }

  div.tab-pane-slide.slide-5cols div.product-list-content:nth-child(3n) {
    border-right: none; }

  div.tab-pane-slide.slide-4cols div.product-list-content {
    width: 33.33%; }

  div.tab-pane-slide.slide-4cols div.product-list-content:nth-child(3n) {
    border-right: none; }

  div.tab-pane-slide.slide-3cols div.product-list-content {
    width: 33.33%; }

  div.tab-pane-slide.slide-3cols div.product-list-content:nth-child(3n) {
    border-right: none; }

  div.tab-pane-slide.slide-2cols div.product-list-content {
    width: 33.33%; }

  div.tab-pane-slide.slide-2cols div.product-list-content:nth-child(3n) {
    border-right: none; } }
@media (max-width: 480px) {
  body .brand-sale-box .sale-time {
    text-align: center;
    padding: 10px 0; }

  body .brand-sale-box .sale-time > i {
    color: #333333;
    float: none;
    font-size: 23px;
    text-align: center;
    width: 38px; }

  body .brand-sale-box .sale-time .orange {
    float: none;
    margin: 0 10px; }

  body .nav.nav-pills li {
    border-top: 1px solid #dddddd;
    float: left;
    width: calc(100% + 2px); }

  body header.header-style3 .header-mid-right .header-mid-right-content, body header.header-style4 .header-mid-right .header-mid-right-content {
    display: inline-block;
    float: left;
    margin: -1px;
    text-align: center;
    line-height: 30px;
    width: 50%; }

  body header.header-style3 .header-mid-right .header-mid-right-content .country-select, body header.header-style4 .header-mid-right .header-mid-right-content .country-select {
    float: none;
    padding: 0;
    position: relative;
    width: 125px; }

  body div .shoping-form .button-cart a {
    float: left;
    margin: 0 10px 10px 2%;
    padding: 0 14px;
    width: 96%; }

  header.header-style4 .money-select, header.header-style4 .country-select {
    width: 110px;
    float: none;
    margin: 0 auto;
    line-height: 30px;
    height: 30px; }

  .header-mid-right-content > div {
    width: 100%; }

  .header-mid-right {
    padding-bottom: 20px; }

  .header-mid-right div.country-select-menu {
    width: 40%; }

  .header-mid-right div.country-select-menu i {
    text-align: center;
    width: 20px;
    position: absolute;
    top: 30%;
    right: 9px; }

  .header-mid-right div.country-select-menu .country-dropdown {
    width: 100%; }

  .header-mid-right div.money-select-menu {
    margin-left: 20px;
    width: 40%; }

  .header-mid-right div.money-select-menu i {
    text-align: center;
    width: 20px;
    position: absolute;
    top: 30%;
    right: 9px; }

  .select-categories-content select[name="position"] {
    margin-right: 0; }

  .product-detail .product-box .social > div {
    float: left;
    margin-right: 0;
    width: 50%; }

  .product-detail .product-box .social > div.tweet-social {
    margin: 0; }

  .product-detail .product-box .social > div a {
    float: left; }

  .product-detail .product-box .social > div span.amount-folow {
    float: left; }

  .breadcrumb-sidebar p {
    margin: 0 4px 5px 0; }

  .product-detail .product-box .quanlity-product {
    margin-bottom: 15px; }

  body .header-search {
    margin: 30px 0 0 0;
    width: 100%; }

  body .form-search-header {
    position: relative;
    padding: 0 40px 0 120px;
    margin: 0;
    width: 100%; }

  body .form-search-header select {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 20px 0 0;
    width: 120px; }

  body .form-search-header a {
    position: absolute;
    right: 0;
    top: 0; }

  body .header-bottom-right {
    width: 100%; }

  body .product-list .product-list-content {
    width: 100%;
    text-align: center; }

  body .categories-grid .product-box {
    width: 100% !important; }

  body .select-categories > div {
    display: inline-block;
    margin: 10px 0 0; }

  body .select-categories > div span {
    float: left;
    width: 60px; }

  body.categories-page div .panel-categories .page-nav {
    width: 140px; }

  body.categories-page div .panel-categories .page-nav > div {
    width: 30px; }

  body .slide-home1 .thumbs {
    float: left;
    width: 100%; }

  body .slide-home1 .thumbs > a {
    float: left;
    padding: 0;
    width: 33.3%;
    border: medium none; }

  body .slide-home1 .thumbs > a.active::before {
    top: 100%;
    transform: translateX(-50%) translateY(-7px) rotate(45deg);
    -o-transform: translateX(-50%) translateY(-7px) rotate(45deg);
    -ms-transform: translateX(-50%) translateY(-7px) rotate(45deg);
    -moz-transform: translateX(-50%) translateY(-7px) rotate(45deg);
    -webkit-transform: translateX(-50%) translateY(-7px) rotate(45deg);
    z-index: 3;
    left: 50%; }

  body .slide-home1 .bx-wrapper {
    width: 100%; }

  body .banner-home2-bottom .banner-home2-title > a {
    text-align: left;
    width: 100%; }

  body .banner-asblute li {
    width: 100%; }

  body .header-bottom-right > div.cart-menu-relative {
    float: right; }

  body.home3 .header-mid-right div.country-select-menu .country-dropdown {
    width: 140px; }

  body.home3 .header-mid .country-select {
    padding: 0;
    position: relative;
    width: 75px; }

  body.home3 .header-search {
    margin: 17px 0 0 0; }

  body.home3 .form-search-header {
    padding: 0 40px 0 0; }

  body.home3 .form-search-header select {
    left: auto;
    right: 45px;
    width: 130px; }

  body.home3 .my-supermarket {
    margin-bottom: 20px;
    width: 100%; }

  body.home3 .my-supermarket .hover-supermarket {
    width: 100%; }

  body.home3 .banner-home3-content .slide-h3-content {
    left: 20px;
    top: 20px; }

  body.home3 .banner-home3-content .slide-h3-content h3 {
    font-size: 18px; }

  body.home3 .banner-home3-content .slide-h3-content p {
    font-size: 15px;
    margin: 2px 0 10px; }

  body.home3 .banner-home3-content .slide-h3-content a {
    font-size: 14px;
    font-weight: 600;
    height: 30px;
    line-height: 29px;
    padding: 0 12px; }

  body.home3 .product-tabs-h3 .supermartket-owl-carousel .product-list-content {
    width: 100% !important; }

  body .banner-home2-bottom .banner-home2-title > a {
    margin: 0;
    text-align: left;
    width: auto;
    float: left; }

  body .banner-home2-bottom .block-title {
    float: left;
    margin-right: 10px; }

  body .banner-home2-bottom .title-left {
    float: left;
    margin: 5px 10px 5px 0; }

  body .item-slide-content {
    left: 20px;
    position: absolute;
    top: 20px; }

  body .item-slide-content .slide-title {
    font-size: 18px;
    margin-bottom: 5px;
    text-transform: uppercase;
    margin-bottom: 20px; }

  body .item-slide-content .sile-desc {
    color: #888888;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 10px;
    display: none; }

  body .item-slide-content.slide2 {
    left: 20px;
    top: 20px; }

  .list-banner .banner-item {
    width: 86%;
    margin-left: 7%;
    background-color: white;
    box-shadow: 0 0 5px rgba(223, 223, 223, 0.9);
    background-position: right center;
    background-repeat: no-repeat; }

  /*tab-pane-slide cols*/
  div.tab-pane-slide.slide-5cols .product-list-content {
    width: 50%; }

  div.tab-pane-slide.slide-5cols .product-list-content:nth-child(2n) {
    border-right: none; }

  div.tab-pane-slide.slide-4cols .product-list-content {
    width: 50%; }

  div.tab-pane-slide.slide-4cols .product-list-content:nth-child(2n) {
    border-right: none; }

  div.tab-pane-slide.slide-3cols .product-list-content {
    width: 50%; }

  div.tab-pane-slide.slide-3cols .product-list-content:nth-child(2n) {
    border-right: none; }

  div.tab-pane-slide.slide-2cols .product-list-content {
    width: 50%; }

  div.tab-pane-slide.slide-2cols .product-list-content:nth-child(2n) {
    border-right: none; }

  .item-deal .box-count {
    margin: 0 3px !important; } }
@media (max-width: 360px) {
  body {
    /*tab-pane-slide cols*/ }

  body .product-tabs-style2 .supermartket-owl-carousel .product-list-content {
    width: 100%; }

  body div.tab-pane-slide.slide-5cols .product-list-content {
    width: 100%; }

  body div.tab-pane-slide.slide-5cols .product-list-content:nth-child(n) {
    border-right: none; }

  body div.tab-pane-slide.slide-4cols .product-list-content {
    width: 100%; }

  body div.tab-pane-slide.slide-4cols .product-list-content:nth-child(n) {
    border-right: none; }

  body div.tab-pane-slide.slide-3cols .product-list-content {
    width: 10%; }

  body div.tab-pane-slide.slide-3cols .product-list-content:nth-child(n) {
    border-right: none; }

  body div.tab-pane-slide.slide-2cols .product-list-content {
    width: 100%; }

  body div.tab-pane-slide.slide-2cols .product-list-content:nth-child(n) {
    border-right: none; }

  body .item-slide-content4.item-slide-content .slide-title {
    font-size: 16px; }

  body .logo-slide {
    width: 70%;
    margin: 30px auto; } }
a.added-wishlist.wishlist {
  display: none; }

.product-box-bottom .contact-form {
  position: fixed;
  top: 0px;
  left: 0px; }

a.view-added-wishlist {
  color: white;
  border-color: #ff8400;
  background-color: #ff8400;
  margin: 0 1%; }

a.refresh-product.added {
  color: white;
  border-color: #ff8400;
  background-color: #ff8400; }

.get-rates.button-check-out {
  color: #333333;
  background-color: #dbdbdb;
  height: 34px;
  line-height: 33px;
  float: right;
  margin-top: 20px;
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  padding: 0 20px;
  text-transform: uppercase;
  width: auto;
  border: none;
  margin-bottom: 3px; }

.get-rates.button-check-out:hover {
  background-color: #ff8400;
  color: white; }

#wrapper-response {
  margin: 20px 0; }

.categories-list .product-box-bottom a.view-added-wishlist {
  color: white;
  border-color: #ff8400;
  background-color: #ff8400;
  margin: 0 1%; }

.star-rating.rating {
  float: right; }

span.spr-badge-caption {
  display: none; }

.header-style6 .menu-item.active.item-home > a {
  color: #fff;
  text-transform: uppercase; }

.header-style6 .menu-item.active.item-home > a:hover {
  color: #f8b91d; }

.time_circles {
  position: relative;
  width: 100%;
  height: 100%; }

.time_circles > div {
  position: absolute;
  text-align: center; }

.time_circles > div > h4 {
  margin: 0;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Century Gothic', Arial;
  line-height: 1; }

.time_circles > div > span {
  margin: 0;
  padding: 0;
  display: block;
  width: 100%;
  text-align: center;
  font-family: 'Century Gothic', Arial;
  line-height: 1;
  font-weight: bold; }

body.stop-scrolling {
  height: 100%;
  overflow: hidden; }

.sweet-overlay {
  background-color: #000;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  z-index: 10000; }

.sweet-alert {
  background-color: #fff;
  font-family: 'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
  width: 478px;
  padding: 17px;
  border-radius: 5px;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -256px;
  margin-top: -200px;
  overflow: hidden;
  display: none;
  z-index: 99999; }

@media all and (max-width: 540px) {
  .sweet-alert {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    left: 15px;
    right: 15px; } }
.sweet-alert h2 {
  color: #575757;
  font-size: 30px;
  text-align: center;
  font-weight: 600;
  text-transform: none;
  position: relative;
  margin: 25px 0;
  padding: 0;
  line-height: 40px;
  display: block; }

.sweet-alert p {
  color: #797979;
  font-size: 16px;
  text-align: center;
  font-weight: 300;
  position: relative;
  text-align: inherit;
  float: none;
  margin: 0;
  padding: 0;
  line-height: normal; }

.sweet-alert fieldset {
  border: none;
  position: relative; }

.sweet-alert .sa-error-container {
  background-color: #f1f1f1;
  margin-left: -17px;
  margin-right: -17px;
  overflow: hidden;
  padding: 0 10px;
  max-height: 0;
  webkit-transition: padding 0.15s,max-height .15s;
  transition: padding 0.15s,max-height .15s; }

.sweet-alert .sa-error-container.show {
  padding: 10px 0;
  max-height: 100px;
  webkit-transition: padding 0.2s,max-height .2s;
  transition: padding 0.25s,max-height .25s; }

.sweet-alert .sa-error-container .icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #ea7d7d;
  color: #fff;
  line-height: 24px;
  text-align: center;
  margin-right: 3px; }

.sweet-alert .sa-error-container p {
  display: inline-block; }

.sweet-alert .sa-input-error {
  position: absolute;
  top: 29px;
  right: 26px;
  width: 20px;
  height: 20px;
  opacity: 0;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: all .1s;
  transition: all .1s; }

.sweet-alert .sa-input-error::before, .sweet-alert .sa-input-error::after {
  content: "";
  width: 20px;
  height: 6px;
  background-color: #f06e57;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  margin-top: -4px;
  left: 50%;
  margin-left: -9px; }

.sweet-alert .sa-input-error::before {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.sweet-alert .sa-input-error::after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.sweet-alert .sa-input-error.show {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1); }

.sweet-alert input {
  width: 100%;
  box-sizing: border-box;
  border-radius: 3px;
  border: 1px solid #d7d7d7;
  height: 43px;
  margin-top: 10px;
  margin-bottom: 17px;
  font-size: 18px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06);
  padding: 0 12px;
  display: none;
  -webkit-transition: all .3s;
  transition: all .3s; }

.sweet-alert input:focus {
  outline: none;
  box-shadow: 0 0 3px #c4e6f5;
  border: 1px solid #b4dbed; }

.sweet-alert input:focus::-moz-placeholder {
  transition: opacity .3s .03s ease;
  opacity: .5; }

.sweet-alert input:focus:-ms-input-placeholder {
  transition: opacity .3s .03s ease;
  opacity: .5; }

.sweet-alert input:focus::-webkit-input-placeholder {
  transition: opacity .3s .03s ease;
  opacity: .5; }

.sweet-alert input::-moz-placeholder {
  color: #bdbdbd; }

.sweet-alert input:-ms-input-placeholder {
  color: #bdbdbd; }

.sweet-alert input::-webkit-input-placeholder {
  color: #bdbdbd; }

.sweet-alert.show-input input {
  display: block; }

.sweet-alert .sa-confirm-button-container {
  display: inline-block;
  position: relative; }

.sweet-alert .la-ball-fall {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -27px;
  margin-top: 4px;
  opacity: 0;
  visibility: hidden; }

.sweet-alert button {
  background-color: #8CD4F5;
  color: #fff;
  border: none;
  box-shadow: none;
  font-size: 17px;
  font-weight: 500;
  -webkit-border-radius: 4px;
  border-radius: 5px;
  padding: 10px 32px;
  margin: 26px 5px 0;
  cursor: pointer; }

.sweet-alert button:focus {
  outline: none;
  box-shadow: 0 0 2px rgba(128, 179, 235, 0.5), inset 0 0 0 1px rgba(0, 0, 0, 0.05); }

.sweet-alert button:hover {
  background-color: #7ecff4; }

.sweet-alert button:active {
  background-color: #5dc2f1; }

.sweet-alert button.cancel {
  background-color: #C1C1C1; }

.sweet-alert button.cancel:hover {
  background-color: #b9b9b9; }

.sweet-alert button.cancel:active {
  background-color: #a8a8a8; }

.sweet-alert button.cancel:focus {
  box-shadow: rgba(197, 205, 211, 0.8) 0 0 2px, rgba(0, 0, 0, 0.04706) 0 0 0 1px inset !important; }

.sweet-alert button[disabled] {
  opacity: .6;
  cursor: default; }

.sweet-alert button.confirm[disabled] {
  color: transparent; }

.sweet-alert button.confirm[disabled] ~ .la-ball-fall {
  opacity: 1;
  visibility: visible;
  transition-delay: 0; }

.sweet-alert button::-moz-focus-inner {
  border: 0; }

.sweet-alert[data-has-cancel-button=false] button {
  box-shadow: none !important; }

.sweet-alert[data-has-confirm-button=false][data-has-cancel-button=false] {
  padding-bottom: 40px; }

.sweet-alert .sa-icon {
  width: 80px;
  height: 80px;
  border: 4px solid gray;
  -webkit-border-radius: 40px;
  border-radius: 40px;
  border-radius: 50%;
  margin: 20px auto;
  padding: 0;
  position: relative;
  box-sizing: content-box; }

.sweet-alert .sa-icon.sa-error {
  border-color: #F27474; }

.sweet-alert .sa-icon.sa-error .sa-x-mark {
  position: relative;
  display: block; }

.sweet-alert .sa-icon.sa-error .sa-line {
  position: absolute;
  height: 5px;
  width: 47px;
  background-color: #F27474;
  display: block;
  top: 37px;
  border-radius: 2px; }

.sweet-alert .sa-icon.sa-error .sa-line.sa-left {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 17px; }

.sweet-alert .sa-icon.sa-error .sa-line.sa-right {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  right: 16px; }

.sweet-alert .sa-icon.sa-warning {
  border-color: #F8BB86; }

.sweet-alert .sa-icon.sa-warning .sa-body {
  position: absolute;
  width: 5px;
  height: 47px;
  left: 50%;
  top: 10px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  margin-left: -2px;
  background-color: #F8BB86; }

.sweet-alert .sa-icon.sa-warning .sa-dot {
  position: absolute;
  width: 7px;
  height: 7px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  margin-left: -3px;
  left: 50%;
  bottom: 10px;
  background-color: #F8BB86; }

.sweet-alert .sa-icon.sa-info {
  border-color: #C9DAE1; }

.sweet-alert .sa-icon.sa-info::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 29px;
  left: 50%;
  bottom: 17px;
  border-radius: 2px;
  margin-left: -2px;
  background-color: #C9DAE1; }

.sweet-alert .sa-icon.sa-info::after {
  content: "";
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-left: -3px;
  top: 19px;
  background-color: #C9DAE1; }

.sweet-alert .sa-icon.sa-success {
  border-color: #A5DC86; }

.sweet-alert .sa-icon.sa-success::before, .sweet-alert .sa-icon.sa-success::after {
  content: '';
  -webkit-border-radius: 40px;
  border-radius: 40px;
  border-radius: 50%;
  position: absolute;
  width: 60px;
  height: 120px;
  background: #fff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.sweet-alert .sa-icon.sa-success::before {
  -webkit-border-radius: 120px 0 0 120px;
  border-radius: 120px 0 0 120px;
  top: -7px;
  left: -33px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 60px 60px;
  transform-origin: 60px 60px; }

.sweet-alert .sa-icon.sa-success::after {
  -webkit-border-radius: 0 120px 120px 0;
  border-radius: 0 120px 120px 0;
  top: -11px;
  left: 30px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0 60px;
  transform-origin: 0 60px; }

.sweet-alert .sa-icon.sa-success .sa-placeholder {
  width: 80px;
  height: 80px;
  border: 4px solid rgba(165, 220, 134, 0.2);
  -webkit-border-radius: 40px;
  border-radius: 40px;
  border-radius: 50%;
  box-sizing: content-box;
  position: absolute;
  left: -4px;
  top: -4px;
  z-index: 2; }

.sweet-alert .sa-icon.sa-success .sa-fix {
  width: 5px;
  height: 90px;
  background-color: #fff;
  position: absolute;
  left: 28px;
  top: 8px;
  z-index: 1;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.sweet-alert .sa-icon.sa-success .sa-line {
  height: 5px;
  background-color: #A5DC86;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 2; }

.sweet-alert .sa-icon.sa-success .sa-line.sa-tip {
  width: 25px;
  left: 14px;
  top: 46px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.sweet-alert .sa-icon.sa-success .sa-line.sa-long {
  width: 47px;
  right: 8px;
  top: 38px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.sweet-alert .sa-icon.sa-custom {
  background-size: contain;
  border-radius: 0;
  border: none;
  background-position: center center;
  background-repeat: no-repeat; }

@-webkit-keyframes showSweetAlert {
  0% {
    transform: scale(0.7);
    -webkit-transform: scale(0.7); }

  45% {
    transform: scale(1.05);
    -webkit-transform: scale(1.05); }

  80% {
    transform: scale(0.95);
    -webkit-transform: scale(0.95); }

  100% {
    transform: scale(1);
    -webkit-transform: scale(1); } }

@keyframes showSweetAlert {
  0% {
    transform: scale(0.7);
    -webkit-transform: scale(0.7); }

  45% {
    transform: scale(1.05);
    -webkit-transform: scale(1.05); }

  80% {
    transform: scale(0.95);
    -webkit-transform: scale(0.95); }

  100% {
    transform: scale(1);
    -webkit-transform: scale(1); } }

@-webkit-keyframes hideSweetAlert {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1); }

  100% {
    transform: scale(0.5);
    -webkit-transform: scale(0.5); } }

@keyframes hideSweetAlert {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1); }

  100% {
    transform: scale(0.5);
    -webkit-transform: scale(0.5); } }

@-webkit-keyframes slideFromTop {
  0% {
    top: 0; }

  100% {
    top: 50%; } }

@keyframes slideFromTop {
  0% {
    top: 0; }

  100% {
    top: 50%; } }

@-webkit-keyframes slideToTop {
  0% {
    top: 50%; }

  100% {
    top: 0; } }

@keyframes slideToTop {
  0% {
    top: 50%; }

  100% {
    top: 0; } }

@-webkit-keyframes slideFromBottom {
  0% {
    top: 70%; }

  100% {
    top: 50%; } }

@keyframes slideFromBottom {
  0% {
    top: 70%; }

  100% {
    top: 50%; } }

@-webkit-keyframes slideToBottom {
  0% {
    top: 50%; }

  100% {
    top: 70%; } }

@keyframes slideToBottom {
  0% {
    top: 50%; }

  100% {
    top: 70%; } }

.showSweetAlert[data-animation=pop] {
  -webkit-animation: showSweetAlert .3s;
  animation: showSweetAlert .3s; }

.showSweetAlert[data-animation=none] {
  -webkit-animation: none;
  animation: none; }

.showSweetAlert[data-animation=slide-from-top] {
  -webkit-animation: slideFromTop .3s;
  animation: slideFromTop .3s; }

.showSweetAlert[data-animation=slide-from-bottom] {
  -webkit-animation: slideFromBottom .3s;
  animation: slideFromBottom .3s; }

.hideSweetAlert[data-animation=pop] {
  -webkit-animation: hideSweetAlert .2s;
  animation: hideSweetAlert .2s; }

.hideSweetAlert[data-animation=none] {
  -webkit-animation: none;
  animation: none; }

.hideSweetAlert[data-animation=slide-from-top] {
  -webkit-animation: slideToTop .4s;
  animation: slideToTop .4s; }

.hideSweetAlert[data-animation=slide-from-bottom] {
  -webkit-animation: slideToBottom .3s;
  animation: slideToBottom .3s; }

@-webkit-keyframes animateSuccessTip {
  0% {
    width: 0;
    left: 1px;
    top: 19px; }

  54% {
    width: 0;
    left: 1px;
    top: 19px; }

  70% {
    width: 50px;
    left: -8px;
    top: 37px; }

  84% {
    width: 17px;
    left: 21px;
    top: 48px; }

  100% {
    width: 25px;
    left: 14px;
    top: 45px; } }

@keyframes animateSuccessTip {
  0% {
    width: 0;
    left: 1px;
    top: 19px; }

  54% {
    width: 0;
    left: 1px;
    top: 19px; }

  70% {
    width: 50px;
    left: -8px;
    top: 37px; }

  84% {
    width: 17px;
    left: 21px;
    top: 48px; }

  100% {
    width: 25px;
    left: 14px;
    top: 45px; } }

@-webkit-keyframes animateSuccessLong {
  0% {
    width: 0;
    right: 46px;
    top: 54px; }

  65% {
    width: 0;
    right: 46px;
    top: 54px; }

  84% {
    width: 55px;
    right: 0;
    top: 35px; }

  100% {
    width: 47px;
    right: 8px;
    top: 38px; } }

@keyframes animateSuccessLong {
  0% {
    width: 0;
    right: 46px;
    top: 54px; }

  65% {
    width: 0;
    right: 46px;
    top: 54px; }

  84% {
    width: 55px;
    right: 0;
    top: 35px; }

  100% {
    width: 47px;
    right: 8px;
    top: 38px; } }

@-webkit-keyframes rotatePlaceholder {
  0% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg); }

  5% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg); }

  12% {
    transform: rotate(-405deg);
    -webkit-transform: rotate(-405deg); }

  100% {
    transform: rotate(-405deg);
    -webkit-transform: rotate(-405deg); } }

@keyframes rotatePlaceholder {
  0% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg); }

  5% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg); }

  12% {
    transform: rotate(-405deg);
    -webkit-transform: rotate(-405deg); }

  100% {
    transform: rotate(-405deg);
    -webkit-transform: rotate(-405deg); } }

.animateSuccessTip {
  -webkit-animation: animateSuccessTip .75s;
  animation: animateSuccessTip .75s; }

.animateSuccessLong {
  -webkit-animation: animateSuccessLong .75s;
  animation: animateSuccessLong .75s; }

.sa-icon.sa-success.animate::after {
  -webkit-animation: rotatePlaceholder 4.25s ease-in;
  animation: rotatePlaceholder 4.25s ease-in; }

@-webkit-keyframes animateErrorIcon {
  0% {
    transform: rotateX(100deg);
    -webkit-transform: rotateX(100deg);
    opacity: 0; }

  100% {
    transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    opacity: 1; } }

@keyframes animateErrorIcon {
  0% {
    transform: rotateX(100deg);
    -webkit-transform: rotateX(100deg);
    opacity: 0; }

  100% {
    transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    opacity: 1; } }

.animateErrorIcon {
  -webkit-animation: animateErrorIcon .5s;
  animation: animateErrorIcon .5s; }

@-webkit-keyframes animateXMark {
  0% {
    transform: scale(0.4);
    -webkit-transform: scale(0.4);
    margin-top: 26px;
    opacity: 0; }

  50% {
    transform: scale(0.4);
    -webkit-transform: scale(0.4);
    margin-top: 26px;
    opacity: 0; }

  80% {
    transform: scale(1.15);
    -webkit-transform: scale(1.15);
    margin-top: -6px; }

  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    margin-top: 0;
    opacity: 1; } }

@keyframes animateXMark {
  0% {
    transform: scale(0.4);
    -webkit-transform: scale(0.4);
    margin-top: 26px;
    opacity: 0; }

  50% {
    transform: scale(0.4);
    -webkit-transform: scale(0.4);
    margin-top: 26px;
    opacity: 0; }

  80% {
    transform: scale(1.15);
    -webkit-transform: scale(1.15);
    margin-top: -6px; }

  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    margin-top: 0;
    opacity: 1; } }

.animateXMark {
  -webkit-animation: animateXMark .5s;
  animation: animateXMark .5s; }

@-webkit-keyframes pulseWarning {
  0% {
    border-color: #F8D486; }

  100% {
    border-color: #F8BB86; } }

@keyframes pulseWarning {
  0% {
    border-color: #F8D486; }

  100% {
    border-color: #F8BB86; } }

.pulseWarning {
  -webkit-animation: pulseWarning .75s infinite alternate;
  animation: pulseWarning .75s infinite alternate; }

@-webkit-keyframes pulseWarningIns {
  0% {
    background-color: #F8D486; }

  100% {
    background-color: #F8BB86; } }

@keyframes pulseWarningIns {
  0% {
    background-color: #F8D486; }

  100% {
    background-color: #F8BB86; } }

.pulseWarningIns {
  -webkit-animation: pulseWarningIns .75s infinite alternate;
  animation: pulseWarningIns .75s infinite alternate; }

@-webkit-keyframes rotate-loading {
  0% {
    transform: rotate(0deg); }

  100% {
    transform: rotate(360deg); } }

@keyframes rotate-loading {
  0% {
    transform: rotate(0deg); }

  100% {
    transform: rotate(360deg); } }

.sweet-alert .sa-icon.sa-error .sa-line.sa-left {
  -ms-transform: rotate(45deg) \9; }

.sweet-alert .sa-icon.sa-error .sa-line.sa-right {
  -ms-transform: rotate(-45deg) \9; }

.sweet-alert .sa-icon.sa-success {
  border-color: transparent\9; }

.sweet-alert .sa-icon.sa-success .sa-line.sa-tip {
  -ms-transform: rotate(45deg) \9; }

.sweet-alert .sa-icon.sa-success .sa-line.sa-long {
  -ms-transform: rotate(-45deg) \9; }

.la-ball-fall, .la-ball-fall > div {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.la-ball-fall {
  display: block;
  font-size: 0;
  color: #fff; }

.la-ball-fall.la-dark {
  color: #333; }

.la-ball-fall > div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor; }

.la-ball-fall {
  width: 54px;
  height: 18px; }

.la-ball-fall > div {
  width: 10px;
  height: 10px;
  margin: 4px;
  border-radius: 100%;
  opacity: 0;
  -webkit-animation: ball-fall 1s ease-in-out infinite;
  -moz-animation: ball-fall 1s ease-in-out infinite;
  -o-animation: ball-fall 1s ease-in-out infinite;
  animation: ball-fall 1s ease-in-out infinite; }

.la-ball-fall > div:nth-child(1) {
  -webkit-animation-delay: -200ms;
  -moz-animation-delay: -200ms;
  -o-animation-delay: -200ms;
  animation-delay: -200ms; }

.la-ball-fall > div:nth-child(2) {
  -webkit-animation-delay: -100ms;
  -moz-animation-delay: -100ms;
  -o-animation-delay: -100ms;
  animation-delay: -100ms; }

.la-ball-fall > div:nth-child(3) {
  -webkit-animation-delay: 0;
  -moz-animation-delay: 0;
  -o-animation-delay: 0;
  animation-delay: 0; }

.la-ball-fall.la-sm {
  width: 26px;
  height: 8px; }

.la-ball-fall.la-sm > div {
  width: 4px;
  height: 4px;
  margin: 2px; }

.la-ball-fall.la-2x {
  width: 108px;
  height: 36px; }

.la-ball-fall.la-2x > div {
  width: 20px;
  height: 20px;
  margin: 8px; }

.la-ball-fall.la-3x {
  width: 162px;
  height: 54px; }

.la-ball-fall.la-3x > div {
  width: 30px;
  height: 30px;
  margin: 12px; }

@-webkit-keyframes ball-fall {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-145%);
    transform: translateY(-145%); }

  10% {
    opacity: .5; }

  20% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }

  80% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }

  90% {
    opacity: .5; }

  100% {
    opacity: 0;
    -webkit-transform: translateY(145%);
    transform: translateY(145%); } }

@-moz-keyframes ball-fall {
  0% {
    opacity: 0;
    -moz-transform: translateY(-145%);
    transform: translateY(-145%); }

  10% {
    opacity: .5; }

  20% {
    opacity: 1;
    -moz-transform: translateY(0);
    transform: translateY(0); }

  80% {
    opacity: 1;
    -moz-transform: translateY(0);
    transform: translateY(0); }

  90% {
    opacity: .5; }

  100% {
    opacity: 0;
    -moz-transform: translateY(145%);
    transform: translateY(145%); } }

@-o-keyframes ball-fall {
  0% {
    opacity: 0;
    -o-transform: translateY(-145%);
    transform: translateY(-145%); }

  10% {
    opacity: .5; }

  20% {
    opacity: 1;
    -o-transform: translateY(0);
    transform: translateY(0); }

  80% {
    opacity: 1;
    -o-transform: translateY(0);
    transform: translateY(0); }

  90% {
    opacity: .5; }

  100% {
    opacity: 0;
    -o-transform: translateY(145%);
    transform: translateY(145%); } }

@keyframes ball-fall {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-145%);
    -moz-transform: translateY(-145%);
    -o-transform: translateY(-145%);
    transform: translateY(-145%); }

  10% {
    opacity: .5; }

  20% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }

  80% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }

  90% {
    opacity: .5; }

  100% {
    opacity: 0;
    -webkit-transform: translateY(145%);
    -moz-transform: translateY(145%);
    -o-transform: translateY(145%);
    transform: translateY(145%); } }

@font-face {
  font-family: 'fontello';
  src: url("fontello.eot?14845602");
  src: url("fontello.eot?14845602#iefix") format("embedded-opentype"), url("fontello.woff2?14845602") format("woff2"), url("fontello.woff?14845602") format("woff"), url("fontello.ttf?14845602") format("truetype"), url("fontello.svg?14845602#fontello") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="mh-icon-"]:before, [class*=" mh-icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: .2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.mh-icon-download:before {
  content: '\e800'; }

.mh-icon-mail:before {
  content: '\e801'; }

.mh-icon-heart:before {
  content: '\e802'; }

.mh-icon-link:before {
  content: '\e803'; }

.mh-icon-thumbs-up:before {
  content: '\e804'; }

.mh-icon-spin1:before {
  content: '\e830'; }

.mh-icon-spin2:before {
  content: '\e831'; }

.mh-icon-spin3:before {
  content: '\e832'; }

.mh-icon-spin4:before {
  content: '\e834'; }

.mh-icon-spin5:before {
  content: '\e838'; }

.mh-icon-spin6:before {
  content: '\e839'; }

.mh-icon-twitter:before {
  content: '\f099'; }

.mh-icon-facebook:before {
  content: '\f09a'; }

.mh-icon-gplus:before {
  content: '\f0d5'; }

.mh-icon-linkedin:before {
  content: '\f0e1'; }

.mh-icon-comment:before {
  content: '\f0e5'; }

.mh-icon-youtube:before {
  content: '\f16a'; }

.mh-icon-dropbox:before {
  content: '\f16b'; }

.mh-icon-instagram:before {
  content: '\f16d'; }

.mh-icon-flickr:before {
  content: '\f16e'; }

.mh-icon-tumblr:before {
  content: '\f173'; }

.mh-icon-dribbble:before {
  content: '\f17d'; }

.mh-icon-skype:before {
  content: '\f17e'; }

.mh-icon-vimeo:before {
  content: '\f194'; }

.mh-icon-reddit:before {
  content: '\f1a1'; }

.mh-icon-digg:before {
  content: '\f1a6'; }

.mh-icon-vine:before {
  content: '\f1ca'; }

.mh-icon-git:before {
  content: '\f1d3'; }

.mh-icon-wechat:before {
  content: '\f1d7'; }

.mh-icon-share:before {
  content: '\f1e0'; }

.mh-icon-pinterest:before {
  content: '\f231'; }

.mh-icon-whatsapp:before {
  content: '\f232'; }

/*---------------- Fixed -------------------------*/
/* Swatches Styles */
.swatch {
  margin-bottom: 25px;
  display: inline-block;
  width: 100%; }

/* Label */
.swatch .header {
  margin-bottom: 10px;
  color: #000;
  max-width: 100%;
  font-weight: 700;
  text-transform: uppercase; }

/* Hide radio buttons.*/
.swatch input {
  display: none; }

.swatch label {
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  display: inline-block;
  border: 1px solid rgba(255, 0, 0, 0);
  padding: 2px;
  line-height: normal;
  float: left;
  margin-right: 7px; }

.swatch label span {
  min-width: 21px;
  height: 18px;
  display: inline-block;
  float: left;
  margin: 0px;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer; }

.color.swatch-element label {
  padding: 2px; }

.color.swatch-element label span {
  display: inline-block;
  float: left;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  position: relative;
  /*width: 21px; height: 21px;*/
  margin: 0px; }

.color.swatch-element label .selected:before {
  font-family: 'FontAwesome';
  content: "\f00c";
  color: #666666;
  position: absolute;
  left: 10px;
  top: 8px; }

/* Styling selected swatch */
/* Slightly raised */
.swatch input:checked + label,
.swatch label:hover {
  border-color: #333333;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s; }

.swatch .swatch-element {
  float: left;
  -webkit-transform: translateZ(0);
  /* webkit flicker fix */
  -webkit-font-smoothing: antialiased;
  /* webkit text rendering fix */
  /* Spacing between buttons */
  margin-right: 7px;
  /* To position the sold out graphic and tooltip */
  position: relative; }

/* Image with the cross in it */
.crossed-out {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0; }

.swatch .swatch-element .crossed-out {
  display: none; }

.swatch .swatch-element.soldout .crossed-out {
  display: block; }

.swatch .swatch-element.soldout label {
  filter: alpha(opacity=60);
  /* internet explorer */
  -khtml-opacity: 0.6;
  /* khtml, old safari */
  -moz-opacity: 0.6;
  /* mozilla, netscape */
  opacity: 0.6;
  /* fx, safari, opera */ }

/* Tooltips */
.swatch .tooltip {
  text-align: center;
  background: gray;
  color: #666666;
  bottom: 100%;
  padding: 10px;
  display: block;
  position: absolute;
  width: 100px;
  left: -28px;
  margin-bottom: 15px;
  /* Make it invisible by default */
  filter: alpha(opacity=0);
  -khtml-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  visibility: hidden;
  /* Animations */
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-transition: all .25s ease-out;
  -moz-transition: all .25s ease-out;
  -ms-transition: all .25s ease-out;
  -o-transition: all .25s ease-out;
  transition: all .25s ease-out;
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  z-index: 10000;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.swatch .tooltip:before {
  bottom: -20px;
  content: " ";
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  width: 100%; }

/* CSS triangle */
.swatch .tooltip:after {
  border-left: solid transparent 10px;
  border-right: solid transparent 10px;
  border-top: solid gray 10px;
  bottom: -10px;
  content: " ";
  height: 0;
  left: 50%;
  margin-left: -13px;
  position: absolute;
  width: 0; }

.swatch .swatch-element:hover .tooltip {
  filter: alpha(opacity=100);
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px); }

.swatch.error {
  background-color: #e8d2d2 !important;
  color: #333333 !important;
  padding: 1em;
  border-radius: 5px; }

.swatch.error p {
  margin: 0.7em 0; }

.swatch.error p:first-child {
  margin-top: 0; }

.swatch.error p:last-child {
  margin-bottom: 0; }

.swatch.error code {
  font-family: monospace; }

.item-swatch {
  list-style: none;
  padding: 0;
  margin: 0;
  line-height: 0; }

.item-swatch li {
  display: inline-block;
  margin: 0 2px; }

.item-swatch li label {
  cursor: pointer;
  margin: 0;
  width: 15px;
  height: 15px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%; }

#wrapper-response .error {
  display: inline;
  margin: 0;
  color: red;
  font-weight: bold; }

#wrapper-response .success {
  display: none;
  margin: 0;
  color: red;
  font-weight: bold; }

#shipping-calculator .field {
  margin: 0px !important; }

#shipping-rates {
  list-style-type: none;
  display: inline-block; }

html {
  padding: 0px !important; }

#html5-watermark {
  display: none !important; }

.modal-backdrop.in {
  display: none; }

.remove-compare-x:before {
  font-family: 'FontAwesome';
  content: "\f00d";
  font-size: 18px;
  color: #ff0000;
  line-height: normal; }

#compare-modal .remove-compare.center-block {
  float: none; }

#compare-modal .remove-compare .remove-compare-x:hover {
  opacity: 1; }

.popup-quickview .product-box-content {
  height: 486px;
  width: 830px;
  background-color: white !important;
  margin: 0 auto;
  padding: 20px; }

.popup-quickview .featue-slide {
  display: none; }

.popup-quickview .single-product-content {
  padding-right: 0px; }

.popup-quickview .product-box .product-box-bottom {
  margin-top: 40px; }

.popup-quickview .social {
  display: none !important; }

.popup-quickview .swatch {
  margin-bottom: 10px; }

.popup-quickview .onsale .product-box-bottom {
  margin-top: 10px; }

.page-404 {
  height: 600px;
  width: 100%;
  display: block;
  text-align: center;
  padding: 20px 15px; }

.page-404 .title {
  font-size: 36px;
  color: #222;
  font-family: "Montserrat",sans-serif;
  margin: 20px 0; }

.page-404 .des {
  font-size: 18px;
  color: #555;
  margin: 0 0 75px; }

#comment_form .errors {
  padding: 0 15px;
  color: red; }

.lazy {
  /* optional way, set loading as background */
  background-repeat: no-repeat;
  background-position: 50% 50%; }

/*---------------- End fixed -------------------------*/
.logo img {
  width: auto;
  height: auto; }
